import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../actions/modal';
import cross from '../images/static/cross.svg';



function TeachingVideo(props) {
  const dispatch = useDispatch();

  function close() {
    dispatch(hideModal(null, {
      ...props
    }));
  }

  return (
    <div className="modal-window dark">
      <img src={cross} alt="" className="modal-close" onClick={close} />
      <div className="modal-content teaching-video">
        <div className="video-container">
          <iframe title="teaching-video" width="853" height="480" src="https://www.youtube.com/embed/jQAjyhA2r_w?autoplay=1" frameBorder="0" allowFullScreen></iframe>
        </div>
      </div>
    </div>
  )
}

export default TeachingVideo;

import React from 'react';
import { withRouter } from 'react-router';
import { NavLink } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="nav">
          <NavLink exact to={{ pathname: '/about-us', search: this.props.location.search }}>About Favuni Global</NavLink>
          <NavLink exact to={{ pathname: '/contact-us', search: this.props.location.search }}>Contact Us</NavLink>
          <NavLink exact to={{ pathname: '/terms-of-use', search: this.props.location.search }}>Terms Conditions</NavLink>
          <NavLink exact to={{ pathname: '/privacy-policies', search: this.props.location.search }}>Top Countries</NavLink>
        </div>
        <div className="nav with-margin">
          <NavLink to={{ pathname: '/join-us', search: this.props.location.search }}>Join as a Consultant</NavLink>
        </div>
        <div className="social-icons">
          <a href="https://www.facebook.com/profile.php?id=100089344030351&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="fb">&nbsp;</a>
          <a href="https://www.instagram.com/favuni_global/" target="_blank" rel="noopener noreferrer" className="insta">&nbsp;</a>
          <a href="https://www.linkedin.com/company/favuni-global-opc-pvt-limited/" target="_blank" rel="noopener noreferrer" className="linkedin">&nbsp;</a>
          {/* <a href=""><img src={iconEight} alt="" /></a> */}
        </div>
        <p className="text-two">© 2021, Favuni Global. All rights reserved.</p>
      </div>
    );
  }
}

export default withRouter(Footer);

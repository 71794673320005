
import { USERS_PROFILE } from '../constants/types';

export default function users(state = {}, action = {}) {
  switch (action.type) {
    case USERS_PROFILE:
      return action.users;
    default:
      return state;
  }
}
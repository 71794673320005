import React from 'react';
import { useSelector } from 'react-redux';
import PhoneIcon from '../../images/icons/call.png';
import rightImg from '../../images/icons/rightdemo.png';

function RightPanel() {
  const user = useSelector(state => state.user);

  function gotoWebsite() {
    window.open(user.student_site[0].url, "_blank")
  }
  return <div className="right-panel">
    {
      user && user.student_site && user.student_site.length ? <div className="card-one">
       <div>
          <p className="text-one">Create your website you’re proud of</p>
          <p className="text-two">Quickly and easily in 30 seconds</p>
          <button className="button blue small" onClick={gotoWebsite} >Get Started</button>
       </div>
        <div className="ringt-image">
          <img src={rightImg} alt="" />
        </div>
      </div> : null
    }

    <div className="contact-us-card">
      <div>
        <p className="text-one">We’re here to help</p>
        <p className="text-two">Contact</p>
        <div className="text-two">
        <a href="tel:+918792740458">+91 8792740458</a>
        </div>
      </div>
      <div>
        <div className="circle">
          <a href="tel:+918792740458"> <img src={PhoneIcon} alt="" /> </a>
        </div>
      </div>
    </div>
    {/* <div className="helper-card">
      <img src={ChecklistIcon} alt="" />
      <p className="text-one">How to Join session</p>
      <p className="text-two">To prepare for the first demo class, please follow these 3 simple steps</p>
    </div> */}
  </div>
}

export default RightPanel;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../../actions/modal';
import { useHistory } from "react-router-dom";
import api from '../../commonjs/api';
import cross from '../../images/static/cross.svg';
import { userLoggedIn, clearStore } from '../../actions/auth';
import { CLIENT_ID } from '../../constants/config';
import IndiaFlag from '../../images/signup/india-flag.svg';
import { errorHandler, getParameterByName, toUtcAmPm, setUserData, setAxiosHeaders, setAccessToken, notify, setLocalData, } from '../../utils/helper';
import InlineError from '../InlineError';


function UpdateNumberModal({ dataNumber, oldData, ...props }) {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({})
    const [disableBtn, setDisableBtn] = useState(true)
    const [loading, setLoading] = useState(false)
    const [resendLoading, setResendLoading] = useState(false)
    const [data, setData] = useState({
        phone: getParameterByName('phone') || '',
        otp: '',
    })

    let history = useHistory();
    function close() {
        dispatch(hideModal(null, {
            ...props
        }));
    }

    function sendOtp() {
        let params = {
            username:oldData.phone,
            un_type: 'phone',
            new_phone: dataNumber.phone,
            client_id: CLIENT_ID
        }
        setLoading(true);
        setResendLoading(true)
        setTimeout( () => {
            setResendLoading(false)
         },10000) 
        api.profile.generateOtp(params)
          .then(response => {
            window.console.log(response);
            setLoading(false);
          })
          .catch(error => {
            console.error(error);
            setLoading(false);
            let tempErrors = errorHandler(error.response, true);
          });
      } 

    function verify() {
        if (!data.otp) {
            setErrors({ ...errors, otp: 'Required Field' });
            window.console.log(errors);
            return;
        }
        if (data.otp && data.otp.length !== 6) {
            setErrors({ ...errors, otp: 'Invalid Otp' });
            window.console.log(errors);
            return;
        }
        let params = {
            username: oldData.phone,
            otp: data.otp,
            new_phone: dataNumber.phone,
            client_id: CLIENT_ID
        }
        console.log("final params", params)
        setLoading(true);

        api.profile.verifyOtp(params)
            .then(async (response) => {
                window.console.log(response);
                window.localStorage.clear();
                dispatch(clearStore());
                // createLead();
                setLoading(false);
                setAccessToken(response.access_token);
                setLocalData('refreshToken', response.refresh_token);
                setAxiosHeaders();
                setUserData({ accessToken: response.access_token });
                dispatch(userLoggedIn(response));
                setLocalData('phone', dataNumber.phone);
                let message = 'Thanks, Your phone number has been updated.';
                notify(message, 'success');
                history.push('/dashboard');
                dispatch(hideModal(null, {
                    ...props
                }));
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                let tempErrors = errorHandler(error.response);
                setErrors({ ...errors, otp: error.response.data.response});
                setDisableBtn(true);
                window.console.log(tempErrors);
            });
    }


    function handleChange(e) {
        setData({ ...data, [e.target.name]: e.target.value });
        if (e.target.name === 'otp' && e.target.value) {
            setDisableBtn(false);
          }
    }

    function handleFocus(e) {
        setErrors({ ...errors, [e.target.name]: '' });
    }
    return (
        <div className="modal-window">
            <div className="reschedule-modal update-number modal-content">
                <div className="modal-header-update">
                    <img src={cross} alt="" className="modal-close" onClick={close} />
                    <p className="phone-title-text">Update phone number</p>
                </div>
                <div>
                    <div className="otp-container">
                        <label htmlFor="" className="varify-label">Enter verification code</label>
                        <input autoFocus type="number" placeholder="Enter your OTP" value={data.otp} name="otp" onChange={handleChange} onFocus={handleFocus} className={'otp ' + (errors.otp ? 'error' : '')} />
                        {errors.otp && <InlineError text={errors.otp} />}
                        <p  className={"helper-text " + (resendLoading ? 'disabled' : '')}>I didn’t receive a code <span onClick={sendOtp}>Send again</span></p>
                        <button className={"q-button blue " + (loading || disableBtn? 'disabled' : '')} onClick={verify}>Update</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(UpdateNumberModal);
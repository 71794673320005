import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../actions/modal';
import cross from '../images/static/cross.svg';
import tick from '../images/check.svg';
import { useHistory } from "react-router-dom";


function ThankYouModalStory(props) {
  const dispatch = useDispatch(),
  history = useHistory();
  function close() {
    dispatch(hideModal(null, {
      ...props
    }));
  }

  return (
    <div className="modal-window">
      <div className="thankyou-modal modal-content">
        <img src={cross} alt="" className="modal-close" onClick={close} />
        <div>
          <img src={tick} alt=""/>
          <p className="sub-text">{props.message || 'Thank you for your interest, We’ll verify your details and get in touch shortly'}</p>
          <button className="button blue" onClick={close}>Ok, got it</button>
        </div>
      </div>
    </div>
  )
  
}

export default withRouter(ThankYouModalStory);
import { CONTINENT } from '../constants/types';

function continent(state = null, action = {}) {
  switch (action.type) {
    case CONTINENT:
      return action.continent;
    default:
      return state;
  }
}

export { continent };
import { combineReducers } from 'redux';
import user from './reducers/user';
import users from './reducers/users';
import modal from './reducers/modal';
import clear from './reducers/clear';
import notification from './reducers/notification';
import { country } from './reducers/country';
import { continent } from './reducers/continent';
import { dialcode } from './reducers/dialcode';
import { language, languageMap } from './reducers/language';

const rootReducer = combineReducers({
  user,
  users,
  modal,
  notification,
  language,
  languageMap,
  country,
  continent,
  dialcode
});

export default (state, action) =>
  rootReducer(action.type === 'CLEARSTORE' ? undefined : state, action);
import { Select } from 'antd';
import countries from 'countries-and-timezones';
import lookup from 'country-codes-list';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { lingoqLeads } from '../../actions/auth'; //leads,
import api from "../../commonjs/api";
import { showModal } from '../../actions/modal';
import { setLocalData, validatePhoneNumber, notify, getParameterByName } from '../../utils/helper';
var Recaptcha = require('react-recaptcha');


const phoneHelperText = <span>Please add country code <br /> eg: 915544595445 <br /> Number should be mininmum 8 characters and maximum 12 characters</span>,
    { Option } = Select,
    countryList = countries.getAllCountries(),
    timeZone = moment.tz.guess();

function ContactUsDemoLead({ program = '', course = 'codeq', classLength = 60, action }) {
    const continent = useSelector(state => state.continent),
        country = useSelector(state => state.country),
        [data, setData] = useState({
            first_name: '',
            last_name: '',
            phone_number: '',
            age: '',
            message: '',
            has_tnc_agreed: continent === 'Europe' ? false : true,
            country: !country ? "" : country,
            trimPhone: '',
            goal: '',
        }),
        [errors, setErrors] = useState({}),
        [courses, setCourses] = useState([]),
        [tab, setTab] = useState(""),
        [dialCodeData,setDialCodeData] = useState('91'),
        [countryCode, setCountryCode] = useState('IN'),
        [loading, setLoading] = useState(false),
        [isVarified, setIsVarified] = useState(false),
        [goal, setGoal] = useState([{ name: 'Spoken English',enum:'SPKENG' }, { name:'Overall English', enum:'OALENG'}]),
        [cp, setCp] = useState(false),
        dispatch = useDispatch();



    function thankyouModal(message, type = undefined, e, messageAlreadyRegister) {
        console.log("datya in event",e)
        setLoading(false);
        action();
        if (e && e.response && e.response.status === 409) {
            message = messageAlreadyRegister;
            dispatch(showModal('THANK_YOU_MODAL_STORY', { message, type }));
        } else {
            dispatch(showModal('THANK_YOU_MODAL_STORY', { message, type }));
        }

    }


    useEffect(() => {
        window.console.log(lookup.all());
        let params = {
            is_online: true
        };
        if (program) {
            params.program = program;
        }
        Object.keys(countryList).forEach((key) => {
            if (countryList[key].timezones.includes(timeZone)) {
                // let country = countryList[key].name,
                let trimPhone = lookup.all().filter(c => c.countryNameEn === data.country);

                if (trimPhone.length) {
                    trimPhone = trimPhone[0].countryCallingCode;
                    setCountryCode(trimPhone);
                } else {
                    trimPhone = '91';
                }
                setData({ ...data, country, trimPhone });
            }
        })
    }, []);

    useEffect(() => {
        getCourses();
      }, []);
    
      function getCourses() {
        let params = {
          is_online: true,
        };
        params.program__name = 'lingoq';
        api.masterData.courses(params)
          .then(response => {
            response = response.filter(data => data.description !== '');
            setCourses(response);
          })
          .catch(error => {
            console.error(error);
          });
      }


    function handleChange(e) {
        if (e.target.name === 'age') {
            const re = /^[0-9\b]+$/;
            if (e.target.value === '' || re.test(e.target.value) && (e.target.value >0 && e.target.value <100 )) {
                let tempData = { ...data };
                tempData[e.target.name] = e.target.value;
                setData(tempData);
            }
        } else {
            let tempData = { ...data };
            tempData[e.target.name] = e.target.value;
            setData(tempData);
        }
    }


    const handlePhoneChange = (value, d, e, formattedValue) => {
        window.console.log(value, countryCode, d.dialCode);
        let finalPhone = value.replace(d.dialCode, "");
        console.log("final phone", finalPhone)
        let tempData = { ...data };
        tempData.trimPhone = value;
        tempData.phone_number = finalPhone;
        tempData.country = d.name;
        if (value.toString().length <= countryCode.toString().length) {
            tempData.trimPhone = countryCode;
        }
        setData(tempData);
        setDialCodeData(d.dialCode);
    }

    function validate(params) {
        let errors = {};
        if (!params.first_name || /\S/.test(params.first_name) === false) {
            errors.first_name = true;
        }
        if (!params.trimPhone) {
            errors.trimPhone = true;
        }
        if (!params.age || /\S/.test(params.age) === false) {
            errors.age = true;
        }

        if (params.trimPhone && !validatePhoneNumber(params.phone_number)) {
            errors.trimPhone = true;
        }
        if (!params.goal) {
            errors.goal = true;
          }
        return errors;
    }

    function submit() {
        let tempErrors = validate(data);
        setErrors(tempErrors);
        if (Object.keys(tempErrors).length) {
            return;
        }

        let params = {};
        Object.keys(data).forEach(key => {
            if (data[key]) {
                params[key] = data[key];
            }
        });
        if (!params.country && country) {
            params.country = country;
        }
        params.country_code = dialCodeData;
        console.log("parsnsnjd",params.age);
        const courseData =  courses.filter((data)=>{
            return params.age > data.min_age && params.age <= data.max_age
        })
        if(courseData.length >0){
            params.course= courseData[0].name;
        }
        console.log("datta in ",courseData);
        delete params.trimPhone;
        // delete params.has_tnc_agreed;
        setLocalData('studentDetails', params);
        setLoading(true);
        const message = 'Thank you for submitting your details. We will send your LingoQ material on your phone number shortly';
        const messageAlreadyRegister = 'Hi, Looks like you\'ve already registered with us.We will connect with you shortly for your child\'s Free Demo. Thanks';
         dispatch(lingoqLeads(params, (e) => thankyouModal(message, 'error', e, messageAlreadyRegister)));
    }

    function handleTabChange(value, name) {
        setTab(value);
        let tempData = { ...data };
        tempData[name] = value;
        setData(tempData);
    }

    return <div className="form-component">
        <div className="component">
            <div className="row">
                <div className="col">
                    <input type="text" pattern="[^\s]+" placeholder="Child Name*" name="first_name" value={data.first_name} className={errors.first_name ? 'error' : ''} onChange={handleChange} autoComplete={'a' + Math.random()} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <PhoneInput
                        inputProps={
                            {
                                autoComplete: 'c' + Math.random()
                            }
                        }
                        searchPlaceholder="Search Country"
                        enableSearch={true}
                        disableSearchIcon={true}
                        value={data.trimPhone}
                        onChange={handlePhoneChange}
                        placeholder="Phone Number*"
                        prefix="+"
                        autoFormat={false}
                        containerClass={errors.trimPhone ? 'error' : ''}
                        countryCodeEditable={false}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <input type="text" pattern="[^\s]+" placeholder="Age*" name="age" value={data.age} className={errors.age ? 'error' : ''} onChange={handleChange} autoComplete={'a' + Math.random()} />
                </div>
            </div>


            <div className="form-field book-lead" id="newgoal">
                <label htmlFor="" className="label">
                    Goal
                </label>
                <div className="custom-tab">
                    <div className="tabs">
                        {goal.map((data) => {
                            return (<div
                                // className={"tab disable " + (tab === data.enum ? "active" ? errors.goal ? 'error' : '')}
                                className={"tab disable  " + (tab === data.enum ? "active" : errors.goal ? 'error' : '')}
                                onClick={() => handleTabChange(data.enum, 'goal')}
                            >
                                <div>
                                    <p>{data.name} </p>
                                </div>
                            </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="row center mt-24">
                <button className={"blue " + (loading ? 'disabled' : '')} onClick={submit}>Submit</button>
            </div>
        </div>
    </div>
}

export default ContactUsDemoLead;
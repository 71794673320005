import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../actions/modal';
import cross from '../images/close-white.svg';
import YouTube from 'react-youtube';

function VideoPlayer(props) {
  const dispatch = useDispatch(),
    { youtubeId } = props;

  function close() {
    dispatch(hideModal(null, {
      ...props
    }));
  }

  function onPlayerReady(event) {
    event.target.mute();
    event.target.playVideo();
  }

  window.console.log(props);

  return (
    <div className="modal-window dark">
      <div className="modal-content video-player">
        <img src={cross} alt="" className="modal-close" onClick={close} />
        <div className="video-container">
          <YouTube
            videoId={youtubeId}
            options={{
              playerVars: {
                autoplay: 1
              }
            }}
            onReady={onPlayerReady} />
        </div>
      </div>
    </div>
  )
}

export default VideoPlayer;
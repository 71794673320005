import { DatePicker, Select } from 'antd';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../actions/modal';
import api from '../commonjs/api';
import cross from '../images/static/cross.svg';
import { errorHandler, getParameterByName, notify } from '../utils/helper';


const minimumDate = moment().add(1, 'days'),
  token = getParameterByName('t'),
  { Option } = Select;

function AddClassModal(props) {
  const dispatch = useDispatch(),
    { title, message, action } = props,
    [slots, setSlots] = useState([]),
    [course, setCourse] = useState(undefined),
    [selectedSlot, setSlot] = useState({}),
    [courses, setCourses] = useState([]),
    [requestDone, setRequestDone] = useState(false),
    [loading, setLoading] = useState(false),
    [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    getStudentCourseView(token);
  }, []);

  useEffect(() => {
    if (course && selectedDate) {
      let date = selectedDate.clone();
      let startOfDay = moment(date).startOf('day'),
        endOfDay = moment(date).add(1, 'days').startOf('day'),
        utcTime = moment.utc(startOfDay).format('hh:mma'),
        utcDate = moment.utc(startOfDay).format('YYYY-MM-DD'),
        utcEndDate = moment.utc(endOfDay).format('YYYY-MM-DD');

      if (moment(date).isSame(moment(), 'day')) {
        date = moment.utc(date);
      }
      let params = {
        start_date: date.format('YYYY-MM-DDThh:mma'),
        end_date: utcEndDate + 'T' + utcTime,
        t: token
      };
      if (!moment(date).isSame(moment(), 'day')) {
        params.start_date = utcDate + 'T' + utcTime;
      }
      demoSlotsPerDay(params);
    }
  }, [course]);


  function close() {
    dispatch(hideModal(null, {
      ...props
    }));
  }

  function selectSlot(params) {
    setSlot(params);
  }

  function getStudentCourseView(t) {
    api.scheduler.getStudentCourseView({ t })
      .then(response => {
        // window.console.log(response);
        setCourses(response.courses);
        if (response.courses.length === 1) {
          setCourse(response.courses[0].c);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  function demoSlotsPerDay(params = {}) {
    window.console.log(course, courses);
    if (courses.length && !course) {
      return;
    }
    if (course && courses.length) {
      let temp = courses.filter(c => c.c === course);
      if (temp.length) {
        params.course_id = temp[0].course_id;
        params.trainer_id = temp[0].trainer_id;
      }
    }
    window.console.log('Params', params);
    notify('page not found', 'info');
    api.scheduler.getClass(params)
      .then(response => {
        setRequestDone(true);
        setSlots(response.events);
      })
      .catch(error => {
        console.error(error);
        errorHandler(error.response, true);
      });
  }

  // function onChange(date, dateString) {
  //   let params = {
  //     start_date: moment(date).format('YYYY-MM-DD') + 'T' + (moment(date).format('hh:mm a').replace(' ', '')),
  //     end_date: moment(date).add(1, 'days').format('YYYY-MM-DD') + 'T12:00am',
  //     t: token
  //   };
  //   setSelectedDate(date);
  //   demoSlotsPerDay(props.data.id, params);
  // }

  function onChange(d, dateString) {
    if (!d) {
      setSlots([]);
      setSlot({});
      setSelectedDate(null);
      return;
    }
    let date = d.clone();
    let startOfDay = moment(date).startOf('day'),
      endOfDay = moment(date).add(1, 'days').startOf('day'),
      utcTime = moment.utc(startOfDay).format('hh:mma'),
      utcDate = moment.utc(startOfDay).format('YYYY-MM-DD'),
      utcEndDate = moment.utc(endOfDay).format('YYYY-MM-DD');

    if (moment(date).isSame(moment(), 'day')) {
      date = moment.utc(date);
    }
    let params = {
      start_date: date.format('YYYY-MM-DDThh:mma'),
      end_date: utcEndDate + 'T' + utcTime,
      t: token
    };
    if (!moment(date).isSame(moment(), 'day')) {
      params.start_date = utcDate + 'T' + utcTime;
    }
    setSelectedDate(date);
    setSlot({});
    demoSlotsPerDay(params);
  }

  function confirm() { //to be deprecated
    let params = {
      t: token,
      event: parseInt(selectedSlot.id),
    };
    if (course && courses.length) {
      let temp = courses.filter(c => c.c === course);
      if (temp.length) {
        params.course_id = temp[0].course_id;
        params.trainer_id = temp[0].trainer_id;
      }
    }
    setLoading(true);
    api.scheduler.addClass(params)
    notify('page not found', 'error');
    // .then(response => {
    //   notify('Class has been booked successfully', 'success');
    //   close();
    //   if (props.action) {
    //     props.action();
    //   }
    //   setLoading(false);
    // })
    // .catch(error => {
    //   console.error(error);
    //   errorHandler(error.response, true);
    //   setLoading(false);
    // });
  }

  function handleSelectChange(name, value) {
    window.console.log(name, value);
    setCourse(value);
  }

  function renderInfo(params) {
    if (Object.keys(selectedSlot).length) {
      return <div className="info-wrap">
        <p className="text">Your demo class date and time is : <span>{moment(selectedSlot.start).format('LL')} &nbsp; &nbsp;   {moment(selectedSlot.start).format('hh:mm a')} - {moment(selectedSlot.end).format('hh:mm a')}</span></p>
      </div>
    }
    if (requestDone && !slots.length) {
      return <div className="info-wrap">
        <p className="text">No classes on : <span>{moment(selectedDate).format('LL')}</span></p>
      </div>
    }
  }

  return (
    <div className="modal-window">
      <div className="reschedule-modal modal-content">
        <img src={cross} alt="" className="modal-close" onClick={close} />
        <div>
          <div className="content">
            <p className="text">You can book an extra class be selecting date below. If you want to reschedule/ cancel an existing class, please click on the respective date and click reschedule/ cancel.</p>
            {
              courses.length > 1 ? <div className="form-field" id="course">
                <label htmlFor="" className="label">Select Course</label>
                <Select defaultValue="" name="course" onChange={handleSelectChange.bind(null, 'course')} value={course}>
                  {
                    courses.map((course, index) => {
                      return <Option key={index} value={course.c}>{course.course__name} - {course.trainer__name}</Option>
                    })
                  }
                </Select>
              </div> : null
            }
            <p className="sub-text">Add Extra Class</p>
            <DatePicker onChange={onChange} disabledDate={d => !d || d.isBefore(minimumDate)} allowClear={false} />
            {
              slots.length ? <div className="">
                <p className="label">Available time slots</p>
                <div className="available-slots-wrapper">
                  {
                    slots.map((slot, index) => {
                      return <div onClick={selectSlot.bind(null, slot)} className={"time-slot " + (slot === selectedSlot ? 'selected' : '')} key={index}>{moment(slot.start).local().format('hh:mm a')} - {moment(slot.end).local().format('hh:mm a')}</div>
                    })
                  }
                </div>
              </div> : null
            }
            {
              renderInfo()
            }
            <div className="buttons-wrap">
              <button className={"q-button blue " + (!Object.keys(selectedSlot).length ? 'disabled' : 'disabled') + (loading ? ' disabled ' : 'disabled')} onClick={confirm}>Confirm</button>
            </div>
            <p className="info-text">Once you’ve confirmed your slot, you’ll receive a confirmation email</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(AddClassModal);
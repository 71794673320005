import React from "react";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { showModal } from "../../actions/modal";
import { BOOK_DEMO_MODAL } from "../../constants/types";
import AgeIcon from "../../images/homepage/icon-age.svg";
import SessionIcon from "../../images/homepage/icon-session.svg";
import { useLocation } from "react-router-dom";

function CourseCard({ data, index, type = undefined }) {
  const classMap = {
      1: "one",
      2: "two",
      3: "three",
      4: "four",
      5: "five",
      6: "six",
    },
    history = useHistory(),
    dispatch = useDispatch(),
    location = useLocation();

  let rand = ((Math.random() * 6) | 0) + 1,
    randonNumber = 0;

  if (rand === randonNumber) {
    randonNumber = ((Math.random() * 6) | 0) + 1;
  } else {
    randonNumber = rand;
  }

  function gotoDetails(id) {
    history.push({
      pathname: `/course/${id}`,
      search: location.search,
    });
  }

  function bookDemoModal(course = null, e) {
    e.stopPropagation();
    window.console.log("bookDemoModal", course);
    history.push(`/book-demo${location.search}${location.search ? '&' : '?'}program=${type}&course=${course.id} `);
    // dispatch(showModal(BOOK_DEMO_MODAL, { program: 'lingoq' }));
  }

  return (
    <div
      className={"class-card " + classMap[randonNumber]}
      key={index}
      onClick={gotoDetails.bind(null, data.id)}
    >
      <div className="img-wrap">
        <div>
          <p className="text-one">{data.name}</p>
          <p className="text-two">{data.description}</p>
          <p className="line"></p>
          <div className="flex-wrap">
            <p className="text-three">
              <img src={SessionIcon} alt="" />{" "}
              <span>{data.price ? data.price[0].classes : ""} Sessions</span>
            </p>
            <p className="text-three">
              <img src={AgeIcon} alt="" /> Age : {data.min_age} - {data.max_age}{" "}
            </p>
          </div>
        </div>
      </div>
      <div className={"content " + (type || "")}>{data.display_text}</div>
      {data.icons && data.icons.length ? (
        <div className="icons-wrap">
          {data.icons.map((icon, ind) => {
            return (
              <div className="icon" key={ind}>
                <img src={icon} alt="" />
              </div>
            );
          })}
        </div>
      ) : null}
      {data.tags && data.tags.length ? (
        <div className="tags-wrap">
          {data.tags.map((tag, ind) => {
            return (
              <div className="tag" key={ind}>
                <img src={tag.url} alt="" />
                <span>{tag.name}</span>
              </div>
            );
          })}
        </div>
      ) : null}
      <div className="pricing-wrap">
        {data.price &&
          data.price.map((price, index) => {
            return (
              <div className="pricing" key={index}>
                <p className="text-one">{price.class_type} </p>
                <p className="text-two">
                  {price.currency} {parseInt(price.amount)}{" "}
                </p>
                {/* <p className="text-one">
                  {price.name} <span>{price.classes} sessions</span>
                </p> */}
              </div>
            );
          })}
      </div>

      <div className="bottom-section">
        <button className="blue" onClick={bookDemoModal.bind(null, data)}>
          Book Free Demo
        </button>
        <p className="link"> View course details</p>
      </div>
    </div>
  );
}

export default CourseCard;

export const english = {
  views: {
    masterClasses: {
      titleText: 'Studey in united Aus',
      subText: 'Improve your Grammar, Pronunciation & Fluency through our Live English Teaching Series by top teachers in India, from the comfort of your home!',
      bannerRow: {
        colOne: {
          textOne: 'FREE live interactive classes',
          textTwo: 'Attend live interactive classes online with experts.'
        },
        colTwo: {
          textOne: 'Learn from experts',
          textTwo: 'Learn from the experts - India’s best teachers.'
        },
        colThree: {
          textOne: 'Learn by practice',
          textTwo: 'Access to quality education anytime, anywhere.'
        }
      },
      filterText: {
        textOne: 'Showing',
        textTwo: 'courses for all age group'
      },
      infoBar: {
        colOne: {
          textOne: '50+',
          textTwo: 'Group Classes'
        },
        colTwo: {
          textOne: '100,000+',
          textTwo: 'Students Attended'
        },
        colThree: {
          textOne: '75+',
          textTwo: 'Topics for groups'
        },
        colFour: {
          textOne: '200+',
          textTwo: 'Classes Completed'
        },
      },
      courseCard: {
        age: 'For Ages',
        trainer: 'Trainer',
        joinClass: 'JOIN CLASS FOR FREE',
        years: 'years'
      }
    },
    masterIreland: {
      titleText: 'Study in Ireland',
      subText: 'Ireland is one of the most ingenious countries in the world. Its creativity comes not from broad numbers, but from brilliant minds educated at Ireland universities. !',
      bannerRow: {
        colOne: {
          textOne: 'FREE live webinars',
          textTwo: 'Attend live interactive webinars with experts.'
        },
        colTwo: {
          textOne: 'Consult from experts',
          textTwo: 'Get free consult from the experts - Our best consultant.'
        },
        colThree: {
          textOne: 'Quality education',
          textTwo: 'Access to quality education anytime, anywhere.'
        }
      },
      filterText: {
        textOne: 'Testimonial',
        textTwo: 'courses for all age group'
      },
      infoBar: {
        colOne: {
          textOne: '50+',
          textTwo: 'Group Classes'
        },
        colTwo: {
          textOne: '100,000+',
          textTwo: 'Students Attended'
        },
        colThree: {
          textOne: '75+',
          textTwo: 'Topics for groups'
        },
        colFour: {
          textOne: '200+',
          textTwo: 'Classes Completed'
        },
      },
      courseCard: {
        age: 'For Ages',
        trainer: 'Trainer',
        joinClass: 'JOIN CLASS FOR FREE',
        years: 'years'
      }
    },
    masterEurope: {
      titleText: 'Study in Sweden',
      subText: 'Sweden is one of the most ingenious countries in the world. Its creativity comes not from broad numbers, but from brilliant minds educated at Swedish universities. !',
      bannerRow: {
        colOne: {
          textOne: 'FREE live webinars',
          textTwo: 'Attend live interactive webinars with experts.'
        },
        colTwo: {
          textOne: 'Consult from experts',
          textTwo: 'Get free consult from the experts - Our best consultant.'
        },
        colThree: {
          textOne: 'Quality education',
          textTwo: 'Access to quality education anytime, anywhere.'
        }
      },
      filterText: {
        textOne: 'Testimonial',
        textTwo: 'courses for all age group'
      },
      infoBar: {
        colOne: {
          textOne: '50+',
          textTwo: 'Group Classes'
        },
        colTwo: {
          textOne: '100,000+',
          textTwo: 'Students Attended'
        },
        colThree: {
          textOne: '75+',
          textTwo: 'Topics for groups'
        },
        colFour: {
          textOne: '200+',
          textTwo: 'Classes Completed'
        },
      },
      courseCard: {
        age: 'For Ages',
        trainer: 'Trainer',
        joinClass: 'JOIN CLASS FOR FREE',
        years: 'years'
      }
    },
    masterUsa: {
      titleText: 'Study in USA',
      subText: 'You’ll quest the feasible for exciting experiences and incredible opportunities to work, learn and grow, as a scholar and as a fellow.!',
      bannerRow: {
        colOne: {
          textOne: 'FREE live Webinars',
          textTwo: 'Attend live interactive webinars online with experts.'
        },
        colTwo: {
          textOne: 'Consult from experts',
          textTwo: 'Advice from the experts - India’s best experts.'
        },
        colThree: {
          textOne: 'Quality Education',
          textTwo: 'We assist for the quality education anytime, anywhere.'
        }
      },
      filterText: {
        textOne: 'Testimonial',
        textTwo: 'courses for all age group'
      },
      infoBar: {
        colOne: {
          textOne: '50+',
          textTwo: 'Group Classes'
        },
        colTwo: {
          textOne: '100,000+',
          textTwo: 'Students Attended'
        },
        colThree: {
          textOne: '75+',
          textTwo: 'Topics for groups'
        },
        colFour: {
          textOne: '200+',
          textTwo: 'Classes Completed'
        },
      },
      courseCard: {
        age: 'For Ages',
        trainer: 'Trainer',
        joinClass: 'JOIN CLASS FOR FREE',
        years: 'years'
      }
    },
    masterUk: {
      titleText: 'Study in United Kingdom',
      subText: 'UK offers a world-class education, cultural diversity, and a global perspective. Various scholarships and funding options are available for international students.',
      bannerRow: {
        colOne: {
          textOne: 'FREE live Webinars',
          textTwo: 'Attend live interactive webinars online with experts.'
        },
        colTwo: {
          textOne: 'Consult from experts',
          textTwo: 'Advice from the experts - India’s best experts.'
        },
        colThree: {
          textOne: 'Quality Education',
          textTwo: 'We assist for the quality education anytime, anywhere.'
        }
      },
      filterText: {
        textOne: 'Testimonial',
        textTwo: 'courses for all age group'
      },
      infoBar: {
        colOne: {
          textOne: '50+',
          textTwo: 'Group Classes'
        },
        colTwo: {
          textOne: '100,000+',
          textTwo: 'Students Attended'
        },
        colThree: {
          textOne: '75+',
          textTwo: 'Topics for groups'
        },
        colFour: {
          textOne: '200+',
          textTwo: 'Classes Completed'
        },
      },
      courseCard: {
        age: 'For Ages',
        trainer: 'Trainer',
        joinClass: 'JOIN CLASS FOR FREE',
        years: 'years'
      }
    },
    masterAustralia: {
      titleText: 'Study in Australia',
      subText: 'Study and work opportunities, diverse cultural experiences, beautiful countries to live in and amazing lifestyle.',
      bannerRow: {
        colOne: {
          textOne: 'FREE live webinars',
          textTwo: 'Attend live interactive webinars online with experts.'
        },
        colTwo: {
          textOne: 'Consult from experts',
          textTwo: 'Consult from the experts - India’s best consultant.'
        },
        colThree: {
          textOne: 'Quality Education',
          textTwo: 'We are assisting to quality education anytime, anywhere.'
        }
      },
      filterText: {
        textOne: 'Testimonial',
        textTwo: 'courses for all age group'
      },
      infoBar: {
        colOne: {
          textOne: '50+',
          textTwo: 'Group Classes'
        },
        colTwo: {
          textOne: '100,000+',
          textTwo: 'Students Attended'
        },
        colThree: {
          textOne: '75+',
          textTwo: 'Topics for groups'
        },
        colFour: {
          textOne: '200+',
          textTwo: 'Classes Completed'
        },
      },
      courseCard: {
        age: 'For Ages',
        trainer: 'Trainer',
        joinClass: 'JOIN CLASS FOR FREE',
        years: 'years'
      }
    },
    masterCanada: {
      titleText: 'Study in Canada',
      subText: 'Study and work opportunities, diverse cultural experiences, beautiful countries to live in and amazing lifestyle.',
      bannerRow: {
        colOne: {
          textOne: 'FREE live webinars',
          textTwo: 'Attend live interactive webinars online with experts.'
        },
        colTwo: {
          textOne: 'Consult from experts',
          textTwo: 'Consult from the experts - India’s best consultant.'
        },
        colThree: {
          textOne: 'Quality Education',
          textTwo: 'We are assisting to quality education anytime, anywhere.'
        }
      },
      filterText: {
        textOne: 'Testimonial',
        textTwo: 'courses for all age group'
      },
      infoBar: {
        colOne: {
          textOne: '50+',
          textTwo: 'Group Classes'
        },
        colTwo: {
          textOne: '100,000+',
          textTwo: 'Students Attended'
        },
        colThree: {
          textOne: '75+',
          textTwo: 'Topics for groups'
        },
        colFour: {
          textOne: '200+',
          textTwo: 'Classes Completed'
        },
      },
      courseCard: {
        age: 'For Ages',
        trainer: 'Trainer',
        joinClass: 'JOIN CLASS FOR FREE',
        years: 'years'
      }
    },
    masterClassesAdult: {
      titleText: 'Take your english <br /> speaking to the next level',
      subText: 'Advanced spoken english classes at home for',
      text: '18 years and above • Starts at <span>₹1300</span>',
      buttonText: 'BOOK FREE TRIAL',
      bannerRow: {
        colOne: {
          textOne: 'Live Intercactive classes',
          textTwo: 'Attend Free Live Interactive classes '
        },
        colTwo: {
          textOne: 'Learn from masters',
          textTwo: 'Learn from the Masters - India’s best teachers'
        },
        colThree: {
          textOne: 'Learn by doing',
          textTwo: 'Access to quality education anytime, anywhere'
        }
      },
      filterText: {
        textOne: 'Showing',
        textTwo: 'courses for all age group'
      },
      infoBar: {
        colOne: {
          textOne: '50+',
          textTwo: 'Group Classes'
        },
        colTwo: {
          textOne: '100,000+',
          textTwo: 'Students Attended'
        },
        colThree: {
          textOne: '75+',
          textTwo: 'Topics for groups'
        },
        colFour: {
          textOne: '200+',
          textTwo: 'Classes Completed'
        },
      },
      courseCard: {
        age: 'For Ages',
        trainer: 'Trainer',
        joinClass: 'JOIN CLASS FOR FREE',
        years: 'years'
      },
      helperWrapper: {
        textOne: 'Unwrap a new skill',
        textTwo: 'Explore thousands of classes to fuel your <span>english</span> communication skills with a Premium Membership',
        buttonText: 'Learn More'
      }
    }
  }
};

import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../actions/modal';
import api from '../commonjs/api';
import cross from '../images/static/cross.svg';
import { errorHandler, getParameterByName, notify } from '../utils/helper';


const minimumDate = moment(),
  token = getParameterByName('t');

function RescheduleModal(props) {
  const dispatch = useDispatch();
  const { title, message, action } = props;
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSlot] = useState({});
  const [loading, setLoading] = useState(false);
  const [requestDone, setRequestDone] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  window.console.log('Props', props);


  function close() {
    dispatch(hideModal(null, {
      ...props
    }));
  }

  function selectSlot(params) {
    setSlot(params);
  }

  function demoSlotsPerDay(id, params = {}) {
    api.scheduler.getRescheduleClass(id, params)
      .then(response => {
        setRequestDone(true);
        setSlots(response.events);
      })
      .catch(error => {
        console.error(error);
      });
  }

  // function onChange(date, dateString) {
  //   let params = {
  //     start_date: moment(date).format('YYYY-MM-DD') + 'T' + (moment(date).format('hh:mm a').replace(' ', '')),
  //     end_date: moment(date).add(1, 'days').format('YYYY-MM-DD') + 'T12:00am',
  //     t: token
  //   };
  //   setSelectedDate(date);
  //   demoSlotsPerDay(props.data.id, params);
  // }

  function onChange(d, dateString) {
    if (!d) {
      setSlots([]);
      setSlot({});
      setSelectedDate(null);
      return;
    }
    let date = d.clone();
    let startOfDay = moment(date).startOf('day'),
      endOfDay = moment(date).add(1, 'days').startOf('day'),
      utcTime = moment.utc(startOfDay).format('hh:mm a').replace(' ', ''),
      utcDate = moment.utc(startOfDay).format('YYYY-MM-DD'),
      utcEndDate = moment.utc(endOfDay).format('YYYY-MM-DD');

    if (moment(date).isSame(moment(), 'day')) {
      date = date.add(3, 'hours');
      date = moment.utc(date);
    }
    let params = {
      start_date: date.format('YYYY-MM-DD') + 'T' + date.format('hh:mm a').replace(' ', ''),
      end_date: utcEndDate + 'T' + utcTime,
      t: token
    };
    if (!moment(date).isSame(moment(), 'day')) {
      params.start_date = utcDate + 'T' + utcTime;
    }
    setSelectedDate(date);
    setSlot({});
    demoSlotsPerDay(props.data.id, params);
  }

  function confirm() {
    let params = {
      t: token,
      from_event: parseInt(props.data.id),
      to_event: parseInt(selectedSlot.id)
    };
    setLoading(true);
    api.scheduler.rescheduleClass(params)
      .then(response => {
        props.action();
        setLoading(true);
        notify('Class has been rescheduled', 'success');
        close();
      })
      .catch(error => {
        console.error(error);
        errorHandler(error.response, true);
        setLoading(true);
      });
  }

  function renderInfo(params) {
    if (Object.keys(selectedSlot).length) {
      return <div className="info-wrap">
        <p className="text">Your demo class date and time is : <span>{moment(selectedSlot.start).format('LL')} &nbsp; &nbsp;   {moment(selectedSlot.start).format('hh:mm a')} - {moment(selectedSlot.end).format('hh:mm a')}</span></p>
      </div>
    }
    if (requestDone && !slots.length) {
      return <div className="info-wrap">
        <p className="text">No classes on : <span>{moment(selectedDate).format('LL')}</span></p>
      </div>
    }
  }

  return (
    <div className="modal-window">
      <div className="reschedule-modal modal-content">
        <img src={cross} alt="" className="modal-close" onClick={close} />
        <div>
          <div className="content">
            <p className="sub-text">Kindly select the date and time slot to reschedule your {props.data.course} class</p>
            <DatePicker onChange={onChange} disabledDate={d => !d || d.isBefore(minimumDate)} allowClear={false} />
            {
              slots.length ? <div className="">
                <p className="label">Available time slots</p>
                <div className="available-slots-wrapper">
                  {
                    slots.map((slot, index) => {
                      return <div onClick={selectSlot.bind(null, slot)} className={"time-slot " + (slot === selectedSlot ? 'selected' : '')} key={index}>{moment(slot.start).local().format('hh:mm a')} - {moment(slot.end).local().format('hh:mm a')}</div>
                    })
                  }
                </div>
              </div> : null
            }
            {
              renderInfo()
            }
            <div className="buttons-wrap">
              <button className={"q-button blue " + ((!Object.keys(selectedSlot).length || loading) ? 'disabled' : '')} onClick={confirm}>Confirm</button>
            </div>
            <p className="info-text">Once you’ve confirmed your slot, you’ll receive a confirmation email</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(RescheduleModal);
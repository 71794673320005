import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import Logo from '../../images/onboarding/logo.svg';
import LogoOne from '../../images/onboarding/logo-one.svg';
import ImgOne from '../../images/onboarding/two.svg';
import ImgTwo from '../../images/onboarding/three.svg';
import ImgThree from '../../images/onboarding/four.svg';
import Slider from "react-slick";
import { hideModal } from '../../actions/modal';

function Carousel({close, action}) {
  const history = useHistory();
  let settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    draggable: true
  };

  function closeModal() {
    action();
    close();
  }

  return (
    <div className="content-wrapper">
      <div className="logo-wrap">
        <img src={LogoOne} alt="" />
      </div>
      <Slider {...settings} className="carousel-wrapper">
        <div className="c-item">
          <div className="text-wrap">
            <p>Favuni is an online learning platform for kids of ages 6-18</p>
          </div>
          <div className="img-wrap">
            <img src={ImgOne} alt="" />
          </div>
        </div>
        <div className="c-item">
          <div className="text-wrap">
            <p>1:1 and 1:2 online classes in Coding and English - Learn at Your Own Pace</p>
          </div>
          <div className="img-wrap">
            <img src={ImgTwo} alt="" />
          </div>
        </div>
        <div className="c-item">
          <div className="text-wrap">
            <p>Personalized Curriculum, Timely Feedback, and Fun & Interactive Sessions - The Complete Learning Experiences</p>
          </div>
          <div className="img-wrap">
            <img src={ImgThree} alt="" />
          </div>
        </div>
      </Slider>
      <div className="button-wrap">
        <button className="blue" onClick={closeModal}>Get Started</button>
      </div>
    </div>
    
  )
}

function Onboarding(props) {
  const dispatch = useDispatch();

  window.console.log(props);

  function close() {
    dispatch(hideModal(null, {
      ...props
    }));
  }

  return (
    <div className="onboarding-layout">
      {/* <div className="step-one">
        <div className="content">
          <img src={Logo} alt=""/>
          <p>Rediscover Learning</p>
        </div>
      </div> */}
      <Carousel close={close} action={props.action} />
    </div>
  )
}

export default Onboarding;
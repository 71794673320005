import React, {useState} from 'react';
import { renderToString } from 'react-dom/server'
import { DatePicker } from 'antd';
import { useDispatch } from 'react-redux';
///import htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import { hideModal } from '../../../actions/modal';
import cross from '../../../images/static/cross.svg';
import api from '../../../commonjs/api';

import StudentClasses from '../downloads/StudentClasses';


const { RangePicker } = DatePicker;

function DateRangeSelector(props) {
  const dispatch = useDispatch(),
    [events, setEvents] = useState([]);

  function close() {
    dispatch(hideModal(null, {
      ...props
    }));
  }

  function handleChange(date, dateString) {
    window.console.log(date, dateString);
    studentCalendar(props.userId);
  }

  function studentCalendar(id) {
    api.platform.studentCalendar(id)
      .then(response => {
        setEvents(response.events);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const downloadImage = (params) => {
    // const node = document.getElementById('student-classes');
    // window.console.log(node);
    // htmlToImage.toPng(node, {style: {
    //     opacity: '1'
    //   }})
    //   .then(function (dataUrl) {
    //     let link = document.createElement('a');
    //     link.download = 'student-classes.png';
    //     link.href = dataUrl;
    //     link.click();
    //     close();
    //   });
  };

  const downloadPdf = (params) => {
    // htmlToImage.toCanvas(document.getElementById('student-events'), { quality: 0.95 })
    //   .then(function (canvas) {
    //     let pdf;
    //     if (canvas.width > canvas.height) {
    //       pdf = new jsPDF('l', 'mm', [canvas.width, canvas.height]);
    //     } else {
    //       pdf = new jsPDF('p', 'mm', [canvas.height, canvas.width]);
    //     }

    //     pdf.addImage(canvas, 'PNG', 40, 6);
    //     pdf.save("student-classes.pdf");
    //   });
  };

  return (
    <div className="modal-window">
      <div className="date-range-selector modal-content">
        <img src={cross} alt="" className="modal-close" onClick={close} />
        <div>
          <p className="title-text">Download Classlog</p>
          <div className="form-wrapper">
            <div className="form-field">
              <label htmlFor="Select date range" className="label">Select date range</label>
              <RangePicker
                onChange={handleChange}
                style={{width: '100%'}}
                format="LL"
                allowClear={false}
              />
            </div>
            <div className="form-field no-margin">
              <button className={"button blue " + (!events.length ? 'disabled' : '')} onClick={downloadImage}>Download</button>
            </div>
          </div>
        </div>
      </div>
      <StudentClasses events={events} />
    </div>
  )
}

export default DateRangeSelector;
import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useSelector } from 'react-redux';

import Home from '../../images/mobile-design/home.svg';
import Curriculum from '../../images/mobile-design/curriculum.svg';
import Classlogs from '../../images/mobile-design/classlogs.svg';
import Payments from '../../images/mobile-design/payments.svg';
import Profile from '../../images/mobile-design/profile.svg';
import api from '../../commonjs/api';

function BottomNavigation() {
  const usr = useSelector(state => state.user),
   [states, setStaes] = useState({});

  useEffect(() => {
    window.console.log('BOTTOM NAVIGATION __________________', usr);
    if (usr && usr.id) {
      getCurriculum(usr.id);
    }
  }, [usr]);

  function getCurriculum(id = usr.id) {
    api.platform.curriculum(id)
      .then(response => {
        window.console.log(response);
        if (!response.result.length) {
          setStaes({ ...states, curriculum: true });
        } else {
          setStaes({ ...states, curriculum: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }  

  return <div className="mobile-navigation">
    <div>
      {
        !states.curriculum ? <NavLink exact to="/curriculum">
          <img src={Curriculum} alt="" />
          <span>Curriculum</span>
        </NavLink> : null
      }
    </div>
    <div>
      <NavLink exact to="/classlogs">
        <img src={Classlogs} alt="" />
        <span>Classlogs</span>
      </NavLink>
    </div>
    <div className={"home " + (window.location.pathname === '/dashboard' ? 'active' : '')}>
      <div>
        <NavLink exact to="/dashboard">
          <img src={Home} alt="" />
        </NavLink>
      </div>
    </div>
    <div>
      <NavLink exact to="/payments">
        <img src={Payments} alt="" />
        <span>Payments</span>
      </NavLink>
    </div>
    <div>
      <NavLink exact to="/schedule">
        <img src={Payments} alt="" />
        <span>schedule</span>
      </NavLink>
    </div>
    <div>
      <NavLink exact to="/profile">
        <img src={Profile} alt="" />
        <span>Profile</span>
      </NavLink>
    </div>
  </div>
}
export default BottomNavigation;
import React from 'react';
import { useSelector } from 'react-redux';

function CompanyDetails() {
  const continent = useSelector(state => state.continent);

  if (continent === 'Europe') {
    return <div className="company-details">
      <div className="col">
        <p className="text-one">Mail us</p>
        <p className="text-two"><a href="mailto:contact@favuniglobal.com" target="_top">contact@favuniglobal.com</a></p>
      </div>
      <div className="col">
        <p className="text-one"> 24/7 Helpline</p>
        <p className="text-two">
          <span>
            <a href="tel:917007835912">+91&nbsp;&nbsp;70078 35912</a>
          </span>
        </p>
      </div>
    </div>
  }

  return <div className="company-details">
    <div className="col">
      <p className="text-one">Location</p>
      <p className="text-two">Favuni Global, Regus Business Centre,Floor No.7,<br/>  Logix City Centre, Noida, Uttar Pradesh -201301</p>
    </div>
    <div className="col">
      <p className="text-one">Mail us</p>
      <p className="text-two"><a href="mailto:contact@favuniglobal.com" target="_top">contact@favuniglobal.com</a></p>
      <p className="text-two"><a href="mailto:students@favuniglobal.com" target="_top">students@favuniglobal.com</a></p>
    </div>
    <div className="col">
      <p className="text-one"> 24/7 Helpline</p>
      <p className="text-two">
        <span>
          <a href="tel:+919289633147">+91&nbsp;&nbsp;9289633147</a> </span> <br/>
          <span><a href="tel:917007835912">+91&nbsp;&nbsp;70078 35912</a>
        </span>
        </p>
    </div>
  </div>
}
export default CompanyDetails;
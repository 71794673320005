import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../actions/modal';
import cross from '../images/static/cross.svg';


function ConfirmationModal(props) {
  const dispatch = useDispatch();
  const { title, message, action, typeData, DataError } = props;

  function close() {
    dispatch(hideModal(null, {
      ...props
    }));
  }

  function submit() {
    if (action) {
      action();
    }
    close();
  }
  console.log("typeDatatypeData", typeData, DataError)
  return (
    <div className="modal-window">
      <div className="confirmation-modal modal-content">
        <img src={cross} alt="" className="modal-close" onClick={close} />
        <div>
          <div className="content">
            {
              title ? <p className="title-text">{title}</p> : null
            }
            {
              message ? <p className="sub-text">{message}</p> : null
            }
            {typeData === 'LinkType' ? <a className="link-type" href={DataError} target="_blank">{DataError}</a> : <></>}
            <div className="buttons-wrap">
              <button className="q-button blue-bordered" onClick={close}>Cancel</button>
              {typeData === 'LinkType' ? <></> : <button className="q-button blue" onClick={submit}>Yes</button>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ConfirmationModal);
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { hideModal } from '../../../actions/modal';
import cross from '../../../images/static/cross.svg';
import api from '../../../commonjs/api';
import { errorHandler, notify } from '../../../utils/helper';

const REJECT_MESSAGE = 'Sorry, looks like we’ll have to cancel as we arent able to find the right slot. See you again in next class';

function EventConfirmation(props) {
  const dispatch = useDispatch(),
    [events, setEvents] = useState([]),
    [loading, setLoading] = useState(true);

  window.console.log(props);

  useEffect(() => {
    if(props.token) {
      scheduleApprovalToken({ t: props.token });
    } else {
      scheduleApproval(props.userId);
    }
  }, []);

  useEffect(() => {
    if(!events.length && !loading) {
      close();
    }
  }, [events, loading]);

  function close() {
    dispatch(hideModal(null, {
      ...props
    }));
  }

  function scheduleApproval(id, action) {
    setLoading(true);
    api.platform.scheduleApproval(id)
      .then(response => {
        setEvents(response.events);
        if (!response.events || !response.events.length) {
          if (action && props.action) {
            props.action();
          }
        }
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        errorHandler(error.response, true);
      });
  }

  function postScheduleApproval(id, params={}) {
    let message = REJECT_MESSAGE;
    if (params.is_approved) {
      let event = events.filter(event => event.to_event.id === params.to_event_id)[0];
      message = `Thank you. Your make up class for ${moment(event.to_event.start).format('LL')}  ${moment(event.to_event.start).format('hh:mma')} -  ${moment(event.to_event.end).format('hh:mma')} is confirmed`;
    }
    setLoading(true);
    api.platform.postScheduleApproval(id, params)
      .then(response => {
        window.console.log(response);
        scheduleApproval(props.userId, true);
        setLoading(false);
        notify(message, 'success');
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }

  function scheduleApprovalToken(params, action) {
    setLoading(true);
    api.platform.scheduleApprovalToken(params)
      .then(response => {
        setEvents(response.events);
        if (!response.events.length) {
          if (action) {
            props.action();
          }
        }
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        errorHandler(error.response, true);
      });
  }

  function postScheduleApprovalToken(params = {}) {
    let message = REJECT_MESSAGE;
    if (params.is_approved) {
      let event = events.filter(event => event.to_event.id === params.to_event_id)[0];
      message = `Thank you. Your make up class for ${moment(event.to_event.start).format('LL')}  ${moment(event.to_event.start).format('hh:mma')} - ${moment(event.to_event.end).format('hh:mma')} is confirmed`;
    }
    setLoading(true);
    api.platform.postScheduleApprovalToken(params)
      .then(response => {
        window.console.log(response);
        scheduleApprovalToken({ t: props.token });
        setLoading(false);
        notify(message, 'success');
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }

  function takeAction(is_approved, event) {
    if(props.token) {
      postScheduleApprovalToken({ to_event_id: event.to_event.id, is_approved, t: props.token });
      return;
    }
    postScheduleApproval(props.userId, { to_event_id: event.to_event.id, is_approved })
  }

  if(!events.length) {
    return null
  }

  return (
    <div className="modal-window">
      <div className="event-confirmation modal-content">
        <img src={cross} alt="" className="modal-close" onClick={close} />
        <p className="title-text">Please Confirm</p>
        <div className="events-wrapper">
          {
            events.map((event, ind) => {
              return <div className="event" key={ind} >
                <p className="text-one">Trainer: {event.trainer_name} </p>
                <p className="text-one">Course: {event.to_event.course} </p>
                <p className="text-one">Current Timing: {moment(event.start).format('ll')} {moment(event.start).format('hh:mma')} - {moment(event.end).format('hh:mma')} </p>
                <p className="text-one">Reschedule Timing: {moment(event.to_event.start).format('ll')} {moment(event.to_event.start).format('hh:mma')} - {moment(event.to_event.end).format('hh:mma')} </p>
                <div className="buttons-wrap">
                  <button className={"reject " + (loading ? 'disabled' : '')} onClick={takeAction.bind(null, false, event)} >Reject</button>
                  <button className={"accept " + (loading ? 'disabled' : '')} onClick={takeAction.bind(null, true, event)} >Approve</button>
                </div>
              </div>
            })
          }
        </div>
      </div>
    </div>
  )
}

export default EventConfirmation;
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../actions/modal';
import Close from '../../images/static/cross.svg';

const Component = ({ close, children }) => {
  let component = React.cloneElement(children, { close })
  return <>
    {component}
  </>
}

function Modal(props) {
  const dispatch = useDispatch();

  function close() {
    if (props.close) {
      props.close();
    }
    dispatch(hideModal(null, {
      ...props
    }));
  }

  useEffect(() => {
    if (props.hideModal) {
      close();
    }
  }, [props.hideModal]);

  window.console.log(props);

  return (
    <div className="modal-window" onClick={(e) => e.stopPropagation()}>
      <div className={"modal-content " + (props.size || props.className || '')} id="modal">
        <img src={Close} className="modal-close" alt="" onClick={close} />
        <div className="modal-header">
          <h3 className="header-text">{props.title}</h3>
          <h5 className="sub-title-text">{props.subTitle} </h5>
        </div>
        <div className="modal-body">
          <Component close={close}>
            {props.component}
          </Component>
        </div>
      </div>
    </div>
  );
}

export default Modal;
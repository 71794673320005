import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../actions/modal';
import cross from '../../../images/static/cross.svg';
import { snakeToTitleCase } from '../../../utils/helper';
import ProfileForm from '../ProfileForm';

function EditProfile(props) {
  const dispatch = useDispatch();

  function close() {
    dispatch(hideModal(null, {
      ...props
    }));
  }

  return (
    <div className="modal-window large">
      <div className="survey-view modal-content">
        <div className="modal-header">
          <img src={cross} alt="" className="modal-close" onClick={close} />
          <p className="title-text">Profile Settings</p>
        </div>
        <ProfileForm data={props.data} action={props.action} />
      </div>
    </div>
  )
}

export default EditProfile;
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import Logo from '../../../images/landing/logo.svg';
import CodeQ from '../../../images/landing/codeq.svg';
import LingoQ from '../../../images/landing/lingoq.png';

function StudentClasses({events=[]}) {
  const user = useSelector(state => state.user);

  return(
    <div className="student-classes" id="student-classes">
      <div className="header-section">
        <div className="logo-wrap">
          <div><img src={Logo} alt=""/></div>
          <div><img src={CodeQ} alt=""/></div>
        </div>
        <div className="key-value-wrapper">
          <div className="key-value">
            <p className="key">Student Name</p>
            <p className="value">{user.first_name} {user.last_name}</p>
          </div>
          {/* <div className="key-value">
            <p className="key">Course</p>
            <p className="value">CodeQ Junior</p>
          </div>
          <div className="key-value">
            <p className="key">Trainer</p>
            <p className="value">Celia Ryan</p>
          </div> */}
          <div className="key-value">
            <p className="key">Total Sessions</p>
            <p className="value">{events.length}</p>
          </div>
        </div>
      </div>
      <div className="table-section">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Trainer</th>
              <th>Course</th>
              <th>Start</th>
              <th>End</th>
              {/* <th>Skills</th> */}
            </tr>
          </thead>
          <tbody>
            {
              events.map((event, index) => {
                return <tr key={index}>
                  <td>{event.id}</td>
                  <td>{event.trainer_name}</td>
                  <td>{event.course}</td>
                  <td>{moment(event.start).format('lll')}</td>
                  <td>{moment(event.end).format('lll')}</td>
                  {/* <td>{event.slills || '-'}</td> */}
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default StudentClasses;
import { DIALCODE } from '../constants/types';

function dialcode(state = null, action = {}) {
  switch (action.type) {
    case DIALCODE:
      return action.dialcode;
    default:
      return state;
  }
}

export { dialcode };
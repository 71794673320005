import { QuestionOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import validator from 'validator';
import { leads } from '../../actions/auth';
import { showModal } from '../../actions/modal';
import { setLocalData, validatePhoneNumber } from '../../utils/helper';
import { COUNTRIES } from '../../commonjs/commonData';

const phoneHelperText = <span>Please add country code <br /> eg: 919013595445 <br /> Number should be mininmum 8 characters and maximum 12 characters</span>;

function ContactUs({ program = '', courses = [], course = null }) {
  const continent = useSelector(state => state.continent),
    dialCode = useSelector(state => state.dialcode),
    [data, setData] = useState({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      message: '',
      course: course ? course.name : '',
      has_tnc_agreed: continent === 'Europe' ? false : true,
      news_letter: continent === 'Europe' ? false : true,
    }),
    [errors, setErrors] = useState({}),
    [loading, setLoading] = useState(false),
    dispatch = useDispatch(),
    country = useSelector(state => state.country);

  function thankyouModal(message, type = undefined, e, messageAlreadyRegister) {
    setLoading(false);
    if (e && e.response && e.response.status === 400) {
      message = messageAlreadyRegister;
      dispatch(showModal('THANK_YOU_MODAL', { message, type }));
    } else {
      dispatch(showModal('THANK_YOU_MODAL', { message, type }));
    }

  }

  useEffect(() => {
    let params = {
      is_online: true
    };
    if (program) {
      params.program = program;
    }
  }, []);

  useEffect(() => {
    if (course && course.name) {
      setData({ ...data, course: course.name });
    }
  }, [course])


  function handleChange(e) {
    let tempData = { ...data };
    tempData[e.target.name] = e.target.value;
    setData(tempData);
  }

  function onCheckboxChange(e) {
    let tempData = { ...data };
    tempData[e.target.name] = e.target.checked;
    setData(tempData);
  }

  function validate(params) {
    let errors = {};
    if (!params.first_name) {
      errors.first_name = true;
    }
    if (!params.phone) {
      errors.phone = true;
    }

    if (params.phone && !validatePhoneNumber(params.phone)) {
      errors.phone = true;
    }
    if (params.email && !validator.isEmail(params.email)) {
      errors.email = true;
    }
    if (!params.has_tnc_agreed) {
      errors.has_tnc_agreed = true;
    }
    if (!params.news_letter) {
      errors.news_letter = true;
    }
    if (!params.course) {
      errors.course = true;
    }
    if (!country || country === '') {
      if (!params.country) {
        errors.country = true;
      }
    }
    return errors;
  }

  function submit() {
    let tempErrors = validate(data);
    setErrors(tempErrors);
    if (Object.keys(tempErrors).length) {
      return;
    }
    let params = {};
    Object.keys(data).forEach(key => {
      if (data[key]) {
        params[key] = data[key];
      }
    });
    if (!params.country && country) {
      params.country = country;
    }
    params.redirection = true;
    window.console.log(params);
    setLocalData('studentDetails', params);
    setLoading(true);
    const message = 'Hi, Thank you for your interest. One of our executives will contact you shortly to book your trial session.';
    const messageAlreadyRegister = 'Hi, Looks like you\'ve already registered with us.We will connect with you shortly for your child\'s Free Demo. Thanks, Team fav';
    dispatch(leads(params, (e) => thankyouModal(message, 'error', e, messageAlreadyRegister)));
  }


  return <div className="form-component">
    <h1 className="title-text">Book a <span>Free Demo Class</span> Today!</h1>
    <p className="sub-text">Our executive will be in touch within the next 24 hours.</p>
    <div className="component">
      <div className="row">
        <div className="col">
          <input type="text" placeholder="First Name" name="first_name" value={data.first_name} className={errors.first_name ? 'error' : ''} onChange={handleChange} />
        </div>
        <div className="col">
          <input type="text" placeholder="Last Name" name="last_name" value={data.last_name} onChange={handleChange} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <input type="text" placeholder="Email" name="email" value={data.email} className={errors.email ? 'error' : ''} onChange={handleChange} />
        </div>
        <div className="col">
          {
            dialCode ? <label className="dial-code">
              {
                dialCode
              }
            </label> : null
          }
          <input type="number" placeholder="Phone No." name="phone" value={data.phone} className={(errors.phone ? 'error' : '') + (dialCode ? ' with-dial-code' : '')} onChange={handleChange} />
          <Tooltip className="ant-tooltip" placement="rightBottom" title={phoneHelperText}>
            <QuestionOutlined />
          </Tooltip>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <textarea name="message" id="message" value={data.message} placeholder="Type your message" onChange={handleChange}></textarea>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className={'select-dropdown ' + (errors.course ? 'error' : '')}>
            <select onChange={handleChange} name="course" className={data.course === '' ? 'light' : ''} value={data.course} >
              <option value="">Select Course</option>
              {
                courses.map(data => {
                  return (
                    <option key={data.id} value={data.name}>{data.name} | Age ({data.min_age} - {data.max_age})</option>
                  )
                })
              }
            </select>
          </div>
        </div>
      </div>
      {!country || country === '' ?
        <div className="row">
          <div className="col">
            <div className={'select-dropdown ' + (errors.country ? 'error' : '')}>
              <select onChange={handleChange} name="country" className={data.country === '' ? 'light' : ''} value={data.course} >
                <option value="">Select Country</option>
                {
                  COUNTRIES.map((country, index) => {
                    return (
                      <option key={index} value={country}>{country}</option>
                    )
                  })
                }
              </select>
            </div>
          </div>
        </div> : <></>}
      <div className="row mb-24 tnc">
        <Checkbox name="has_tnc_agreed" onChange={onCheckboxChange} checked={data.has_tnc_agreed}>By checking this box you confirm that you have read and agree to our  <NavLink exact to="/terms-of-use" target="_blank">
          Terms & Conditions
              </NavLink></Checkbox>
        {
          errors.has_tnc_agreed ? <p className="error-message">Please tick the above field.</p> : null
        }
      </div>
      <div className="row mb-24 tnc">
        <Checkbox onChange={onCheckboxChange} name="news_letter" checked={data.news_letter}>By checking this box you would like to receive more information about our products/services, events, news </Checkbox>
        {
          errors.news_letter ? <p className="error-message">Please tick the above field.</p> : null
        }
      </div>
      <div className="row center">
        <button className={"blue " + (loading ? 'disabled' : '')} onClick={submit}>Book Free Demo</button>
      </div>
      {
        program === 'lingoq' ? <p className="error-text">WE PROVIDE LINGOQ ENGLISH CLASSES ONLY FOR KIDS OF AGE BETWEEN 2 TO 17 YRS OLD.
        </p> : null
      }
    </div>
  </div>
}

export default ContactUs;
import React from 'react';
import { connect } from 'react-redux';
import ModalRoot from './ModalRoot';
import NotificationRoot from './NotificationRoot';
import { getLocalData, isMobileDevice } from '../../utils/helper';
import Logo from '../../images/landing/logo.svg';
import BottomNavigation from '../../components/utils/BottomNavigation';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';

import AuthenticatedHeader from './AuthenticatedHeader';

const AUTHENTICATED_ROUTES = [
  '/form',
  '/demo-view',
  '/calendar-view',
  '/profile',
  '/curriculum',
  '/dashboard',
  '/payments',
  '/schedule',
  '/regular-booking',
  '/classlogs',
  '/video-view',
  '/quiz',
  '/parent',
],
WHIE_BG = [
  '/dashboard',
  '/quiz',
],
NO_PANELS = [
  '/quiz',
  '/video-view',
  '/parent'
],
NO_RIGHT_PANELS = [
  '/dashboard',
  '/quiz',
  '/video-view',
  '/parent',
  '/regular-booking'
],

NO_HEADER_PADDING = ['/video-view'];

class Layout extends React.Component {

  renderNavigationContent = () => {
    if (isMobileDevice() && window.location.pathname !== '/form') {
      return <BottomNavigation />
    }

    return null
  }
  
  render() {
    const props = this.props;
  // console.log("!this.props.user.past_demo_booked < 1",this.props.user.past_demo_booked,this.props.user.past_demo_booked > 0,!NO_PANELS.includes(window.location.pathname),this.props.user.past_regular_events )
    return (
      <>
        <ModalRoot />
        <NotificationRoot />
        {
          AUTHENTICATED_ROUTES.includes(window.location.pathname) ? <div className="authenticated-layout">
            <AuthenticatedHeader />
            <div className="authenticated-wrapper">
              <div className={"student-view " + (WHIE_BG.includes(window.location.pathname) ? 'white' : '') + (NO_HEADER_PADDING.includes(window.location.pathname) ? 'no-padding' : '')}>
                {
                  !NO_PANELS.includes(window.location.pathname) ? <LeftPanel /> : null
                }
                
                <div className="center-content">
                  {props.children}
                </div>
                {
                  // !NO_RIGHT_PANELS.includes(window.location.pathname) || !this.props.user.past_demo_booked > 0  || this.props.user.past_regular_events > 0 || this.props.user.future_regular_events > 0  ? <RightPanel /> : null
                    !NO_RIGHT_PANELS.includes(window.location.pathname) ? <RightPanel /> : null
                }

              </div>
              {
                this.renderNavigationContent()
              }
            </div>
          </div> : props.children
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    program: state.program,
    user: state.user
  }
}

export default connect(mapStateToProps, {})(Layout);

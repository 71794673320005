import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AddClassModal from '../../components/AddClassModal';
import BookDemoModal from '../../components/BookDemoModal';
import CalendarPopup from '../../components/CalendarPopup';
import CommonInfoModal from '../../components/CommonInfoModal';
import CommonInfoLeadModal from '../../components/CommonInfoLeadModal'
import ConfirmationModal from '../../components/ConfirmationModal';
import RescheduleModal from '../../components/RescheduleModal';
import RescheduleModalPlatform from '../../components/platform/RescheduleModal';
import UpdateSendOtpModal from '../../components/platform/UpdateSendOtpModal';
import UpdateNumberModal from '../../components/platform/UpdateNumberModal';
import Signup from '../../components/Signup';
import TNCModal from '../../components/TNCModal';
import TeachingVideo from '../../components/TeachingVideo';
import VideoPlayer from '../../components/VideoPlayer';
import ThankYouModal from '../../components/ThankYouModal';
import DateRangeSelector from '../../components/platform/modals/DateRangeSelector';
import EventConfirmation from '../../components/platform/modals/EventConfirmation';
import SurveyView from '../../components/platform/modals/SurveyView';
import Onboarding from '../../components/onboarding/Onboarding';
import Modal from '../../components/utils/Modal';
import { ADD_CLASS_MODAL, BOOK_DEMO_MODAL, CALENDAR_MODAL, COMMON_INFO_MODAL, CONFIRMATION_MODAL, RESCHEDULE_MODAL, SIGNUP_MODAL, TEACHING_VIDEO, THANK_YOU_MODAL, TNC_MODAL, RESCHEDULE_MODAL_PLATFORM,UPDATE_MODAL_OTP,UPDATE_MODAL_NUMBER, DATE_RANGE_SELECTOR_MODAL, EVENT_CONFIRMATION, VIDEO_PLAYER, SURVEY_LIST_MODAL, EDIT_PROFILE_MODAL, ONBOARDING, MODAL, CONFIRM_MODAL ,MESSAGE_MODAL,COMMON_INFO_LEAD_MODAL, STORY_BOOK_MODAL, THANK_YOU_MODAL_STORY} from '../../constants/types';
import EditProfile from '../../components/platform/modals/EditProfile';
import ConfirmModal from '../../components/regular-view/ConfirmModal';
import MessageModal from '../../components/regular-view/MessageModal';
import StoryBookModal from '../../components/StoryBookModal/StoryBook'
import ThankYouModalStory from '../../components/ThankYouModalStory'

const modalComponents = {
  [TEACHING_VIDEO]: TeachingVideo,
  [VIDEO_PLAYER]: VideoPlayer,
  [THANK_YOU_MODAL]: ThankYouModal,
  [BOOK_DEMO_MODAL]: BookDemoModal,
  [COMMON_INFO_MODAL]: CommonInfoModal,
  [CONFIRMATION_MODAL]: ConfirmationModal,
  [RESCHEDULE_MODAL]: RescheduleModal,
  [TNC_MODAL]: TNCModal,
  [CALENDAR_MODAL]: CalendarPopup,
  [ADD_CLASS_MODAL]: AddClassModal,
  [SIGNUP_MODAL]: Signup,
  [RESCHEDULE_MODAL_PLATFORM]: RescheduleModalPlatform,
  [UPDATE_MODAL_OTP]: UpdateSendOtpModal,
  [UPDATE_MODAL_NUMBER]:UpdateNumberModal,
  [DATE_RANGE_SELECTOR_MODAL]: DateRangeSelector,
  [EVENT_CONFIRMATION]: EventConfirmation,
  [SURVEY_LIST_MODAL]: SurveyView,
  [EDIT_PROFILE_MODAL]: EditProfile,
  [ONBOARDING]: Onboarding,
  [MODAL]: Modal,
  [CONFIRM_MODAL]: ConfirmModal,
  [MESSAGE_MODAL] : MessageModal,
  [COMMON_INFO_LEAD_MODAL] : CommonInfoLeadModal,
  [STORY_BOOK_MODAL]: StoryBookModal,
  [THANK_YOU_MODAL_STORY]: ThankYouModalStory,
};

const ModalRoot = ({ modal }) => {
  return modal.map((data) => {
    if (!data.type) {
      return null;
    }

    data.props.uid = data.uid;

    const ModalComponent = modalComponents[data.type];
    return <ModalComponent key={data.uid} {...data.props} />;
  });
};

ModalRoot.propTypes = {
  modal: PropTypes.any.isRequired
};

const mapState = state => {
  return {
    modal: state.modal
  }
}

export default withRouter(connect(mapState)(ModalRoot));

import { Dropdown, Menu } from 'antd';
import moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal, showModal } from '../actions/modal';
import api from '../commonjs/api';
import cross from '../images/static/cross.svg';
import { errorHandler, getParameterByName, notify } from '../utils/helper';


const token = getParameterByName('t');

const CalendarPopup = (props, ref) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  let { data, action } = props;
  let popupData = data ? { ...data.extendedProps, id: data.id, start: data.start, end: data.end } : { trainer: [] };
  const [showSchedule, setShowSchedule] = useState(moment(data.start).isBefore(moment().clone().add(20, 'minutes')) ? false : true),
    [showCancelClass, setShowCancelClass] = useState(moment(data.start).isBefore(moment()) ? false : true);

  let now = moment();
  let then = moment.utc(props.data.start).local();

  let ms = moment(then, "DD/MM/YYYY HH:mm:ss").diff(moment(now, "DD/MM/YYYY HH:mm:ss"));
  let d = moment.duration(ms);
  let duration = Math.floor(ms / 60000);

  window.console.log('MINS ', duration);

  window.console.log(props.data.start, moment(), duration);

  if (popupData.skill) {
    let skill = props.skills ? props.skills.filter(data => data.id === popupData.skill)[0] : {};
    popupData.skillName = skill.name;
  }

  window.console.log('CALENDAR PROPS', props);

  useEffect(() => {
    if (props.readOnly) {
      setShowSchedule(false);
      setShowCancelClass(false);
    }
  }, [props.readOnly])

  window.console.log(popupData, props);


  window.console.log(duration);

  function close() {
    dispatch(hideModal(null, {
      ...props
    }));
  }

  const handleClick = (e) => {
  };

  const callMaskEvent = (student_id) => {
    api.scheduler.callMaskEvent({ t: token, student_id, event_id: popupData.id })
      .then(response => {
        window.console.log(response.data);
        notify(response.data, 'info', true, 10000);
      })
      .catch(error => {
        console.error(error);
        errorHandler(error.response, true);
      });
  }

  const deleteClassApiPlatform = () => {
    api.platform.cancelClass(user.id, { event_id: popupData.id })
      .then(response => {
        notify('Your class has been cancelled. We hope to see you again.', 'success');
        props.action();
      })
      .catch(error => {
        console.error(error);
        dispatch(showModal('CONFIRMATION_MODAL', {
          message: 'Please click on the below link and fill the form',
          typeData:'LinkType',
          DataError: 'https://share.hsforms.com/'
        }));
        // notify("Please contact our support team to cancel this class.",'error')
      });
  }

  const deleteClassApi = () => {
    if (props.type === 'platform') {
      deleteClassApiPlatform();
      return;
    }
    api.scheduler.cancelClass(popupData.id, { t: token })
      .then(response => {
        notify('Your class has been cancelled. We hope to see you again.', 'success');
        props.action();
      })
      .catch(error => {
        console.error(error);
        // errorHandler(error.response, true);
        dispatch(showModal('CONFIRMATION_MODAL', {
          message: 'Please click on the below link and fill the form',
          typeData:'LinkType',
          DataError: 'https://share.hsforms.com/'
        }));
        // notify("Please contact our support team to cancel this class.",'error')
      });
  }

  const cancelEvent = () => {
    dispatch(showModal('CONFIRMATION_MODAL', {
      message: 'Are you sure you want to cancel your class?',
      action: deleteClassApi
    }));
    close();
  };

  const openReschedulePopup = () => {
    let modal = props.type === 'platform' ? 'RESCHEDULE_MODAL_PLATFORM' : 'RESCHEDULE_MODAL';
    dispatch(showModal(modal, {
      data: popupData,
      action: props.action
    }));
    close();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    return () => {
      window.removeEventListener("mousedown", handleClick, false);
    };
  }, ['ref']);

  if (!data) {
    return <div className="calendar-popup" id="event-details-popup" ref={ref}></div>;
  }

  let menu = null;
  if (props.callMask && popupData.students && popupData.students.length > 1) {
    menu = (
      <Menu>
        {
          popupData.students.map((student, ind) => {
            return <Menu.Item key={ind} onClick={callMaskEvent.bind(null, student.id)}>
              {
                student.first_name
              }
            </Menu.Item>
          })
        }
      </Menu>
    );
  }

  return (
    <div className="modal-window">
      <div className="calendar-popup modal-content">
        <img src={cross} alt="" className="modal-close" onClick={close} />
        <div>
          <div className="content">
            <div className="content-wrap">
              <p className="name">Course: <span>{popupData.course}</span></p>
              {
                popupData.event_type ? <p className="name">Event type: <span>{popupData.event_type}</span></p> : null
              }
              {
                popupData.trainer_name ? <p className="email">Trainer: <span>{popupData.trainer_name}</span></p> : null
              }
              {
                popupData.students && popupData.students.length ?
                  popupData.students.map((student, index) => {
                    return <div key={index}>
                      <p className="email">Student: <span>{student.first_name} {student.absolute_age ? ('(' + student.absolute_age + ')') : ''}</span></p>
                      {
                        student.interests ? <p className="email">Interests: <span>{student.interests}</span></p> : null
                      }
                      {
                        student.coding_experience ? <p className="email">Coding Exp: <span>{student.coding_experience}</span></p> : null
                      }
                      {
                        student.personalisation && popupData.event_type === 'DEMO' ? <p className="email">Personalisation: <span>{student.personalisation}</span></p> : null
                      }
                    </div>
                  }) : null
              }
              <p className="timing">Timing: <span>{moment(data.start).format('hh:mm A')} - {moment(data.end).format('hh:mm A')}</span></p>
            </div>
            <ul className="footer-wrap">
              {
                showCancelClass ? <li onClick={cancelEvent}>
                  <p className="link">Cancel Class</p>
                </li> : null
              }

              {
                // showSchedule ? <li onClick={openReschedulePopup}>
                //   <p className="link"> Reschedule Class</p>
                // </li> : null
                // <li>
                //   <p className="link disabled"> Reschedule Class</p>
                // </li>
              }
              {
                props.callMask && duration >= -30 && duration <= 30 ? <li><div className={popupData.students.length > 1 ? '' : 'link'}>{
                  popupData.students.length > 1 ? <Dropdown.Button overlay={menu}>
                    Call Student
                      </Dropdown.Button> : <span onClick={callMaskEvent.bind(null, popupData.students[0].id)}>Call Student</span>
                }</div></li> : null
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
};

export default forwardRef(CalendarPopup);
import React, { useState, useEffect } from 'react';
import { browserHistory } from 'react-router';
import { useHistory } from "react-router-dom";
import moment from 'moment-timezone';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../actions/modal';
import Cross from '../images/landing/delete-icon.svg';
import Logo from '../images/landing/logo.svg';
import Bg from '../images/landing/signup-banner.png';
import api from '../commonjs/api';
import { CLIENT_ID } from '../constants/config';
import { userLoggedIn } from '../actions/auth';
import { validatePhoneNumber, setUserData, setAxiosHeaders, setAccessToken, errorHandler, notify, setLocalData, getParameterByName, validatePhoneNumberIndia } from '../utils/helper';
import InlineError from './InlineError';
import LeftArrow from '../images/signup/left-arrow.svg';
import IndiaFlag from '../images/signup/india-flag.svg';
import { userData, usersData } from '../actions/auth';
import { leads } from '../actions/auth';


// 'Asia/Calcutta'
const PHONE_TZ = ['Asia/Calcutta'];

function Signup(props) {
  const dispatch = useDispatch(),
    [data, setData] = useState({
      phone: getParameterByName('phone') || '',
      otp: '',
      email: getParameterByName('email') || ''
    }),
    [loading, setLoading] = useState(false),
    [showOtpForm, setShowOtpForm] = useState(false),
    [errors, setErrors] = useState({}),
    [isEmail, setIsEmail] = useState(false),
    [disableBtn, setDisableBtn] = useState(true),
    continent = useSelector(state => state.continent),
    country = useSelector(state => state.country),
    users = useSelector(state => state.users),
    user = useSelector(state => state.user);

  let history = useHistory();

  useEffect(() => {
    window.console.log('Timezone => ', users);
    if (!PHONE_TZ.includes(moment.tz.guess()) || continent === 'Europe') {
      setIsEmail(true);
    }
    let unlisten = history.listen(() => {
      window.console.log('listening.... change');
      if(window.location.pathname !== '/') {
        close();
      }
    });
    if (users.length) {
      history.push('/dashboard');
    }
  }, []);

  function close() {
    history.push('/');
    // dispatch(hideModal(null, {
    //   ...props
    // }));
  }

  function sendOtp() {
    if (!isEmail && !validatePhoneNumberIndia(data.phone)) {
      setErrors({ ...errors, phone: 'Invalid phone number' });
      window.console.log(errors);
      return;
    }
    if (isEmail && !validator.isEmail(data.email)) {
      setErrors({ ...errors, email: 'Invalid email id' });
      window.console.log(errors);
      return;
    }
    let params = {
      username: data.phone,
      un_type: 'phone',
      client_id: CLIENT_ID
    }
    if(isEmail) {
      params.username = data.email;
      params.un_type = 'email';
    }
    setLoading(true);
    api.profile.generateOtp(params)
      .then(response => {
        window.console.log(response);
        setLoading(false);
        setShowOtpForm(true);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        let tempErrors = errorHandler(error.response, true);
      });
  } 
  
  function getStudents () {
    api.profile.studentsProfile()
      .then(response => {
        window.console.log(response);
        dispatch(usersData(response.result));
        if(response.result.length) {
          dispatch(userData(response.result[0]));
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  function createLead() {
    if (getParameterByName('phone')) {
      return;
    }
    let params = { };
    if (getParameterByName('phone')) {
      params.phone = getParameterByName('phone');
    }
    if(data.email) {
      params.email = data.email;
    }
    if (!params.country && country) {
      params.country = country;
    }
    const message = 'Hi, Thank you for your interest. One of our executives will contact you shortly to book your trial session.';
    dispatch(leads(params));
  }

  function verify() {
    if (!data.otp) {
      setErrors({ ...errors, otp: 'Required Field' });
      window.console.log(errors);
      return;
    }
    if (data.otp && data.otp.length !== 6) {
      setErrors({ ...errors, otp: 'Invalid Otp' });
      window.console.log(errors);
      return;
    }
    let params = {
      username: isEmail ? data.email : data.phone,
      otp: data.otp,
      client_id: CLIENT_ID
    }
    setLoading(true);
    
    api.profile.verifyOtp(params)
      .then(async (response) => {
        window.console.log(response);
        // createLead();
        setLoading(false);
        setAccessToken(response.access_token);
        setLocalData('refreshToken', response.refresh_token);
        setAxiosHeaders();
        setUserData({ accessToken: response.access_token });
        dispatch(userLoggedIn(response));
        let message = 'Thanks, Your phone number has been verified.';
        if (isEmail) {
          message = 'Thanks, Your Email has been verified.';
          setLocalData('email', data.email);
        } else {
          setLocalData('phone', data.phone);
        }
        notify(message, 'success');
        let path = `'/form'${isEmail ? '?type=email' : ''}`;
        if (users && users.length > 1) {
          path = '/dashboard'
        }

        if (user && user.demo_booked > 0 && user.past_regular_events === 0 && user.future_regular_events === 0 && user.demo_proposed === 0 && users.length === 1) {
          path = '/dashboard';
        }
        // if (users && users.length > 1) {
        //   path = '/dashboard'
        // } else {
        //   path = '/form';
        // }
        history.push(path);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        let tempErrors = errorHandler(error.response);
        setErrors({ ...errors, otp: 'Code error, re-enter again'});
        setDisableBtn(true);
        window.console.log(tempErrors);
      });
  }

  function handleChange(e) {
    setData({ ...data, [e.target.name]: e.target.value});
    if (e.target.name === 'otp' && e.target.value) {
      setDisableBtn(false);
    }
  } 
  
  function handleFocus(e) {
    setErrors({ ...errors, [e.target.name]: '' });
  }

  function goBack() {
    setShowOtpForm(false);
    setErrors({ ...errors, otp: '' });
  }

  function setEmailLogin() {
    setIsEmail(!isEmail);
  }

  function renderForm(params) {
    if (showOtpForm) {
      return <div className="content-wrapper otp-screen">
        <p className="title-text"><span onClick={goBack}><img src={LeftArrow} alt="" /></span>Verification Code</p>
        <p className="sub-text">Please enter the verification code sent to ({data.phone || data.email}) <span onClick={goBack}>Change</span></p>
        <input autoFocus type="number" placeholder="Enter your OTP" value={data.otp} name="otp" onChange={handleChange} onFocus={handleFocus} className={'otp ' + (errors.otp ? 'error' : '')} />
        {errors.otp && <InlineError text={errors.otp} />}
        <p className="helper-text">I didn’t receive a code <span onClick={sendOtp}>Resend OTP</span></p>
        <button className={"q-button blue " + (loading || disableBtn ? 'disabled' : '')} onClick={verify}>SIGN IN</button>
      </div>
    }

    if(isEmail) {
      return <div className="content-wrapper">
        <p className="title-text">Hello there!</p>
        <p className="sub-text">Sign in to continue with your email</p>
        <label htmlFor="" className="label">Login</label>
        <input autoFocus type="text" placeholder="Enter your Email here" value={data.email} name="email" onChange={handleChange} onFocus={handleFocus} className={errors.email ? 'error' : ''} />
        {errors.email && <InlineError text={errors.email} />}
        {
          continent !== 'Europe' ? <p className="helper-text with-action" >If you want to use Indian Mobile Number <span onClick={setEmailLogin} >Click Here</span></p> : null
        }
        <p className="helper-text">A 6 digit OTP will be sent via Email to verify your <br /> Email Id</p>
        <button className={"q-button blue " + (loading ? 'disabled' : '')} onClick={sendOtp}>SEND OTP</button>
      </div>
    }

    return <div className="content-wrapper">
      <p className="title-text">Hello there!</p>
      <p className="sub-text">Sign in to continue with your mobile <br /> number</p>
      <label htmlFor="" className="label">Login</label>
      <div className="input-with-flag">
        <span><img src={IndiaFlag} alt=""/> +91</span>
        <input autoFocus type="number" placeholder="Enter Your Mobile Number" value={data.phone} name="phone" onChange={handleChange} onFocus={handleFocus} className={errors.phone ? 'error' : ''} />
      </div>
      {errors.phone && <InlineError text={errors.phone} />}
      {
        continent !== 'Europe' ? <p className="helper-text with-action" >If you dont have Indian Mobile Number <span onClick={setEmailLogin} >Click Here</span></p> : null
      }
      <p className="helper-text">A 6 digit OTP will be sent via SMS to verify your <br /> mobile number</p>
      <button className={"q-button blue " + (loading ? 'disabled' : '')} onClick={sendOtp}>SEND OTP</button>
    </div>
  }

  return (
    <div className={"signup " + (window.location.pathname === '/' ? 'fixed' : '')}>
      <img src={Bg} alt="" className="bg"/>
      <div className="header-wrap">
        <div><img src={Logo} alt="" className="logo" /></div>
        <div><img src={Cross} alt="" className="cross" onClick={close}/></div>
      </div>
      {
        renderForm()
      }
    </div>
  )
}

export default Signup;
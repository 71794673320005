import React from 'react';
import { NavLink } from "react-router-dom";
import HomeIcon from '../../images/icons/home-blue.png';
import CurriculumIcon from '../../images/icons/file.png';
import ClasslogIcon from '../../images/icons/calendar.png';
import PaymentsIcon from '../../images/icons/payment-card.png';

function Leftpanel(params) {
  return <div className="left-panel">
    <div>
      <NavLink className="list" exact to="/dashboard"> <img src={HomeIcon} alt=""/> Home</NavLink>
      <NavLink className="list" exact to="/curriculum"> <img src={CurriculumIcon} alt="" /> Curriculum</NavLink>
      <NavLink className="list" exact to="/classlogs"> <img src={ClasslogIcon} alt="" /> Classlogs</NavLink>
      <NavLink className="list" exact to="/payments"> <img src={PaymentsIcon} alt="" /> Payments</NavLink>
      <NavLink className="list" exact to="/schedule"> <img src={ClasslogIcon} alt="" /> Schedule</NavLink>
      <NavLink className="list" exact to="/regular-booking"> <img src={ClasslogIcon} alt="" /> Booking</NavLink>
    </div>
  </div>
}

export default Leftpanel;
import { baseUrl } from '../api-config';

const apiBase = {
    masterData: 'masterdata'
};

const apiEndpoints = {
    leads: baseUrl + 'utils/leads',
    packages: baseUrl + 'utils/packages',
    course: baseUrl + 'masterdata/course',
    courses: baseUrl + 'masterdata/course',
    demoSlotsPerDay: baseUrl + 'scheduler/get-demo-slots-per-day',
    studentEvents: baseUrl + 'scheduler/get-student-calendar',
    cancelClass: baseUrl + 'scheduler/cancel-class',
    rescheduleClass: baseUrl + 'scheduler/reschedule-class',
    studentUpdate: baseUrl + 'scheduler/student-update',
    class: baseUrl + 'scheduler/regular-book',
    startClass: baseUrl + 'scheduler/start-class',
    trainerEvents: baseUrl + 'scheduler/trainer-calendar',
    trainerNextClass: baseUrl + 'scheduler/trainer-next-class',
    callMaskEvent: baseUrl + 'scheduler/call-mask-event',
    generateOtp: baseUrl + 'profile/generate-otp',
    verifyOtp: baseUrl + 'profile/verify-otp',
    studentsProfile: baseUrl + 'profile/students',
    student: baseUrl + 'profile/student-profile',
    bookDemo: baseUrl + 'profile/student-book-demo',
    getDemoSlotsPerDay: baseUrl + 'platform/get-demo-slots-per-day',
    studentCalendar: baseUrl + 'platform/get-student-calendar',
    getNextClass: baseUrl + 'platform/start-class',
    rescheduleClassPlatform: baseUrl + 'platform/reschedule-class',
    cancelClassPlatform: baseUrl + 'platform/cancel-class',
    bookClassPlatform: baseUrl + 'platform/regular-book',
    survey: baseUrl + 'survey/survey',
    surveyAnswer: baseUrl + 'survey/survey-response',
    trainerSurveyLinks: baseUrl + 'survey/trainer',
    refreshToken: baseUrl + 'auth/token',
    logout: baseUrl + 'auth/revoke-token',
    programs: baseUrl + 'masterdata/program',
    proposeDemo: baseUrl + 'profile/student-propose-demo',
    scheduleApproval: baseUrl + 'platform/approve-reschedule',
    scheduleApprovalToken: baseUrl + 'scheduler/approve-reschedule-token',
    content: baseUrl + 'masterdata/content',
    payment: baseUrl + 'platform/payment',
    module: baseUrl + 'masterdata/module',
    getHome: baseUrl + 'platform/get-home',
    curriculum: baseUrl + 'profile/student-curriculum',
    demoCurriculum: baseUrl + 'profile/student-demo-curriculum',
    studentCourseView: baseUrl + 'scheduler/get-student-course-view',
    eventSurvey: baseUrl + 'scheduler/event-survey-links/',
    s3FileUpload: baseUrl + 'masterdata/get-file-url',
    getCountry: baseUrl + 'masterdata/get-country',
    studentCourseView: baseUrl + 'scheduler/get-student-course-view',
    modules: baseUrl + apiBase.masterData + '/module',
    skill: baseUrl + 'profile/skill',
    languages: baseUrl + 'profile/language',
    fetchTrainers: baseUrl + 'platform/parent-find-trainers',
    getDemo: baseUrl + 'platform/get-demo-trainer',
    regularClass: baseUrl + 'platform/book-regular-class',
    getCountryData: baseUrl + 'masterdata/country',
    getDatePerCourse: baseUrl + 'platform/get-future-event-per-course',
    getRescheduleDates: baseUrl + 'platform/get-reschedule-dates',
    slottingData: baseUrl + 'profile/multi-student-schedule',
    profileData: baseUrl + 'profile/demo-show',
    storyLead: baseUrl + 'utils/lead',
}

export default apiEndpoints;

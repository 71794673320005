import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../actions/modal';
import greenTick from '../images/signup/check.png';
import cross from '../images/delete-icon.svg';
import { useHistory } from "react-router-dom";



function renderContent(title, type, message, action, btnText) {
  if(type === 'confirm') {
    return(
      <>
        <p className="text-one">{title}</p>
        <div className="info-bar" dangerouslySetInnerHTML={{ __html: message }}>
        </div>
        <div>
          <button className="button" onClick={action}>{btnText || 'Confirm'}</button>
        </div>
      </>
    )
  }
  if(type === 'info') {
    return <>
      <img src={greenTick} alt="" className="green-tick"/>
      <p className="text-one">{title}</p>
      <div className="info-bar success" dangerouslySetInnerHTML={{ __html: message }}>
      </div>
      {
        btnText ? <div>
          <button className="button" onClick={action}>{btnText || 'Confirm'}</button>
        </div> : null
      }
    </>
  }
  if (type === 'error') {
    return <>
      <p className="text-one" dangerouslySetInnerHTML={{ __html: title }}></p>
    </>
  }
}


function CommonInfoModal(props) {
  const dispatch = useDispatch(),
  history = useHistory();
  const {title, message, type, action, btnText} = props;

  function close() {
    // if(type === 'info') {
    //   props.history.push('/');
    // }
    history.push('/')
    dispatch(hideModal(null, {
      ...props
    }));
  }

  function submit() {
    if(action) {
      action()
    }
    close();
  }

  return (
    <div className="modal-window">
      <div className="info-modal modal-content">
        <img src={cross} alt="" className="modal-close" onClick={close} />
        <div>
          {
            renderContent(title, type, message, submit, btnText)
          }
        </div>
      </div>
    </div>
  )
}

export default withRouter(CommonInfoModal);
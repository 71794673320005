import { SHOW_MODAL, HIDE_MODAL } from '../constants/types';

const initialState = [];

function modal(state = initialState, action = {}) {
  let element = document.getElementsByTagName('html')[0];
  switch (action.type) {
    case SHOW_MODAL:
      element.classList.add('fixed');
      return [
        ...state,
        {
          type: action.payload.type,
          uid: action.uid,
          props: action.payload.props
        }
      ];
    case HIDE_MODAL:
      element.classList.remove('fixed');
      return state.filter(modal => {
        return modal.uid !== action.uid;
      });
    default:
      return state;
  }
}

export default modal;
import React from 'react';
import { Helmet } from "react-helmet";
import HomepageLayout from '../../components/homepage/HomepageLayout';
import { scrollTop } from '../../utils/helper';

function Homepage(props) {
  scrollTop();
  return(
    <div>
      <Helmet>
        <title>Study Abroad - Top UK Universities, Scholarships, Courses & Visa Support.</title>
        <meta name="Description" content="Favuni Global is a leading university representative of the top universities to study in the UK and Europe. Free Assessment, 100% Scholarship, Accommodation, Post Study Work Permit, Resume, SOP Writing." />
        <meta name="keywords" content="Study abroad, Study in uk, Overseas education, study overseas, study in Canada, study in UK, study in australia, study in usa, International Education, MBA, Global Business, Medical Science, International Relation, Scholarship, Post study work Permit, Foreign education, Study abroad near me" />
      </Helmet>
      <HomepageLayout />
    </div>
  )
}

export default Homepage;
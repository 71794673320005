export function reducer(state, action) {
  switch (action.type) {
      case "open":
          return Object.assign(Object.assign({}, state), { isOpen: true, isNotification: false });
      case "close":
          return Object.assign(Object.assign({}, state), { isOpen: false });
      case "delay":
          return Object.assign(Object.assign({}, state), { isDelay: false });
      case "notification":
          return Object.assign(Object.assign({}, state), { isNotification: true });
      default:
          return state;
  }
}


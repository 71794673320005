import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import InlineError from '../InlineError';
import { errorHandler, notify } from '../../utils/helper';
import api from '../../commonjs/api';

function ProfileForm({data={}, action}) {
  const loggedInUser = useSelector(state => state.user),
    [user, setUserData] = useState(data),
    [errors, setErrors] = useState({}),
    [loading, setLoading] = useState(false),
    [updatedUserData, setUpdatedUserData] = useState({});

  function handleChange(e) {
    let name = e.target.name,
      value = e.target.value;

    setUserData({ ...user, [name]: value });
    setUpdatedUserData({ ...updatedUserData, [name]: value });
  }

  function handleUpdate() {
    window.console.log(updatedUserData);
    if(!Object.keys(updatedUserData).length) {
      return;
    }
    setLoading(true);
    api.profile.patchStudent(data.id, updatedUserData)
    .then(resp => {
      action();
      notify('Successfully updated', 'success');
      setLoading(false);
      setUpdatedUserData({});
    })
    .catch(error => {
      setLoading(false);
      errorHandler(error.response, true);
    })
  }

  return (
    <div className="profile-form">
      <div className="form">
        <div className="form-field">
          <label htmlFor="" className="label">First Name</label>
          <input type="text" name="first_name" onChange={handleChange} placeholder="Name" value={user.first_name || ''} />
          {errors.first_name && <InlineError text={errors.first_name} />}
        </div>
        <div className="form-field">
          <label htmlFor="" className="label">Second Name</label>
          <input type="text" name="last_name" onChange={handleChange} placeholder="Name" value={user.last_name || ''} />
          {errors.last_name && <InlineError text={errors.last_name} />}
        </div>
        <div className="form-field">
          <label htmlFor="" className="label">Email</label>
          <input type="text" name="communication_email" onChange={handleChange} placeholder="Enter your Email" value={user.communication_email || ''} />
          {errors.communication_email && <InlineError text={errors.communication_email} />}
        </div>
        <div className="form-field">
          <label htmlFor="" className="label">Phone number</label>
          <input type="text" name="phone" disabled onChange={handleChange} placeholder="Enter your Email" value={user.phone || ''} />
          {errors.phone && <InlineError text={errors.phone} />}
        </div>
        <div className="form-field">
          <button className={loading ? 'disabled' : ''} onClick={handleUpdate} >Save Changes</button>
        </div>
      </div>
    </div>
  )
}

export default ProfileForm;
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import App from './App';
import './index.css';
import rootReducer from './rootReducer';
import * as serviceWorker from './serviceWorker';
import { validHostNames, setAxiosHeaders,validHostNamesSantry } from './utils/helper';

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);



if (validHostNames[window.location.hostname]) {
  window.console.log = function () {
  };
  window.console.error = function () {
  };
  window.console.info = function () {
  };
  window.console.warn = function () {
  };
  window.console.clear = function () {
  };
  window.console.debug = function () {
  };
  function silentErrorHandler() { return true; }
  window.onerror = silentErrorHandler;

}

setAxiosHeaders();



ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Route component={App} />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
serviceWorker.unregister();

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { NavLink } from "react-router-dom";
import { hideModal } from '../actions/modal';
import api from '../commonjs/api';
import Logo from '../images/landing/logo.svg';
import { errorHandler, getParameterByName } from '../utils/helper';


const t = getParameterByName('t');

function TNCModal(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  function close() {
    dispatch(hideModal(null, {
      ...props
    }));
  }

  function updateTNC() {
    setLoading(true);
    api.scheduler.studentUpdate({ t, tnc_agreed: true })
      .then(response => {
        setLoading(false);
        props.action();
        close();
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        errorHandler(error.response);
      });
  }

  return (
    <div className="modal-window">
      <div className="modal-content tnc">
        {/* <img src={cross} alt="" className="modal-close" onClick={close} /> */}
        <div>
          <div className="img-wrap">
            <img src={Logo} alt=""/>
          </div>
          <p className="sub-text">Dear Parent, now you can view your child's class calendar and reschedule or cancel classes automatically from the calendar.</p>
          <p className="sub-text small">By clicking on show class schedule you agree to our  <NavLink exact to="/terms-of-use" target="_blank">
            T&C
          </NavLink> . </p>
          <button className={"button blue " + (loading ? 'disabled' : '')} onClick={updateTNC}>Show class schedule</button>
        </div>
      </div>
    </div>
  )


}

export default withRouter(TNCModal);
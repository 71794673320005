import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../actions/modal';
import cross from '../../../images/static/cross.svg';
import { snakeToTitleCase } from '../../../utils/helper';

const SURVEY_TYPES = ['regular_feedback', 'demo_feedback'];

function SurveyView(props) {
  const dispatch = useDispatch(),
    [selected, setSelected] = useState(null),
    [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.data && props.data.length) {
      toggleSelected(props.data[0].survey);
    }
  }, []);

  function toggleSelected(survey) {
    if (selected && selected.id === survey.id) {
      setSelected(null);
      return;
    }
    setSelected(survey);
    window.console.log(selected);
  }

  function close() {
    dispatch(hideModal(null, {
      ...props
    }));
  }

  return (
    <div className="modal-window large">
      <div className="survey-view modal-content">
        <div className="modal-header">
          <img src={cross} alt="" className="modal-close" onClick={close} />
          <p className="title-text">Feedbacks</p>
        </div>
        {
          props.data && props.data.length && props.data.filter(s => SURVEY_TYPES.includes(s.survey.survey_type)).map((s, index) => {
            window.console.log('----------------', s);
            let survey = s.survey;
            survey.questions = s.response;
            return <div className={"list-wrap " + (selected && selected.id === survey.id ? 'selected' : '')} key={index} onClick={toggleSelected.bind(null, survey)} >
              <div>
                <p className="text-one">{survey.welcome_message}</p>
                <p className="text-two">{survey.name} </p>
              </div>
              <div>
                <p className="text-one">Type</p>
                <p className="text-two">{snakeToTitleCase(survey.survey_type)} </p>
              </div>
              <div className="view">
                <span>{selected && selected.id === survey.id ? 'Close' : 'View'}</span>
              </div>
            </div>
          })
        }
        {
          selected && selected.questions ? <div className="questions">
            {
              selected.questions.map((question, index) => {
                window.console.log(question);
                return <div className="question-wrapper" key={index}>
                  <div className="q-wrap" >
                    <div className="question">
                      <span className="label">{index + 1}</span>
                      <p className="text">{question.question.name !== question.question.text ? question.question.text : (question.question.name || '')}</p>
                    </div>
                    <div className="answer-list">
                      {
                        (question.question.response_options && question.question.response_options.length) ? question.question.response_options.map((resp, ind) => {
                          if (question.response_option_ids && question.response_option_ids.map((resp, index) => resp.id).includes(resp.id)) {
                            return <p key={index} >{resp.text}</p>
                          }
                        }) : <p className="answer" dangerouslySetInnerHTML={{ __html: question.answer || '-' }}></p>
                      }
                    </div> 
                  </div>
                </div>
              })
            }
          </div> : null
        }
      </div>
    </div>
  )
}

export default SurveyView;
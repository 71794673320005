import { COUNTRY } from '../constants/types';

function country(state = null, action = {}) {
  switch (action.type) {
    case COUNTRY:
      return action.country;
    default:
      return state;
  }
}

export { country };
import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Menu, Dropdown,  Select } from 'antd';
import { userData } from '../../actions/auth';
import Logo from '../../images/landing/logo.svg';
// import MenuLogo from '../../images/platform/menu.svg';
import { isMobileDevice, setLocalData, getLocalData } from '../../utils/helper';
import { logout, clearStore } from '../../actions/auth';
import api from '../../commonjs/api';
import { setCountry } from '../../actions/country';
import { setContinent } from '../../actions/continent';
import { setDialCode } from '../../actions/dialcode';
import PhoneIcon from '../../images/icons/call.png';
import HamMenu from '../../images/icons/menu.png';

const { Option } = Select;

function AuthenticatedHeader(params) {
  const users = useSelector(state => state.users),
    [user, setUser] = useState(getLocalData('currentUser')),
    [showMenu, setShowMenu] = useState(false),
    [usersList, setUsersList] = useState(null),
    dispatch = useDispatch(),
    menu = (
      <Menu>
        {
          user ? <Menu.Item key="0">
            <NavLink exact to="/profile">
              Profile Settings
          </NavLink>
          </Menu.Item> : null
        }
        <Menu.Item key="1" onClick={logoutFn}>
            <a>Logout</a>
        </Menu.Item>
      </Menu>
    ),
    history = useHistory(),
    dialCode = useSelector(state => state.dialcode),
    country = useSelector(state => state.country),
    continent = useSelector(state => state.continent),
    [states, setStaes] = useState({});

  useEffect(() => {
    if(users.length > 1) {
      let list  = (
        <Menu>
          {
            users.map((user, ind) => {
              return <Menu.Item title={user.first_name} key={ind} onClick={handleUserChange.bind(null, user.id)}><a>{user.first_name} {user.last_name}</a></Menu.Item>
            })
          }
          <Menu.Item key="1" onClick={handleUserChange.bind(null, null)}>
            <a>+ Book Free Demo</a>
          </Menu.Item>
        </Menu>
      )
      setUsersList(list);
    }
  }, [users])

  function handleUserChange(value) {
    window.console.log(value, users);
    if (value === null) {
      history.push('/form?type=add-new-user');
      return;
    }
    let usr = users.filter(user => value === user.id)[0];
    window.console.log(value);
    setUser(usr);
    setLocalData('currentUser', usr);
    dispatch(userData(usr));
    if (usr && usr.demo_booked >= 1 && usr.past_regular_events === 0 && usr.future_regular_events === 0) {
      history.push('/dashboard');
    } else if (usr && usr.demo_booked === 0 && usr.past_regular_events === 0 && usr.future_regular_events === 0) {
      history.push('/form');
    }
  }

  function getCurriculum(id = user.id) {
    api.platform.curriculum(id)
      .then(response => {
        window.console.log(response);
        if (!response.result.length) {
          setStaes({ ...states, curriculum: true });
        } else {
          setStaes({ ...states, curriculum: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }  

  function logoutFn() {
    window.localStorage.clear();
    dispatch(logout());
    dispatch(clearStore());
    dispatch(setContinent(continent));
    dispatch(setCountry(country));
    dispatch(setDialCode(dialCode));
    history.push('/');
  }

  useEffect(() => {
    if (!user && users.length) {
      setUser(users[0]);
    }
    
  }, [users]);

  function toggleUserPanel() {
    setShowMenu(!showMenu);
  }

  function handleChange(value) {
    window.console.log(value, users);
    if(value === null) {
      history.push('/form?type=add-new-user');
      return;
    }
    let usr = users.filter(user => value === user.id)[0];
    window.console.log(value);
    setUser(usr);
    setLocalData('currentUser', usr);
    dispatch(userData(usr));
    if (usr && usr.demo_booked >= 1 && usr.past_regular_events === 0 && usr.future_regular_events === 0) {
      history.push('/dashboard');
    } else if (usr && usr.demo_booked === 0 && usr.past_regular_events === 0 && usr.future_regular_events === 0) {
      history.push('/form');
    }
  }
  return <div className="authenticated-header">
    <div className="container">
      <div className="logo-wrap">
        {
          isMobileDevice() ? <img src={HamMenu} alt="" onClick={toggleUserPanel} /> : null
        }
        {
          showMenu ? <div className="menu-left-panel">
            <div className="dropdown" id="user">
              <Select onChange={handleChange} value={user ? user.id : undefined} getPopupContainer={() => document.getElementById('user')}>
                {
                  users.map((user, ind) => {
                    return <Option title={user.first_name} key={ind} value={user.id}>{user.first_name} {user.last_name}</Option>
                  })
                }
                <Option value={null} className="add-kid" key="kid">+ Book Free Demo</Option>
              </Select>
            </div>
          </div> : null
        }
        {
          users && users.length ? <NavLink exact to="/dashboard">
            <img src={Logo} alt="" className="logo" />
          </NavLink> : <NavLink exact to="/">
          <img src={Logo} alt="" className="logo" />
        </NavLink>
        }
      </div>
      <div className="nav-wrap">
      </div>
      {
        users && users.length && usersList && !isMobileDevice() ? <div className="manage-profile" id="manageprofile">
          {/* <Select onChange={handleChange} value={user ? user.id : undefined} getPopupContainer={() => document.getElementById('user')}>
            {
              users.map((user, ind) => {
                return <Option title={user.first_name}key={ind} value={user.id}>{user.first_name} {user.last_name}</Option>
              })
            }
            <Option value={null} className="add-kid" key="kid">+ Book Free Demo</Option>
          </Select> */}

          <Dropdown overlay={usersList} trigger={['click']}>
            <a className="ant-dropdown-link">
              Manage Profiles
              <DownOutlined />
            </a>
          </Dropdown>
        </div> : null
      }
      {/* <div className="nav-wrap">
        <NavLink exact to="/dashboard">Home</NavLink>
        {
          !states.curriculum ? <NavLink exact to="/curriculum">Curriculum</NavLink> : null
        }
        
        <NavLink exact to="/classlogs">Classlogs</NavLink>
        <NavLink exact to="/payments">Payments</NavLink>
      </div> */}
      {
        isMobileDevice() && !showMenu ? <div className="contact-us-card">
          <div>
            <p className="text-two">Contact</p>
          </div>
          <div>
            <div className="circle">
              <a href="tel:+918792740458"> <img src={PhoneIcon} alt="" /> </a>
            </div>
          </div>
        </div> : null
      }
      <div className="user-wrap">
        {
          user && !isMobileDevice() ? <>
            <p className="name">{user.first_name} {user.last_name}</p>
            <p className="dp">{user.first_name && user.first_name[0]} {user.last_name && user.last_name[0]}</p>
          </> : null
        }
        {
          !isMobileDevice() ? <Dropdown overlay={menu} trigger={['click']}>
            <a className="ant-dropdown-link">
              <DownOutlined />
            </a>
          </Dropdown> : null
        }
      </div> 
    </div>
  </div>
}

export default AuthenticatedHeader;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../../actions/modal';
import api from '../../commonjs/api';
import { showModal } from '../../actions/modal';
import { CLIENT_ID } from '../../constants/config';
import cross from '../../images/static/cross.svg';
import IndiaFlag from '../../images/signup/india-flag.svg';
import { errorHandler, getParameterByName, notify, toUtcAmPm, validatePhoneNumberIndia } from '../../utils/helper';
import InlineError from '../InlineError';



function UpdateSendOtpModal(props) {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        phone: getParameterByName('phone') || '',
        otp: '',
    })
    function close() {
        dispatch(hideModal(null, {
            ...props
        }));
    }
    console.log("new props", props)
    function handleChange(e) {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    function handleFocus(e) {
        setErrors({ ...errors, [e.target.name]: '' });
    }

    function sendOtp() {
        if (!validatePhoneNumberIndia(data.phone)) {
            setErrors({ ...errors, phone: 'Invalid phone number' });
            window.console.log(errors);
            return;
        }
        let params = {
            username: props.data.phone,
            un_type: 'phone',
            new_phone: data.phone,
            client_id: CLIENT_ID
        }
        console.log("main config data", params)
        setLoading(true);
        api.profile.generateOtp(params)
            .then(response => {
                window.console.log(response);
                setLoading(false);
                let modal = 'UPDATE_MODAL_NUMBER';
                dispatch(showModal(modal, {
                    dataNumber: data,
                    oldData: props.data
                }))
                dispatch(hideModal(null, {
                    ...props
                }));
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                let tempErrors = errorHandler(error.response, true);
            });


    }

    return (
        <div className="modal-window">
            <div className="reschedule-modal update-number-otp modal-content">
                <div className="modal-header-update">
                    <img src={cross} alt="" className="modal-close" onClick={close} />
                    <p className="phone-title-text">Update phone number</p>
                </div>
                <div>
                    <div className="input-container">
                        <label htmlFor="" className="label">Enter new phone numb</label>
                        <div className="input-with-flag">
                            <span><img src={IndiaFlag} alt="" /> +91</span>
                            <input autoFocus type="number" placeholder="" value={data.phone} name="phone" onChange={handleChange} onFocus={handleFocus} className={errors.phone ? 'error' : ''} />
                        </div>
                        {errors.phone && <InlineError text={errors.phone} />}
                        <button className={(loading ? 'disabled' : '')} onClick={sendOtp}>Send Code</button>
                        <p className="helper-text">A varifiction code will be sent to the new number  for verification</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(UpdateSendOtpModal);
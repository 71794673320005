import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import './App.css';
import { setLanguage, setLanguageMap } from './actions/language';
import { languageMap } from './language-map/mapping';
import Router from './router/Router';
import Layout from './views/layout/Layout';
import ReactWhatsappButton from "react-whatsapp-button";
import Button from "./components/button";
// import Apple from "./components/Apple";


const App = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLanguage('english'));
    dispatch(setLanguageMap(languageMap['english']));
  }, []);

  return (
    <Layout {...props} >
      <div className="App">
      <ReactWhatsappButton
        countryCode="91"
        phoneNumber="9289633147"
        animated
      />
    </div>
    {/* <Button /> */}
    {/* <Whatsapp /> */}
    {/* <Apple /> */}
      <Router />
    </Layout>
  );
} 

export default withRouter(connect(null, {})(App));

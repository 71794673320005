/* global mobileClient */
import { Button, Dropdown, Menu, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import "./homePage.css";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Slider from "react-slick";
import Sticky from "react-sticky-el";
import validator from "validator";
import { leads } from "../../actions/auth";
import { showModal } from "../../actions/modal";
import api from "../../commonjs/api";
import {
  BOOK_DEMO_MODAL,
  ONBOARDING,
  SIGNUP_MODAL,
  THANK_YOU_MODAL,
  VIDEO_PLAYER,
} from "../../constants/types";
import Whatsapp from "../../components/whatsapp"
import { FloatingWhatsApp } from "../../components/FloatingWhatsApp";
import WhatsAppLogo from "../../images/whatsapp.png"
import BritishCouncil from "../../images/homepage/british-council.png";
import DdlArrowDark from "../../images/homepage/ddl-arrow-dark.svg";
import DdlArrow from "../../images/homepage/ddl-arrow.svg";
import DownArrow from "../../images/homepage/down-arrow.svg";
import FlipKart from "../../images/homepage/flipkart.png";
import Google from "../../images/homepage/google.png";
import IconFive from "../../images/homepage/icons/icon-five.webp";
import IconFour from "../../images/homepage/icons/icon-four.webp";
import IconOne from "../../images/homepage/icons/icon-one.webp";
import IconSix from "../../images/homepage/icons/icon-six.webp";
import IconThree from "../../images/homepage/icons/icon-three.webp";
import IconTwo from "../../images/homepage/icons/icon-two.webp";
import Congratulations from "../../images/homepage/congrate.webp";
import Abertay from "../../images/Uni-logo/abertay.webp";
import BathSpa from "../../images/Uni-logo/bathspa.webp";
import Coventry from "../../images/Uni-logo/Coventry.webp";
import Sunderland from "../../images/Uni-logo/sunderland.webp";
import Loughborough from "../../images/Uni-logo/loughborough.webp";
import Roehampton from "../../images/Uni-logo/University_of_Roehampton.webp";
import UCA from "../../images/Uni-logo/uca.webp";
import BPP from "../../images/Uni-logo/Bpp.webp";
import IBSHungry from "../../images/Uni-logo/ibshungry.webp";
import Oxford from "../../images/Uni-logo/oxford.webp";
import Bristol from "../../images/Uni-logo/UWE_Bristol.webp";
import Kaplan from "../../images/Uni-logo/kaplan.webp";
import Demontford from "../../images/Uni-logo/demontford.webp";
import Wrexham from "../../images/Uni-logo/wrexham.webp";
import Keele from "../../images/Uni-logo/keele.webp";
import Solent from "../../images/Uni-logo/solent.webp";

import Logo from "../../images/homepage/logo.png";
import OrangePlay from "../../images/homepage/play-shadow.webp";
import Play from "../../images/homepage/play.svg";
import Cross from "../../images/landing/cross.svg";
import MenuImg from "../../images/landing/menu-black.svg";
import {
  doScrolling,
  getParameterByName,
  isMobileDevice,
  pixelEvent,
  setLocalData,
  validatePhoneNumber,
  taboolaPixel,
} from "../../utils/helper";
import Footer from "../../views/layout/Footer";
import CourseCard from "../programs/CourseCard";
import CompanyDetails from "../utils/CompanyDetails";
import Nelson from "../../images/homepage/nelsonmandela.png";
import UnitedK from "../../images/countries/uk.webp";
import Canada from "../../images/countries/canada.webp";
import USA from "../../images/countries/usa.webp";
import Australia from "../../images/countries/aus.webp";
import Europe from "../../images/countries/europe.webp";
import Ireland from "../../images/countries/ireland.webp";
import NewZealand from "../../images/countries/newzealand.webp";
import Russia from "../../images/countries/russia.webp";
import Courses from "../../images/homepage/banner.png";
import ComputerScience from "../../images/courses/computerscience.webp";
import MBA from "../../images/courses/business.webp";
import ActurialScience from "../../images/courses/actuary-science.webp";
import LLB from "../../images/courses/law.webp";
import DataAnalytics from "../../images/courses/dataanalysis.webp";
import Research from "../../images/courses/researchs.webp";
import Prinka from "../../images/static/Prinka.jpg";
import ContactUs from "../utils/ContactUs";
import { GiAustralia } from "react-icons/gi";
import { Collapse } from "antd";

const { Panel } = Collapse;
const text1 = `
  A higher education consultant, assist students in preparing in
                selecting university program selection as per your profile
                assessment university/ college financial, scholarships if
                eligible. Apart from also helping interview questions and
                answers.
`;
const text2 = `
We are a trusted provide a range of free no cost service. Your
                Profile Assessment, University Recommendation, SOP/LOR/Resume
                and Application Assistance are completely free.
`;
const text3 = `
 We are an organization for students helping concrete to skilled
                level. We have built our industry experience and expertise
                through 16 years of education consulting, working with over
                7000+ student applications and developing a wide range of
                professional affiliations and network.We are on time available
                to you through our transparent communication.
`;
const text4 = `
  No one can guarantee ethically offer an admissions but we can
                guarantee to helping process methods. We can maximise your
                admission chances by applying to multi universities as per your
                profile assessment.
`;
const text5 = `
   Each and every university has its own set of standard
                eligibility criteria and requirements, English language and
                admission requirements relevant experience. We will assist you
                in selecting university program selection as per your profile
                assessment.
`;
const text6 = `
Each and every university has its own set of standard
                eligibility criteria and requirements, English language and
                admission requirements relevant experience. We will assist you
                in selecting university program selection as per your profile
                assessment.
`;
const text7 = `
    We are ready to help at any stage to planning your study abroad
                journey We have helped students all across virtually But still,
                we would love to meet you! Our office address is Noida Logix
                City Centre,
`;
const text8 = `
 The best way to reach us is through the given address on our
                website. You can also reach out to us on our contact number +91
                988-660-2456. We are available from (Mon-Sat:10AM - 9PM,
                Sun:10AM - 7PM). You may even write to us at
                contact@favuniglobal.com.
`;

const { TabPane } = Tabs,
  phoneHelperText = (
    <span>
      Please add country code <br /> eg: 918813595775 <br /> Number should be
      mininmum 8 characters and maximum 12 characters
    </span>
  ),
  COURSES_FILTERS = ["LingoQ Senior"];

function CarouselOne({ data, type }) {
  let settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      draggable: false,
    },
    classMap = {
      1: "one",
      2: "two",
      3: "three",
      4: "four",
      5: "five",
      6: "six",
    },
    randonNumber = 0;

  if (isMobileDevice()) {
    settings.slidesToShow = 1.05;
    settings.arrows = false;
    settings.draggable = true;
  }

  return (
    <Slider {...settings} className="carousel-wrapper cards-wrapper">
      {data.map((d, ind) => {
        return <CourseCard data={d} key={ind} index={ind} type={type} />;
      })}
    </Slider>
  );
}

function CarouselTwo(projects = []) {
  const dispatch = useDispatch();

  function playVideo(videoId) {
    dispatch(
      showModal(VIDEO_PLAYER, {
        youtubeId: videoId,
      })
    );
  }

  let settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4.9,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    draggable: false,
  };

  if (isMobileDevice()) {
    settings.slidesToShow = 1.1;
    settings.draggable = true;
  }

  return (
    <Slider {...settings} className="carousel-wrapper cards-wrapper">
      {projects.map((project, index) => {
        let videoId = project.video_url.split("/").pop(),
          imgSrc = `https://img.youtube.com/vi/${videoId}/0.jpg`;

        return (
          <div className="proj-card" key={index}>
            <div className="img-wrap" onClick={playVideo.bind(null, videoId)}>
              <img src={project.image_url || imgSrc} alt="" />
            </div>
            <div className="text-content">
              <p className="text-one">{project.person_name || "-"}</p>
              <p className="text-two">{project.age} years</p>
              <p className="text-two">{project.course_name}</p>
            </div>
          </div>
        );
      })}
    </Slider>
  );
}

function CarouselThree({ data = [] }) {
  const dispatch = useDispatch();

  function playVideo(videoId) {
    dispatch(
      showModal(VIDEO_PLAYER, {
        youtubeId: videoId,
      })
    );
  }

  let settings = {
      dots: false,
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 3000,
      draggable: false,
    },
    classMap = {
      1: "one",
      2: "two",
      3: "three",
      4: "four",
      5: "five",
      6: "six",
    },
    randonNumber = 0;

  if (isMobileDevice()) {
    settings.slidesToShow = 1.1;
    settings.arrows = false;
    settings.draggable = true;
  }

  return (
    <Slider {...settings} className="carousel-wrapper cards-wrapper">
      {data
        .filter((p, i) => i < 4)
        .map((project, index) => {
          let videoId = project.video_url.split("/").pop(),
            imgSrc = `https://img.youtube.com/vi/${videoId}/0.jpg`;

          return (
            <div key={index} className="project-card">
              <div className="img-wrap" onClick={playVideo.bind(null, videoId)}>
                <img src={project.image_url || imgSrc} alt="" />
                <img src={Play} className="play" alt="" />
              </div>
              <div className="content-wrap">
                <p>
                  {project.title ||
                    `${project.course_name} - ${project.content_category}`}
                </p>
              </div>
            </div>
          );
        })}
    </Slider>
  );
}

function CarouselFour({ data = [] }) {
  let settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    draggable: false,
  };

  if (isMobileDevice()) {
    settings.slidesToShow = 1;
    settings.draggable = true;
    settings.arrows = false;
  }

  return (
    <Slider {...settings} className="carousel-wrapper cards-wrapper">
      {data.map((feedback, index) => {
        return (
          <div className="testimonial-card" key={index}>
            <div className="user-wrap">
              <div className="dp">
                <img src={feedback.image_url} alt="" />
              </div>
              <div className="content-wrap">
                <p className="name">{feedback.person_name}</p>
              </div>
            </div>
            <div className="text-wrap">
              <p className="text">{feedback.description} </p>
            </div>
          </div>
        );
      })}
    </Slider>
  );
}

function CustomTab({ parentFeedbacks, trainerFeedbacks }) {
  const [tab, setTab] = useState("parents");

  function handleTabChange(value) {
    setTab(value);
  }

  return (
    <div className="custom-tab">
      <div className="tabs">
        <div className={"tab-background " + tab}></div>
        <div
          className={"tab " + (tab === "parents" ? "active" : "")}
          onClick={() => handleTabChange("parents")}
        >
          Parents
        </div>
        <div
          className={"tab " + (tab === "trainers" ? "active" : "")}
          onClick={() => handleTabChange("trainers")}
        >
          Trainers
        </div>
      </div>
      <div className="tab-content">
        {tab === "parents" ? (
          <div className="testimonial-wrapper">
            <CarouselFour data={parentFeedbacks} />
          </div>
        ) : null}
        {tab === "trainers" ? (
          <div className="testimonial-wrapper">
            <CarouselFour data={trainerFeedbacks} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

function HomepageLayout(props) {
  const continent = useSelector((state) => state.continent),
    country = useSelector((state) => state.country),
    [data, setData] = useState({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      message: "",
      course: "",
      has_tnc_agreed: continent === "Europe" ? false : true,
    }),
    [errors, setErrors] = useState({}),
    [courses, setCourses] = useState([]),
    [programs, setPrograms] = useState([]),
    [loading, setLoading] = useState(false),
    [getApiLoading, setGetApiLoading] = useState(false),
    dispatch = useDispatch(),
    [coursesMap, setCoursesMap] = useState({}),
    [trainerFeedbacks, setTrainerFeedbacks] = useState([]),
    [parentFeedbacks, setParentFeedbacks] = useState([]),
    [projects, setProjects] = useState([]),
    location = useLocation(),
    history = useHistory(),
    [showMenu, setShowMenu] = useState(false);

  let youtubeVideoId = "";

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    getCourses();
    getPrograms();
    getContent({ content_type: "feedback" });
    getContent({ content_type: "project" });
    pixelEvent("ViewContent");
    // taboolaPixel('page_view');
    openOnboarding();
  }, []);

  const openOnboarding = () => {
    if (isMobileDevice()) {
      try {
        if (!mobileClient) {
          throw "NOT MOBILE DEVICE";
        } else {
          // dispatch(showModal(ONBOARDING, {
          //   action: openSignupModal
          // }));
          openSignupModal();
        }
      } catch (e) {}
    }
  };
  const onChange = (key) => {
    console.log(key);
  };

  useEffect(() => {
    if (getParameterByName("type") === "platform-signup") {
      openSignupModal();
    }
  }, []);

  function scrollTo(id) {
    let elem = document.getElementById(id);
    doScrolling(elem, 1000, 80);
  }

  function getContent(params = {}) {
    api.masterData
      .content(params)
      .then((response) => {
        switch (params.content_type) {
          case "feedback":
            setTrainerFeedbacks(
              response.filter((f) => f.person_type === "Trainer")
            );
            setParentFeedbacks(
              response.filter((f) => f.person_type === "Parent")
            );
            break;
          case "project":
            setProjects(response);
            break;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getCourses() {
    api.masterData
      .courses({ is_online: true })
      .then((response) => {
        response = response.filter(
          (data) =>
            data.description !== "" && !COURSES_FILTERS.includes(data.name)
        );
        setCourses(response);
        let coursesMap = {};
        response.forEach((course) => {
          if (!coursesMap[course.program]) {
            coursesMap[course.program] = [];
          }
          // coursesMap[course.program] = course;
          coursesMap[course.program].push(course);
        });
        setCoursesMap(coursesMap);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getPrograms() {
    setGetApiLoading(true);
    api.masterData
      .programs({ is_online: true })
      .then((response) => {
        setPrograms(response);
        setGetApiLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setGetApiLoading(false);
      });
  }

  function thankyouModal(message, type = undefined) {
    setLoading(false);
    dispatch(showModal(THANK_YOU_MODAL, { message, type }));
  }

  const openSignupModal = () => {};

  function bookDemoModal(program = null) {
    history.push("/book-demo" + location.search);
    return;
    dispatch(
      showModal(BOOK_DEMO_MODAL, {
        program,
      })
    );
  }

  function playVideo(videoId) {
    dispatch(
      showModal(VIDEO_PLAYER, {
        youtubeId: videoId,
      })
    );
  }

  function handleChange(e) {
    let tempData = { ...data };
    tempData[e.target.name] = e.target.value;
    setData(tempData);
  }

  function onTncChange(e) {
    let tempData = { ...data };
    tempData.has_tnc_agreed = e.target.checked;
    setData(tempData);
  }

  function validate(params) {
    let errors = {};
    if (!params.first_name) {
      errors.first_name = true;
    }
    if (!params.phone) {
      errors.phone = true;
    }
    // if (!params.course) {
    //   errors.course = true;
    // }

    if (params.phone && !validatePhoneNumber(params.phone)) {
      errors.phone = true;
    }
    if (params.email && !validator.isEmail(params.email)) {
      errors.email = true;
    }
    if (!params.has_tnc_agreed) {
      errors.has_tnc_agreed = true;
    }
    return errors;
  }

  function goToPage(path) {
    history.push(path);
  }

  function submit() {
    let tempErrors = validate(data);
    setErrors(tempErrors);
    if (Object.keys(tempErrors).length) {
      return;
    }
    let params = {};
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        params[key] = data[key];
      }
    });
    if (!params.country && country) {
      params.country = country;
    }
    setLocalData("studentDetails", params);
    setLoading(true);
    const message =
      "Hi, Thank you for your interest. One of our executives will contact you shortly to book your trial session.";
    dispatch(leads(params, () => thankyouModal(message, "error")));
  }

  function scrollToExplore() {
    var elmnt = document.getElementById("explore");
    elmnt.scrollIntoView({ behavior: "smooth" });
  }

  const menu = (
    <Menu className="nav-dropdown">
      <Menu.Item>
        <NavLink
          exact
          to={{ pathname: "/study-in-uk", search: location.search }}
          activeClassName="active"
        >
          Study in UK
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink
          exact
          to={{ pathname: "/study-in-ireland", search: location.search }}
          activeClassName="active"
        >
          Study in Ireland
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink
          exact
          to={{ pathname: "/study-in-usa", search: location.search }}
          activeClassName="active"
        >
          Study in USA
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink
          exact
          to={{ pathname: "/study-in-canada", search: location.search }}
          activeClassName="active"
        >
          Study in Canada
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink
          exact
          to={{ pathname: "/study-in-europe", search: location.search }}
          activeClassName="active"
        >
          Study in Europe
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink
          exact
          to={{ pathname: "/study-in-australia", search: location.search }}
          activeClassName="active"
        >
          Study in Australia
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item>
        <NavLink exact to={{ pathname: '/lingoq-study', search: props.location.search }} activeClassName="active">Lingo Q Study</NavLink>
      </Menu.Item> */}
    </Menu>
  );

  function Header() {
    return (
      <Sticky topOffset={10} className="sticky-element">
        <div className="header-wrapper home light">
          <img className="menu" src={MenuImg} alt="" onClick={toggleMenu} />
          <div className="logo-wrap">
            <img src={Logo} alt="" />
          </div>
          <div className="nav-wrap">
            <NavLink
              exact
              to={{
                pathname: "/",
                search: props.location.search,
              }}
              activeClassName="active"
            >
              Home
            </NavLink>
            <Dropdown overlay={menu}>
              <a className="ant-dropdown-link">
                <span>
                  Study Destination{" "}
                  <img src={DdlArrow} alt="" className="light" />
                  <img src={DdlArrowDark} className="dark" alt="" />
                </span>
              </a>
            </Dropdown>
            <NavLink
              exact
              className="with-divider"
              to={{ pathname: "/test-prep", search: location.search }}
              activeClassName="active"
            >
              Test-Prep
            </NavLink>

            <NavLink
              exact
              to={{ pathname: "/about-us", search: props.location.search }}
              activeClassName="active"
            >
              About Us
            </NavLink>
            <NavLink
              exact
              to={{ pathname: "/contact-us", search: props.location.search }}
              activeClassName="active"
            >
              Contact Us
            </NavLink>
            <NavLink
              exact
              to={{ pathname: "/join-us", search: location.search }}
              activeClassName="active"
            >
              Join Us
            </NavLink>
            {/* <button className="bordered" onClick={goToPage.bind(null, '/teach-with-us')}>Teach with us</button> */}
            {/* <button
              className="orange"
              onClick={openSignupModal.bind(null, null)}
            >
              Login
            </button> */}
            <button
              className="white-bordered"
              onClick={bookDemoModal.bind(null, null)}
            >
              Book Appointment
            </button>
          </div>
          <div className={"mobile-nav " + (showMenu ? "show" : "")}>
            <div className="close" onClick={toggleMenu}>
              <img src={Cross} alt="" />
            </div>
            <div className="img-wrap">
              <NavLink exact to="/">
                <img src={Logo} alt="" />
              </NavLink>
            </div>

            <NavLink
              exact
              to={{ pathname: "/study-in-uk", search: location.search }}
              activeClassName="active"
            >
              Study in UK
            </NavLink>
            <NavLink
              exact
              to={{ pathname: "/study-in-ireland", search: location.search }}
              activeClassName="active"
            >
              Study in Ireland
            </NavLink>
            <NavLink
              exact
              to={{ pathname: "/study-in-usa", search: location.search }}
              activeClassName="active"
            >
              Study in USA
            </NavLink>
            <NavLink
              exact
              to={{ pathname: "/study-in-canada", search: location.search }}
              activeClassName="active"
            >
              Study in Canada
            </NavLink>
            <NavLink
              exact
              to={{ pathname: "/study-in-europe", search: location.search }}
              activeClassName="active"
            >
              Study in Europe
            </NavLink>
            <NavLink
              exact
              to={{ pathname: "/study-in-australia", search: location.search }}
              activeClassName="active"
            >
              Study in Australia
            </NavLink>
            {/* <NavLink exact to={{ pathname: '/lingoq-study', search: location.search }} activeClassName="active">Lingo Q Study</NavLink> */}
            <NavLink
              exact
              to={{ pathname: "/", search: location.search }}
              activeClassName="active"
            >
              Home
            </NavLink>
            <NavLink
              exact
              to={{ pathname: "/test-prep", search: location.search }}
              activeClassName="active"
            >
              Test-Prep
            </NavLink>
            <NavLink
              exact
              to={{ pathname: "/about-us", search: location.search }}
              activeClassName="active"
            >
              About Us
            </NavLink>
            <NavLink
              exact
              to={{ pathname: "/contact-us", search: location.search }}
              activeClassName="active"
            >
              Contact Us
            </NavLink>
            <NavLink
              exact
              to={{ pathname: "/join-us", search: location.search }}
              activeClassName="active"
            >
              Join Us
            </NavLink>
            <button
              className="button"
              onClick={bookDemoModal.bind(null, undefined)}
            >
              Book Appointment
            </button>
          </div>
        </div>
      </Sticky>
    );
  }

  if (getApiLoading) {
    return null;
  }

  function renderContent(program) {
    if (program.name === "CodeQ") {
      return (
        <>
          <p className="title-text">
            Explore <span> Online Coding Classes</span> for kids of ages 6-18{" "}
          </p>
          <p className="sub-text">
            Building and programming immersive 3-D applications for the VR
            future is difficult, but we help make it easy!
          </p>
          <div className="slider-wrap">
            {coursesMap[program.id] ? (
              <CarouselOne data={coursesMap[program.id]} type="codeq" />
            ) : null}
          </div>
          <div className="button-wrap">
            <NavLink exact to="/codeq" className="button blue-bordered">
              View all Courses
            </NavLink>
          </div>
        </>
      );
    }
    if (program.name === "LingoQ") {
      return (
        <>
          <p className="title-text">
            Discover a fun way to learn <span>English Online</span>
          </p>
          <p className="sub-text">
            We have a wide range of online courses for children and teenages to
            increase fluency in the English Language!
          </p>
          <div className="slider-wrap">
            {coursesMap[program.id] ? (
              <CarouselOne data={coursesMap[program.id]} type="lingoq" />
            ) : null}
          </div>
          <div className="button-wrap">
            <NavLink exact to="/lingoq" className="button blue-bordered">
              View all Courses
            </NavLink>
          </div>
        </>
      );
    }
  }

  return (
    <div className="homepage-layout">
      <Header />
      <div className="banner-wrapper">
        <div className="container">
          <div className="content-wrap">
            <p className="text-two">FAVUNI GLOBAL</p>
            <p className="text-one">Welcome to Global Education Learning</p>
            <p className="text-three">
              We help to choose your favourite university and courses for study
              abroad
            </p>
            <button
              className="orange"
              onClick={bookDemoModal.bind(null, undefined)}
            >
              Book Appointment
            </button>
          </div>
          <div className="video-wrap">
            {/* <img src={Thumbnail} alt="" className="thumbnail" /> */}
            <img
              src={OrangePlay}
              alt=""
              className="play"
              onClick={playVideo.bind(null, youtubeVideoId)}
            />
          </div>
          <p
            onClick={scrollTo.bind(null, "section-one")}
            className="scroll-text"
          >
            Scroll to explore <img src={DownArrow} alt="" />
          </p>
        </div>
      </div>

      <div
        className="Testimonials_testimonial-area-main__3eI_E"
        id="testimonialsSection"
      >
        <div class="Testimonials_quote-wrapper__27vRL">
          <div class="Testimonials_quote-container__pi8Vw">
            <div class="Testimonials_video-cover-wrapper__19thV">
              <img className="img" src={Nelson} alt="" />
            </div>
            <div class="Testimonials_feedback-wrapper__1QGRM">
              <p class="Testimonials_quote__1XoDv">
                “Education is the most powerful weapon which you can use to
                change the world”
              </p>
              <div class="tw-flex tw-items-center">
                <div class="Testimonials_testimonial-round-img__twEb8"></div>
                <div class="Testimonials_details__157F3">
                  <h4>- Nelson Mandela</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Quotation_area_main__3I_E7" id="testimonialsSection">
          <div class="Quotation_quote_wrapper__2BnL">
            <div class="Quotation_quote_container__nl4Kw">
              <img className="img" src={Nelson} alt="" />
              <div class="Quotation_feed_wrap__1SpRN">
                <p class="Quotation_quXozy">
                  “Education is the most powerful weapon which you can use to
                  change the world”
                </p>
                <div class="tw-flex tw-items-center">
                  <div class="Quotation_details__157F3">
                    <h4>- Nelson Mandela</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="Testimonials_testimonial-area-main__3eI_F"
          id="testimonialsSection"
        >
          <div class="Testimonials_quote-wrapper__28vRL">
            <div class="Testimonials_feedback-wrapper__1QGRM">
              <div class="CourseDescription_heading__bOXwW">
                <span>Favuni Global -</span> Connecting Global Education
                Learning
              </div>
              <p class="Testimonials_quote__1XoDv">
                At Favuni Global we helping to find best destination for study
                abroad on the basis of strong education system top ranked
                universities, Scholarship availability options, Part time job
                opportunities, highest minimum wages, PSW permit maximum job
                opportunities after graduation. We are committed to helping each
                and all individual aspirants make the right Career choices for
                their future by offering appropriate professional career advice
                on their career development and self-improvement includes
                evaluating your academic records, establishing future goals,
                preparing visa documents and file submission. We have built our
                industry experience and expertise through 16 years of education
                consulting, working with over 7000+ student applications and
                developing a wide range of professional affiliations and
                networks. Our team are well –versed with university application
                and closely working and monitoring with the application profile
                to the students. We believe ourselves in taking our time to get
                to know our students and offering best advice applicable to them
                – from which course to choose to the best location to suit them
                better career choice. We develop good relationship with the
                university and colleges bringing faster update. Our counsellors
                team well-versed with university application processes including
                the UK, US, Ireland, Canada, Australia Dubai and USA. Assisting
                applicant for complete Visa guidance Statement Of Purpose and
                Interview preparation and other attractive programs preferred by
                international students.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section three">
        <div className="container">
          <p className="title-text">Why choose Favuni global?</p>
          <div className="flex-wrap">
            <div>
              <div className="img-wrap">
                <img src={IconThree} alt="" />
              </div>
              <div className="content-wrap">
                <p className="text-one">Free Profile Assessment</p>
                <p className="text-two">
                  Get your profile assessment, for FREE! to see what courses
                  match your profile.
                </p>
              </div>
            </div>
            <div>
              <div className="img-wrap">
                <img src={IconOne} alt="" />
              </div>
              <div className="content-wrap">
                <p className="text-one">Course Selection</p>
                <p className="text-two">
                  Applicants are required to select course options when applying
                  to the university.
                </p>
              </div>
            </div>
            <div>
              <div className="img-wrap">
                <img src={IconTwo} alt="" />
              </div>
              <div className="content-wrap">
                <p className="text-one">College SOP and Essay Writing</p>
                <p className="text-two">
                  We help in writing a unique SOP which increases your chances
                  of getting into the university.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="flex-wrap">
            <div>
              <div className="img-wrap">
                <img src={IconSix} alt="" />
              </div>
              <div className="content-wrap">
                <p className="text-one">Interview preparation</p>
                <p className="text-two">
                  We provide guidance for interview so that student can get more
                  confidence.
                </p>
              </div>
            </div>
            <div>
              <div className="img-wrap">
                <img src={IconFive} alt="" />
              </div>
              <div className="content-wrap">
                <p className="text-one">Application and Visa</p>
                <p className="text-two">
                  We help with applying for a study visa application,
                  appointment and interview.
                </p>
              </div>
            </div>
            <div>
              <div className="img-wrap">
                <img src={IconFour} alt="" />
              </div>
              <div className="content-wrap">
                <p className="text-one">Professional Mentorship</p>
                <p className="text-two">
                  We primarily well focus on counseling as per the specific
                  needs of a student.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="section four">
        <p className="title-text">Join us now in journey of fun & learning like <br /> many other student did</p>
        {
          CarouselTwo(projects)
        }
        <div className="button-wrap">
          <NavLink exact to="/test-prep" className="white-bordered-button" >See Students Projects</NavLink>
        </div>
      </div> */}

      <div className="slogan4">
        <h1 className="text-one">Top Countries</h1>
        <div className="row">
          <div className="col">
            {/* <img src={imgFour} alt=""/> */}
            <div className="img-wrapper">
              <div className="img-wrap">
                <img src={UnitedK} alt="" />
                <div className="content-wrap">
                  <p className="text-top">United Kingdom</p>
                </div>
              </div>
              <div className="img-wrap">
                <img src={Canada} alt="" />
                <div className="content-wrap">
                  <p className="text-top">Canada</p>
                </div>
              </div>
              <div className="img-wrap">
                <img src={Australia} alt="" />
                <div className="content-wrap">
                  <p className="text-top">Australia</p>
                </div>
              </div>
              <div className="img-wrap">
                <img src={Europe} alt="" />
                <div className="content-wrap">
                  <p className="text-top">Europe</p>
                </div>
              </div>
            </div>
            <div className="img-wrapper">
              <div className="img-wrap">
                <img src={Ireland} alt="" />
                <div className="content-wrap">
                  <p className="text-top">Ireland</p>
                </div>
              </div>
              <div className="img-wrap">
                <img src={Russia} alt="" />
                <div className="content-wrap">
                  <p className="text-top">Russia</p>
                </div>
              </div>
              <div className="img-wrap">
                <img src={NewZealand} alt="" />
                <div className="content-wrap">
                  <p className="text-top">NewZealand</p>
                </div>
              </div>
              <div className="img-wrap">
                <img src={USA} alt="" />
                <div className="content-wrap">
                  <p className="text-top">USA</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="styles_homepageContainer__cL6dC">
        <section className="stats-main section-gap Stats_stats__PB57m">
          <div className="styles_small-container__2O3eV">
            <div className="row tw-items-center styles_row__1vzFc Stats_statsWrapper__2AwcW">
              <div className="styles_col__c0uzR styles_col-6__3voLH">
                <div className="Stats_mapImg__ZD47G">
                  <img src={Congratulations} alt="" />
                </div>
              </div>

              <div className="styles_col__c0uzR styles_col-6__3voLH">
                <div className="tw-ml-auto">
                  <h2 className="styles_heading__21Gbs Stats_heading__b4q5H">
                    {/* &nbsp; */}
                    <span className="styles_stroke__3kKld styles_stats-b2-stroke__1sdGv styles_orange__11u2T">
                      Education accessible across the world
                    </span>
                  </h2>
                </div>
                <div className="styles_row__1vzFc Stats_statsCountContainer__1mvZI">
                  <div className="tw-px-1.5 md:tw-px-3 styles_col-6__3voLH Stats_statSection__3r1j4">
                    <div className="Stats_singleStatSection__2pvyG">
                      <h3>
                        10+
                        <br />
                        Thousands
                      </h3>
                      <p>Profile Assesment</p>
                    </div>
                  </div>
                  <div className="tw-px-1.5 md:tw-px-3 styles_col-6__3voLH Stats_statSection__3r1j4">
                    <div className="Stats_singleStatSection__2pvyG">
                      <h3>
                        48+
                        <br />
                        Universities
                      </h3>
                      <p>Our Trusted Partners</p>
                    </div>
                  </div>
                  <div className="tw-px-1.5 md:tw-px-3 styles_col-6__3voLH Stats_statSection__3r1j4">
                    <div className="Stats_singleStatSection__2pvyG">
                      <h3>
                        16+
                        <br />
                        Years
                      </h3>
                      <p>Having experience</p>
                    </div>
                  </div>
                  <div className="tw-px-1.5 md:tw-px-3 styles_col-6__3voLH Stats_statSection__3r1j4">
                    <div className="Stats_singleStatSection__2pvyG">
                      <h3>
                        20+
                        <br />
                        Countries
                      </h3>
                      <p>where most students go </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* FAQS */}
      {/* <>  
      }
      </> */}
      <div class="Faq_mainContainer__KomTp Faq_quesans__swHFs">
        <div class="FAQ_SetHeader__GhdP9">Frequently asked questions</div>
        <div class="FAQ_faqSection__K0g03">
          {
            <Collapse  className="collapse" onChange={onChange}>
              <Panel className="Fa_ques"
                header="What does an educational consultant do for your study abroad?"
                key="1"
              >
                <p>{text1}</p>
              </Panel>
              <Panel className="Fa_ques" header=" How much service cost of the service?" key="2">
                <p>{text2}</p>
              </Panel>
              <Panel className="Fa_ques" header="How are you different from others?" key="3">
                <p>{text3}</p>
              </Panel>
              <Panel className="Fa_ques"
                header="Is there any admission guarantee from your agency?"
                key="4"
              >
                <p>{text4}</p>
              </Panel>
              <Panel className="Fa_ques" header="What is the major prerequisites for enrolling in a desired course and university?" key="5">
                <p>{text5}</p>
              </Panel>
              <Panel className="Fa_ques"
                header="How do I start our study abroad journey with Favuni Global?"
                key="6"
              >
                <p>{text6}</p>
              </Panel>
              <Panel className="Fa_ques" header="When should we meet?" key="7">
                <p>{text7}</p>
              </Panel>
              <Panel className="Fa_ques"
                header="What if I need to reach out to Favuni Global for some query?"
                key="8"
              >
                <p>{text8}</p>
              </Panel>
            </Collapse>
          }
        </div>
      </div>

      {/* Faqs End from here */}

      <div class="NearByCentres_nearByCentresMainContainer__3DRfM">
        <div class="NearByCentres_nearByCentresHeading__2W9ti">
          Popular {""}
          <span class="NearByCentres_subHeading__2yOfj">Courses {""}</span>For
          You
        </div>
        <div class="NearByCentres_nearByCentresContainer__2PtmG NearByCentres_showMore__1ATve">
          <div class="NearByCentres_centreCard__3c8lc">
            <div class="NearByCentres_centreImg__1L8Is">
              <img src={ComputerScience} alt="" />
            </div>
            <div class="NearByCentres_centreDetails__7iiUm">
              <div class="NearByCentres_centreName__1MXLs">
                Computer Science
              </div>
              <div class="NearByCentres_divideLineMweb__3DDyw"></div>
              <div class="NearByCentres_divideLine__X5SEV"></div>
            </div>
          </div>
          <div class="NearByCentres_centreCard__3c8lc">
            <div class="NearByCentres_centreImg__1L8Is">
              <img src={MBA} alt="" />
            </div>
            <div class="NearByCentres_centreDetails__7iiUm">
              <div class="NearByCentres_centreName__1MXLs">
                Master of Business Development
              </div>
              <div class="NearByCentres_divideLine__X5SEV"></div>
            </div>
          </div>
          <div class="NearByCentres_centreCard__3c8lc">
            <div class="NearByCentres_centreImg__1L8Is">
              <img src={ActurialScience} alt="" />
            </div>
            <div class="NearByCentres_centreDetails__7iiUm">
              <div class="NearByCentres_centreName__1MXLs">
                Acturial Science
              </div>
              <div class="NearByCentres_divideLine__X5SEV"></div>
            </div>
          </div>
          <div class="NearByCentres_centreCard__3c8lc">
            <div class="NearByCentres_centreImg__1L8Is">
              <img src={LLB} alt="" />
            </div>
            <div class="NearByCentres_centreDetails__7iiUm">
              <div class="NearByCentres_centreName__1MXLs">Bachelor of Law</div>
              <div class="NearByCentres_divideLine__X5SEV"></div>
            </div>
          </div>
          <div class="NearByCentres_centreCard__3c8lc">
            <div class="NearByCentres_centreImg__1L8Is">
              <img src={DataAnalytics} alt="" />
            </div>
            <div class="NearByCentres_centreDetails__7iiUm">
              <div class="NearByCentres_centreName__1MXLs">Data Analytics</div>
              <div class="NearByCentres_divideLine__X5SEV"></div>
            </div>
          </div>
          <div class="NearByCentres_centreCard__3c8lc">
            <div class="NearByCentres_centreImg__1L8Is">
              <img src={Research} alt="" />
            </div>
            <div class="NearByCentres_centreDetails__7iiUm">
              <div class="NearByCentres_centreName__1MXLs">Research</div>
              <div class="NearByCentres_divideLine__X5SEV"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="section two">
        <div className="container">
          <div className="experts-wrap">
            <p className="text">Our Trusted Partners</p>
            <div className="img-list">
              {/* <img src={BritishCouncil} alt="" /> */}
              <img src={Demontford} alt="" />
              <img src={BPP} alt="" />
              <img src={Roehampton} alt="" />
              <img src={Sunderland} alt="" />
              <img src={Solent} alt="" />
              <img src={Bristol} alt="" />
              <img src={Keele} alt="" />
              <img src={Wrexham} alt="" />
            </div>
            <div className="img-list">
              <img src={Loughborough} alt="" />
              <img src={Oxford} alt="" />
              <img src={UCA} alt="" />
              <img src={Coventry} alt="" />
              <img src={Kaplan} alt="" />
              <img src={IBSHungry} alt="" />
              <img src={Abertay} alt="" />
              <img src={BathSpa} alt="" />
            </div>
          </div>
        </div>
      </div>
           {/* <div>
        <a>
          <img src={WhatsAppLogo} width={60} alt="favuni Global" />
        </a>
      </div> */}
       
       
      <div className="section seven">
        <div className="container">
          <CompanyDetails />
        </div>
      </div>
      <Footer />
      {/* <div className="App">
      <FloatingWhatsApp
        phoneNumber="123456789"
        accountName="Foo"
        allowEsc
        allowClickAway
        notification
        notificationSound
      />
      </div> */}
      {/* <Whatsapp /> */}
      {/* <div>
      <script src="https://cdn.tailwindcss.com"></script>

<style>
  body { background-color: "gray"; height: 100vh;}
</style>
 <button title="Contact Sale" class="fixed z-90 bottom-10 right-8 bg-blue-600 w-20 h-20 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-blue-700 hover:drop-shadow-2xl hover:animate-bounce duration-300" > &#9993; </button>
      </div> */}
    </div>
    
  );
}

export default withRouter(HomepageLayout);

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_PROFILE = 'USER_PROFILE';
export const USERS_PROFILE = 'USERS_PROFILE';
export const USER_REGISTERED = 'USER_REGISTERED';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const NOTIFICATION = 'NOTIFICATION';
export const MODAL_TYPE_TNC = 'MODAL_TYPE_TNC';
export const MODAL_TYPE_REQUEST_CALLBACK = 'MODAL_TYPE_REQUEST_CALLBACK';
export const PROGRAM = 'PROGRAM';
export const TEACHING_VIDEO = 'TEACHING_VIDEO';
export const THANK_YOU_MODAL = 'THANK_YOU_MODAL';
export const COMMON_INFO_MODAL = 'COMMON_INFO_MODAL';
export const BOOK_DEMO_MODAL = 'BOOK_DEMO_MODAL';
export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL';
export const RESCHEDULE_MODAL = 'RESCHEDULE_MODAL';
export const TNC_MODAL = 'TNC_MODAL';
export const CALENDAR_MODAL = 'CALENDAR_MODAL';
export const ADD_CLASS_MODAL = 'ADD_CLASS_MODAL';
export const CURRENT_LANGUAGE = 'CURRENT_LANGUAGE';
export const CURRENT_LANGUAGE_MAP = 'CURRENT_LANGUAGE_MAP';
export const SIGNUP_MODAL = 'SIGNUP_MODAL';
export const RESCHEDULE_MODAL_PLATFORM = 'RESCHEDULE_MODAL_PLATFORM';
export const DATE_RANGE_SELECTOR_MODAL = 'DATE_RANGE_SELECTOR_MODAL';
export const CLEARSTORE = 'CLEARSTORE';
export const EVENT_CONFIRMATION = 'EVENT_CONFIRMATION';
export const VIDEO_PLAYER = 'VIDEO_PLAYER';
export const SURVEY_LIST_MODAL = 'SURVEY_LIST_MODAL';
export const EDIT_PROFILE_MODAL = 'EDIT_PROFILE_MODAL';
export const ONBOARDING = 'ONBOARDING';
export const MODAL = 'MODAL';
export const COUNTRY = 'COUNTRY';
export const CONTINENT = 'CONTINENT';
export const DIALCODE = 'DIALCODE';
export const UPDATE_MODAL_OTP='UPDATE_MODAL_OTP';
export const UPDATE_MODAL_NUMBER='UPDATE_MODAL_NUMBER';
export const CONFIRM_MODAL='CONFIRM_MODAL';
export const MESSAGE_MODAL='MESSAGE_MODAL';
export const COMMON_INFO_LEAD_MODAL='COMMON_INFO_LEAD_MODAL';
export const STORY_BOOK_MODAL='STORY_BOOK_MODAL';
export const THANK_YOU_MODAL_STORY='THANK_YOU_MODAL_STORY';
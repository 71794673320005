export const hindi = {
  views: {
    masterClasses: {
      titleText: 'मुफ्त अंग्रेजी कक्षा में पढ़ें',
      subText: 'अपने घर के आराम से, भारत में सबसे अच्छे शिक्षकों द्वारा हमारी लाइव इंग्लिश टीचिंग कक्षाओं के माध्यम से अपनी इंग्लिश व्याकरण, स्पोकन इंग्लिश और प्रवाह में सुधार करें!',
      bannerRow: {
        colOne: {
          textOne: 'लाइव इंटरेक्शन क्लास',
          textTwo: 'Qin1 पर मुफ्त लाइव इंटरैक्टिव कक्षाओं में भाग लें'
        },
        colTwo: {
          textOne: 'सबसे अच्छे शिक्षकों से सीखें',
          textTwo: 'भारत में सर्वश्रेष्ठ शिक्षकों से सीखें'
        },
        colThree: {
          textOne: 'करके सींखें',
          textTwo: 'कहीं भी कभी भी सीखें'
        }
      },
      filterText: {
        textOne: 'सभी आयु वर्ग के लिए',
        textTwo: 'पाठ्यक्रम दिखा रहा है'
      },
      infoBar: {
        colOne: {
          textOne: '50+',
          textTwo: 'ग्रुप कक्षाएं'
        },
        colTwo: {
          textOne: '100,000+',
          textTwo: 'छात्रों ने भाग लिया'
        },
        colThree: {
          textOne: '75+',
          textTwo: 'समूहों का विषय'
        },
        colFour: {
          textOne: '200+',
          textTwo: 'कक्षाएं पूरी हुईं'
        },
      },
      courseCard: {
        age: 'आयु',
        trainer: 'ट्रेनर',
        joinClass: 'मुफ़्त कक्षा में पढ़ें',
        years: 'वर्षों'
      }
    },
    masterClassesAdult: {
      titleText: 'Take your english <br /> speaking to the next level',
      subText: 'Advanced spoken english classes at home for',
      text: '18 years and above • Starts at <span>₹1300</span>',
      buttonText: 'BOOK FREE TRIAL',
      bannerRow: {
        colOne: {
          textOne: 'Live Intercactive classes',
          textTwo: 'Attend Free Live Interactive classes at Qin1'
        },
        colTwo: {
          textOne: 'Learn from masters',
          textTwo: 'Learn from the Masters - India’s best teachers'
        },
        colThree: {
          textOne: 'Learn by doing',
          textTwo: 'Access to quality education anytime, anywhere'
        }
      },
      filterText: {
        textOne: 'Showing',
        textTwo: 'courses for all age group'
      },
      infoBar: {
        colOne: {
          textOne: '50+',
          textTwo: 'Group Classes'
        },
        colTwo: {
          textOne: '100,000+',
          textTwo: 'Students Attended'
        },
        colThree: {
          textOne: '75+',
          textTwo: 'Topics for groups'
        },
        colFour: {
          textOne: '200+',
          textTwo: 'Classes Completed'
        },
      },
      courseCard: {
        age: 'For Ages',
        trainer: 'Trainer',
        joinClass: 'JOIN CLASS FOR FREE',
        years: 'years'
      },
      helperWrapper: {
        textOne: 'Unwrap a new skill',
        textTwo: 'Explore thousands of classes to fuel your <span>english</span> communication skills with a Premium Membership',
        buttonText: 'Learn More'
      }
    }
  }
}
import { USER_LOGGED_IN, USER_LOGGED_OUT, USER_PROFILE, USERS_PROFILE, CLEARSTORE } from '../constants/types';
import { setAxiosHeaders, setUserData, removeAxiosHeaders, setAccessToken, deleteLocalData, getAccessToken, googleEvent, pixelEvent, serializeObjToUrlParams, taboolaPixel, proformics } from '../utils/helper';
import api from '../commonjs/api';
import { CLIENT_ID, CLIENT_SECRET } from '../constants/config';
import { createBrowserHistory } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import ip from 'ip';
import ipLocation from 'iplocation';

const history = createBrowserHistory({ forceRefresh: true });

export const userLoggedIn = (user) => ({
  type: USER_LOGGED_IN,
  user
});

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT
});

export const userData = (user) => ( console.log("in data kkkk",user),{
  type: USER_PROFILE,
  user
});

export const usersData = (users=[]) => ({
  type: USERS_PROFILE,
  users
});

export const clearStore = () => ({
  type: CLEARSTORE
});

export const login = credentials => dispatch =>
  api.user.login(credentials).then(user => {
    setAccessToken(user.data.access_token);
    setAxiosHeaders();
    setUserData({accessToken: user.data.access_token});
    dispatch(userLoggedIn(user.data));
  });

export const logout = (credentials = { client_id: CLIENT_ID, client_secret: CLIENT_SECRET, token: getAccessToken() }) => dispatch =>
  api.profile.logout(credentials).then(user => {
    removeAxiosHeaders();
    deleteLocalData();
    dispatch(userLoggedOut());
  });

export const leads = (params = {}, callback = undefined) => async(dispatch) => {
  callback();
}

export const lingoqLeads = (params = {}, callback = undefined) => async(dispatch) => {
  return api.utils.storyLead(params).then(resp => {
    callback();
    pixelEvent('Lead', params);
    taboolaPixel('lead');
    proformics();
    if (window.gtag_report_conversion) {
      window.gtag_report_conversion();
    }
    googleEvent({
      hitType: 'event',
      eventCategory: 'click',
      eventAction: 'Demo Booked',
      eventLabel: 'Demo Booked'
    });
   if(params.redirection) {
     setTimeout(() => {
       history.push({
         url: '/book-demo',
         search: window.location.search
       });
     }, 1000);
   }
    
  }).catch(error => {
    callback(error);
    console.error(error);
    if (params.redirection) {
      setTimeout(() => {
        history.push({
          url: '/book-demo',
          search: window.location.search
        });
      }, 1000);
    }
    // history.push('/book-demo');
  });;
}
  

const REASONS = [
 'Non availability of child at home',
 'Trainer not joining',
 'National holiday '
];

const COUNTRIES = [
    'Africa',
    'Australia',
    'Bangladeah',
    'Belgium',
    'Canada',
    'Denmark',
    'Dubai',
    'France',
    'Germany',
    'Hong Kong',
    'India',
    'Indonesia',
    'Iran',
    'Japan',
    'Kenya',
    'Kuwait',
    'Malaysia',
    'Maldives',
    'Nepal',
    'Nigeria',
    'Pakistan',
    'Qatar',
    'Russia',
    'Saudi Arabia',
    'Singapore',
    'South Korea',
    'Sri Lanka',
    'Switzerland',
    'Thailand',
    'United Arab Emirates',
    'United Kingdom',
    'United States of America'
  ];


export { REASONS ,COUNTRIES};
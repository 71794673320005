import React from "react";
import cross from "../../images/static/cross.svg";
import { hideModal } from "../../actions/modal";
import { useDispatch, useSelector } from "react-redux";
import userImg from "../../images/icons/mmms.png";

function ConfirmModal(props) {
  const dispatch = useDispatch();
  function close() {
    dispatch(
      hideModal(null, {
        ...props,
      })
    );
  }
  function submit() {
    props.action.props.submit();
    dispatch(
      hideModal(null, {
        ...props,
      })
    );
  }
  return (
    <div className="modal-window">
      <div className="reschedule-modal confirm-booking modal-content">
        <div className="modal-header-update">
          <img src={cross} alt="" className="modal-close" onClick={close} />
          <p className="phone-title-text">Class Details</p>
        </div>
        <div className="booking-confirm-card">
          <div className="heading-selcted">
            <p className={"selected-text"}>{"Selected teacher"}</p>
          </div>
          <div className="name-header">
            <img className="play" src={userImg} className="mmms" alt="" />
            <div className="heading-name">
              <p className={"header-text"}>
                {props.action.props.selectedTrainer}
              </p>
            </div>
          </div>
          <div>
            <div className="class-selcted">
              <p className={"classSelected-text"}>{"Class timings"}</p>
            </div>
            <div className="classTime-card-container">
              {Object.keys(props.action.props.selectedSlots).map(
                (time, index) => {
                  return (
                    props.action.props.selectedSlots[time].length > 0 && (
                      <div className="classTime-card">
                        <div>
                          <p className={"header-text"}>{time}</p>
                        </div>
                        <div>
                          <p className={"header-text"}>
                            {props.action.props.selectedSlots[time]}
                          </p>
                        </div>
                      </div>
                    )
                  );
                }
              )}
            </div>
          </div>
        </div>
        <div className="btn">
          <div className="btn-white">
            <button onClick={close}>Cancel</button>
          </div>
          <div className="btn-blue">
            <button onClick={submit}> Confirm & Book now</button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ConfirmModal;

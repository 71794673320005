import React, {
  useReducer,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { reducer } from "../reducers/reducer";
import { WhatsappSVG, CloseSVG, CheckSVG, SendSVG } from "../components/Icons";
import styles from "../components/stylemodule.css";
import "./stylemodule.css";
import darkBG from "../components/assets/bg-chat-tile-light.png";
import lightBG from "../components/assets/bg-chat-tile-dark.png";
import dummyAvatar from "../components/assets/uifaces-avatar.jpg";
import SoundBeep from "../components/assets/whatsapp-notification.mp3";
export function FloatingWhatsApp({
  onClick,
  onSubmit,
  onClose,
  onNotification,
  onLoopDone,
  phoneNumber = "1234567890",
  accountName = " Account Name",
  avatar = dummyAvatar,
  statusMessage = "Typically replies within 1 hour",
  chatMessage = "Hello there! 🤝 \nHow can we help?",
  placeholder = "Type a message..",
  allowClickAway = false,
  allowEsc = false,
  notification = true,
  notificationDelay = 60,
  notificationLoop = 0,
  notificationSound = false,
  notificationSoundSrc = SoundBeep,
  notificationStyle,
  notificationClassName = "floating-whatsapp-notification",
  buttonStyle,
  buttonClassName = "floating-whatsapp-button",
  chatboxHeight = 320,
  chatboxStyle,
  chatboxClassName = "floating-whatsapp-chatbox",
  darkMode = false,
  style,
  className = "floating-whatsapp",
}) {
  const [{ isOpen, isDelay, isNotification }, dispatch] = useReducer(reducer, {
    isOpen: false,
    isDelay: true,
    isNotification: false,
  });
  const timeNow = useMemo(
    () =>
      new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
    []
  );
  const inputRef = useRef(null);
  const soundRef = useRef(null);
  const loops = useRef(0);
  const notificationInterval = useRef(0);
  const handleNotification = useCallback(() => {
    if (!notification) return;
    dispatch({ type: "notification" });
    if (onNotification) onNotification();
    if (notificationLoop > 0) {
      loops.current += 1;
      if (notificationSound) {
        if (soundRef.current) {
          soundRef.current.currentTime = 0;
          soundRef.current.play();
        }
      }
      if (loops.current === notificationLoop) {
        clearInterval(notificationInterval.current);
        if (onLoopDone) onLoopDone();
      }
    }
  }, [
    notification,
    notificationLoop,
    notificationSound,
    onNotification,
    onLoopDone,
  ]);
  useEffect(() => {
    const delayInSecond = notificationDelay * 1000;
    if (delayInSecond < 10)
      return console.error(
        "notificationDelay prop value must be at least 10 seconds."
      );
    notificationInterval.current = window.setInterval(
      handleNotification,
      delayInSecond
    );
    return () => clearInterval(notificationInterval.current);
  }, [handleNotification, notificationDelay]);
  const handleOpen = useCallback(
    (event) => {
      event.stopPropagation();
      if (isOpen) return;
      clearInterval(notificationInterval.current);
      dispatch({ type: "open" });
      setTimeout(() => dispatch({ type: "delay" }), 2000);
      if (onClick) onClick(event);
    },
    [isOpen, onClick]
  );
  const handleClose = useCallback(() => {
    dispatch({ type: "close" });
    if (onClose) onClose();
  }, [onClose]);
  const handleSubmit = (event) => {
    var _a;
    event.preventDefault();
    if (
      !((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.value)
    )
      return;
    window.open(
      `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${inputRef.current.value}`
    );
    if (onSubmit) onSubmit(event, inputRef.current.value);
    inputRef.current.value = "";
  };
  useEffect(() => {
    const onClickOutside = () => {
      if (!allowClickAway || !isOpen) return;
      handleClose();
    };
    document.addEventListener("click", onClickOutside, false);
    return () => document.removeEventListener("click", onClickOutside);
  }, [allowClickAway, isOpen, handleClose]);
  useEffect(() => {
    const onEscKey = (event) => {
      if (!allowEsc || !isOpen) return;
      if (event.key === "Escape") handleClose();
    };
    document.addEventListener("keydown", onEscKey, false);
    return () => document.removeEventListener("keydown", onEscKey);
  }, [allowEsc, isOpen, handleClose]);
  return React.createElement(
    "div",
    {
      className: `${styles.floatingWhatsapp} ${
        darkMode ? `${styles.dark} ` : ""
      } ${className}`,
      style: style,
    },
    React.createElement(
      "div",
      {
        className: `${styles.whatsappButton} ${buttonClassName}`,
        onClick: handleOpen,
        style: buttonStyle,
        "aria-hidden": "true",
      },
      React.createElement(WhatsappSVG, null),
      isNotification &&
        React.createElement(
          "span",
          {
            className: `${styles.notificationIndicator} ${notificationClassName}`,
            style: notificationStyle,
          },
          "1"
        )
    ),
    React.createElement(
      "div",
      {
        className: `${styles.whatsappChatBox} ${
          isOpen ? styles.open : styles.close
        } ${chatboxClassName}`,
        onClick: (event) => event.stopPropagation(),
        "aria-hidden": "true",
        style: Object.assign(
          { height: isOpen ? chatboxHeight : 0 },
          chatboxStyle
        ),
      },
      React.createElement(
        "header",
        { className: styles.chatHeader },
        React.createElement(
          "div",
          { className: styles.avatar },
          React.createElement("img", {
            src: avatar,
            width: "60",
            height: "60",
            alt: "whatsapp-avatar",
          })
        ),
        React.createElement(
          "div",
          { className: styles.status },
          React.createElement(
            "span",
            { className: styles.statusTitle },
            accountName
          ),
          React.createElement(
            "span",
            { className: styles.statusSubtitle },
            statusMessage
          )
        ),
        React.createElement(
          "div",
          {
            className: styles.close,
            onClick: handleClose,
            "aria-hidden": "true",
          },
          React.createElement(CloseSVG, null)
        )
      ),
      React.createElement(
        "div",
        {
          className: styles.chatBody,
          style: { backgroundImage: `url(${darkMode ? darkBG : lightBG})` },
        },
        isDelay
          ? React.createElement(
              "div",
              { className: styles.chatBubble },
              React.createElement(
                "div",
                { className: styles.typing },
                React.createElement("div", { className: styles.dot }),
                React.createElement("div", { className: styles.dot }),
                React.createElement("div", { className: styles.dot })
              )
            )
          : React.createElement(
              "div",
              { className: styles.message },
              React.createElement("span", { className: styles.triangle }),
              React.createElement(
                "span",
                { className: styles.accountName },
                accountName
              ),
              React.createElement(
                "p",
                { className: styles.messageBody },
                chatMessage
              ),
              React.createElement(
                "span",
                { className: styles.messageTime },
                timeNow,
                React.createElement(
                  "span",
                  { style: { marginLeft: 5 } },
                  React.createElement(CheckSVG, null)
                )
              )
            )
      ),
      React.createElement(
        "footer",
        { className: styles.chatFooter },
        React.createElement(
          "form",
          { onSubmit: handleSubmit },
          React.createElement("input", {
            className: styles.input,
            placeholder: placeholder,
            ref: inputRef,
            dir: "auto",
          }),
          React.createElement(
            "button",
            { type: "submit", className: styles.buttonSend },
            React.createElement(SendSVG, null)
          )
        )
      )
    ),
    notificationSound &&
      React.createElement("audio", {
        ref: soundRef,
        hidden: true,
        src: notificationSoundSrc,
      })
  );
}

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../../actions/modal';
import greenTick from '../../images/lingoqImg.png';
import cross from '../../images/delete-icon.svg';
import { useHistory } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import ContactUsDemoLead from '../utils/ContactUsDemoLead';



function renderContent(title, type, message, action, btnText) {
    if (type === 'info') {
        return <div className='text-center'>
            <img src={greenTick} alt="" className="lingoq-logo" />
            <p className="text-one">{title}</p>
            {
                btnText ? <div>
                    <button className="orange" onClick={action}>{btnText || 'Confirm'}</button>
                </div> : null
            }
        </div>
    }
}

function renderContentForm(action) {
    return <>
        <div className="demo-booking-lingoq">
            <div className="container">
                <ContactUsDemoLead course="lingoq" classLength="40" action={action}/>
            </div>
        </div>
    </>
}


function StoryBook(props) {
    const [storyModal, setStoryModal] = useState(true);
    const [formView, setFormView] = useState(false);
    const dispatch = useDispatch(),
        history = useHistory();
    const { title, message, type, action, btnText } = props;

    function close() {
         history.push('/lingoq')
        dispatch(hideModal(null, {
            ...props
        }));
    }

    function submit() {
        setStoryModal(false);
        setFormView(true);
    }

    function finalSubmit() {
        history.push('/lingoq')
        dispatch(hideModal(null, {
            ...props
        }));
    }

    return (
        <div className="modal-window">
            <div className="info-modal-lingoq modal-content">
                <img src={cross} alt="" className="modal-close" onClick={close} />
                <>
                    {
                        storyModal ? renderContent(title, type, message, submit, btnText) : null
                    }
                    {
                        formView ? renderContentForm(finalSubmit) : null
                    }
                </>
            </div>
        </div>
    )
}

export default withRouter(StoryBook);
/* global mobileClient */
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import { createBrowserHistory } from 'history';

import apiEndpoints from './apiEndpoints';
import { getUrlparamsAsObject, getParametersBySubstring, getAccessToken, removeAxiosHeaders, getLocalData, isLoggedIn, getRefreshToken, setAccessToken, setAxiosHeaders, deleteLocalData, setRefreshToken } from '../utils/helper';
import { CLIENT_ID, CLIENT_SECRET } from '../constants/config';

const history = createBrowserHistory({ forceRefresh: true });
let isTokenrefreshing = false;
let retryReqestArray = [];
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
let call;

function onTokenRefresh(authorisationToken) {
  retryReqestArray.map(cb => cb(authorisationToken));
}

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error && error.response && error.response.status === 401) {
    if (getAccessToken()) {
      if (getRefreshToken()) {
        if (!isTokenrefreshing) {
          removeAxiosHeaders();
          isTokenrefreshing = true;
          const params = {
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            grant_type: 'refresh_token',
            refresh_token: getRefreshToken()
          };
          axios.post(apiEndpoints.refreshToken, params)
            .then(response => {
              setRefreshToken(response.data.refresh_token);
              setAccessToken(response.data.access_token);
              setAxiosHeaders();
              onTokenRefresh(response.data.access_token);
            })
            .catch(error => {
              console.error(error.response);
              window.localStorage.clear();
              history.push('/');
            });
        }
        return new Promise(resolve => {
          retryReqestArray.push(token => {
            const originalRequest = error.config;
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axios(originalRequest));
          });
        });
      } else {
        history.push('/');
        removeAxiosHeaders();
        setRefreshToken('accessToken');
      }
    }
  }
  return Promise.reject(error);
});

axios.interceptors.request.use(request => {
  // let requestPlatform = 'web';
  // try {
  //   if (mobileClient) {
  //     requestPlatform = 'app';
  //   }
  // } catch (e) {
  //   if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  //     requestPlatform = 'm-web'
  //   }
  // }
  // request.headers['X-REQUEST-PLATFORM'] = requestPlatform;
  return request;
});

axiosCancel(axios, {
  debug: false
});

export default {
  utils: {
    leads: (params = {}) => {
      let tag = getUrlparamsAsObject()['tag'],
        tags = getParametersBySubstring('utm_') || [],

        urlParams = getUrlparamsAsObject(),
        utmObj = {};
      console.log("data in tags", tags, tag, urlParams)

      if (tag) {
        tag = tag.constructor === Array ? tag : [tag];
        tags = tags.concat(tag);
      }

      function startsWith(str, word) {
        return str.lastIndexOf(word, 0) === 0;
      }

      Object.keys(urlParams).forEach(key => {
        // if (startsWith(key, 'utm_')) {
        //   utmObj[key] = urlParams[key];
        // }
        utmObj[key] = urlParams[key];
      });
      if (Object.keys(utmObj).length) {
        params.source = utmObj;
      }

      if (tags.length) {
        params.tags = tags;
      }

      return axios.post(apiEndpoints.leads, params).then((res) => res.data);
    },

    packages: (leadParams = {}) =>
      axios.get(apiEndpoints.packages, { leadParams }).then((res) => res.data),


    // storyLead: (params = {}) =>
    //   axios.post(apiEndpoints.storyLead, params).then((res) => res.data),

      storyLead: (params = {}) => {
        let tag = getUrlparamsAsObject()['tag'],
          tags = getParametersBySubstring('utm_') || [],
  
          urlParams = getUrlparamsAsObject(),
          utmObj = {};
        console.log("data in tags", tags, tag, urlParams)
  
        if (tag) {
          tag = tag.constructor === Array ? tag : [tag];
          tags = tags.concat(tag);
        }
  
        function startsWith(str, word) {
          return str.lastIndexOf(word, 0) === 0;
        }
  
        Object.keys(urlParams).forEach(key => {
          // if (startsWith(key, 'utm_')) {
          //   utmObj[key] = urlParams[key];
          // }
          utmObj[key] = urlParams[key];
        });
        if (Object.keys(utmObj).length) {
          params.source = utmObj;
        }
  
        // if (tags.length) {
        //   params.tags = tags;
        // }
  
        return axios.post(apiEndpoints.storyLead, params).then((res) => res.data);
      },

    // getleads: (params = {}) =>
    //   axios.get(apiEndpoints.leads, { params }).then((res) => res.data),

    // joinAsExpert: (params = {}) =>
    // axios.post(apiEndpoints.joinAsExpert, {params}).then((res) => res.data),

    // getJoinUs: () =>
    // axios.get(apiEndpoints.joinAsExpert).then((res) => res.data)
  },

  masterData: {
    courses: (params = {}) =>
      axios.get(apiEndpoints.course, { params }).then((res) => res.data),

    coursesDetails: (id, params = {}) =>
      axios.get(apiEndpoints.course + '/' + id, { params }).then((res) => res.data),

    programs: (params = {}) =>
      axios.get(apiEndpoints.programs, { params }).then((res) => res.data),

    content: (params = {}) =>
      axios.get(apiEndpoints.content, { params }).then((res) => res.data),

    getModules: (params = {}) =>
      axios.get(apiEndpoints.module, { params }).then((res) => res.data),

    getImgUrl: (data = {}, config = {}) =>
      axios.post(apiEndpoints.s3FileUpload, data, { 'Content-Type': 'multipart/form-data', ...config }).then(res => res.data),

    getCountry: () =>
      axios.get(apiEndpoints.getCountry).then((res) => res.data),

    getCountryData: () =>
      axios.get(apiEndpoints.getCountryData).then((res) => res.data),


    getStudentModules: (params = {}) =>
      axios.get(apiEndpoints.modules, { params }).then((res) => res.data),

  },
  scheduler: {
    demoSlotsPerDay: (params = {}) =>
      axios.get(apiEndpoints.demoSlotsPerDay, { params }).then((res) => res.data),

    cancelClass: (id, params = {}) =>
      axios.delete(apiEndpoints.cancelClass + '/' + id, { data: params }).then((res) => res.data),

    rescheduleClass: (params = {}) =>
      axios.post(apiEndpoints.rescheduleClass, params).then((res) => res.data),

    getRescheduleClass: (id, params = {}) =>
      axios.get(apiEndpoints.rescheduleClass + '/' + id, { params }).then((res) => res.data),

    studentEvents: (params = {}) =>
      axios.get(apiEndpoints.studentEvents, { params }).then((res) => res.data),

    studentUpdate: (params = {}) =>
      axios.patch(apiEndpoints.studentUpdate, params).then((res) => res.data),

    addClass: (params = {}) =>
      axios.post(apiEndpoints.class, params).then((res) => res.data),

    getClass: (params = {}) =>
      axios.get(apiEndpoints.class, { params }).then((res) => res.data),

    startClass: (params = {}) =>
      axios.get(apiEndpoints.startClass, { params }).then((res) => res),

    getTrainerEvents: (params = {}) =>
      axios.get(apiEndpoints.trainerEvents, { params }).then((res) => res.data),

    trainerNextClass: (params = {}) =>
      axios.get(apiEndpoints.trainerNextClass, { params }).then((res) => res),

    callMaskEvent: (params = {}) =>
      axios.get(apiEndpoints.callMaskEvent, { params }).then((res) => res),

    getStudentCourseView: (params = {}) =>
      axios.get(apiEndpoints.studentCourseView, { params }).then((res) => res.data),

    eventSurvey: (id, params = {}) =>
      axios.get(apiEndpoints.eventSurvey + id, { params }).then((res) => res.data),

    regularClass: (params) =>
      axios.post(apiEndpoints.regularClass, params).then((res) => res.data),
  },
  profile: {
    bookDemo: (params = {}) =>
      axios.post(apiEndpoints.bookDemo, params).then((res) => res.data),

    proposeDemo: (params = {}) =>
      axios.post(apiEndpoints.proposeDemo, params).then((res) => res.data),

    patchBookDemo: (id, params = {}) =>
      axios.patch(apiEndpoints.bookDemo + '/' + id, params).then((res) => res.data),

    generateOtp: (params = {}) =>
      axios.post(apiEndpoints.generateOtp, params).then((res) => res.data),

    verifyOtp: (params = {}) =>
      axios.post(apiEndpoints.verifyOtp, params).then((res) => res.data),

    studentsProfile: (params = {}) =>
      axios.get(apiEndpoints.studentsProfile, { params }).then((res) => res.data),

    studentProfile: (id) =>
      axios.get(apiEndpoints.student + '/' + id).then((res) => res.data),

    patchStudent: (id, params = {}) =>
      axios.patch(apiEndpoints.student + '/' + id, params).then((res) => res.data),

    logout: (params = {}) =>
      axios.post(apiEndpoints.logout, params).then((res) => res.data),

    skill: () => axios.get(apiEndpoints.skill).then((res) => res.data),

    languages: () => axios.get(apiEndpoints.languages).then((res) => res.data),

    slottingData: (params = {}) =>
      axios.get(apiEndpoints.slottingData, { params }).then((res) => res.data),

    profileData: (id) =>
      axios.get(apiEndpoints.profileData + '/' + id).then((res) => res.data),
  },
  platform: {
    demoSlotsPerDay: (params = {}) =>
      axios.get(apiEndpoints.getDemoSlotsPerDay, { params }).then((res) => res.data),

    studentCalendar: (id, params = {}) =>
      axios.get(apiEndpoints.studentCalendar + '/' + id, { params }).then((res) => res.data),

    getNextClass: (id) =>
      axios.get(apiEndpoints.getNextClass + '/' + id).then((res) => res.data),

    rescheduleClass: (id, params) =>
      axios.post(apiEndpoints.rescheduleClassPlatform + '/' + id, params).then((res) => res.data),

    getRescheduleClass: (id, params = {}) =>
      axios.get(apiEndpoints.rescheduleClassPlatform + '/' + id, { params }).then((res) => res.data),

    cancelClass: (id, params = {}) =>
      axios.delete(apiEndpoints.cancelClassPlatform + '/' + id, { data: params }).then((res) => res.data),

    scheduleApproval: (id) =>
      axios.get(apiEndpoints.scheduleApproval + '/' + id).then((res) => res.data),

    postScheduleApproval: (id, params = {}) =>
      axios.post(apiEndpoints.scheduleApproval + '/' + id, params).then((res) => res.data),

    scheduleApprovalToken: (params) =>
      axios.get(apiEndpoints.scheduleApprovalToken, { params }).then((res) => res.data),

    postScheduleApprovalToken: (params = {}) =>
      axios.post(apiEndpoints.scheduleApprovalToken, params).then((res) => res.data),

    getPayment: (id, params = {}) =>
      axios.get(`${apiEndpoints.payment}/${id}`, { params }).then((res) => res.data),

    getHome: (id, params = {}) =>
      axios.get(`${apiEndpoints.getHome}/${id}`, { params }).then((res) => res.data),

    curriculum: (id, params = {}) =>
      axios.get(`${apiEndpoints.curriculum}/${id}`, { params }).then((res) => res.data),

    demoCurriculum: (id, params = {}) =>
      axios.get(`${apiEndpoints.demoCurriculum}/${id}`, { params }).then((res) => res.data),

    searchTrainers: (params) =>
      axios.post(apiEndpoints.fetchTrainers, params).then((res) => res.data),

    getDemo: (id, params = {}) =>
      axios.get(`${apiEndpoints.getDemo}/${id}`).then((res) => res.data),

    getDatePerCourse: (params = {}) =>
      axios.get(apiEndpoints.getDatePerCourse, { params }).then((res) => res.data),

    getRescheduleDates: (id, params = {}) =>
      axios.get(apiEndpoints.getRescheduleDates + '/' + id, { params }).then((res) => res.data),

  },
  survey: {
    getSurveys: (params = {}) =>
      axios.get(apiEndpoints.survey, { params }).then((res) => res.data),

    postAnswer: (params = {}) =>
      axios.post(apiEndpoints.surveyAnswer, params).then((res) => res.data),

    getAnswer: (id, params = {}) =>
      axios.get(apiEndpoints.surveyAnswer + '/' + id, { params }).then((res) => res.data),

    patchAnswer: (id = undefined, params = {}) =>
      axios.patch(apiEndpoints.surveyAnswer + '/' + id, params).then((res) => res.data),

    trainerSurveyLinks: (params = {}) =>
      axios.get(apiEndpoints.trainerSurveyLinks, { params }).then((res) => res.data),
  },
};

import { SHOW_MODAL, HIDE_MODAL } from '../constants/types';

export const showModal = (type, props) => ({
  type: SHOW_MODAL,
  payload: {
    type,
    props
  },
  uid: props.uid || Date.now()
});

export const hideModal = (type, props) => ({
  type: HIDE_MODAL,
  uid: props.uid
});
import { CURRENT_LANGUAGE, CURRENT_LANGUAGE_MAP } from '../constants/types';

export const setLanguageMap = (language) => ({
  type: CURRENT_LANGUAGE_MAP,
  language
});

export const setLanguage = (language) => ({
  type: CURRENT_LANGUAGE,
  currentLanguage: language
});
import { Spin } from 'antd';
import moment from 'moment-timezone';
import { Button, notification } from 'antd';
import lookup from 'country-code-lookup';
import countryTelData from 'country-telephone-data';
/* global mobileClient */
import React, { useEffect, useState } from 'react';
import Loadable from 'react-loadable';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { userData, usersData } from '../actions/auth';
import { setCountry } from '../actions/country';
import { setContinent } from '../actions/continent';
import { setDialCode } from '../actions/dialcode';
import api from '../commonjs/api';
import { getLocalData, getParameterByName, setLocalData, initPixel, getUrlparamsAsObject } from '../utils/helper';
import Homepage from '../views/pages/Homepage';
import { baseUrl } from '../api-config';

function Loading({ error }) {
  function reload() {
    window.location.reload();
  }

  if (error) {
    window.console.log(error);
    try {
      if (!mobileClient) {
        return (
          <div className="reload-page">
            <div className="align-middle">
              <p>You have new changes to be loaded.</p>
              <button variant="contained" color="secondary" onClick={reload}>
                RELOAD
              </button>
            </div>
          </div>
        );
      } else {
        window.location.reload();
      }
    } catch (e) {
      window.console.log(e);
    }
  } else {
    return <div className="component-loading"><Spin /></div>;
  }
}

const LoadablePage = page => Loadable({
  loader: () => import(`../views/pages/${page}`),
  loading: Loading
});

const PrivateRoute = ({ component: Component, ...rest }) => {
  let user = useSelector(state => state.user);
  return <Route {...rest} render={(props) => (
    getLocalData('accessToken') && Object.keys(user).length
      ? <Component {...props} key={props.match.params.id} />
      : <Redirect to='/' />
  )} />
}

const PublicRoute = ({ component: Component, ...rest }) => {
  let path = '/dashboard',
    users = useSelector(state => state.users),
    user = useSelector(state => state.user);
  if (user && user.demo_booked === 0 && user.past_regular_events === 0 && user.future_regular_events === 0) {
    path = '/form';
  }

  if (users && users.length < 2) {
    path = '/form';
  }

  if (user && user.demo_booked > 0 && user.past_regular_events === 0 && user.future_regular_events === 0 && user.demo_proposed === 0 && users.length === 1) {
    path = '/dashboard';
  }

  // if (user && user.demo_booked >= 1) {
  //   path = '/dashboard';
  // }
  return <Route {...rest} render={(props) => (
    !getLocalData('accessToken')
      ? <Component {...props} />
      : <Redirect to={path} />
  )} />
}




const RegularDemoRout = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => (
    getLocalData('accessToken')
      ? <Component {...props} key={props.match.params.id} />
      : <Redirect to='/' />
  )} />
}



const COUNTRIESDIALCODE = {
  'United Kingdom': '+44',
  'India': '+91'
};

function Router() {
  const dispatch = useDispatch(),
    [loading, setLoading] = useState(true),
    continent = useSelector(state => state.continent);

  function handleClose(key) {
    setLocalData('cookie_agreed', true);
    notification.close(key);
  }

  useEffect(() => {
    if (!loading && !getLocalData('cookie_agreed') && continent === 'Europe') {
      const key = `open${Date.now()}`;
      const btn = <div>
        <Button type="primary" size="small" onClick={handleClose.bind(null, key)}>
          Confirm
        </Button>
        &nbsp;
        <a href="/privacy-policies" target="_blank">Learn More</a></div>;
      notification.open({
        description:
          'We use cookies to give you the best online experience. By using our website, you agree to use of cookies in accordance with our privacy policy.',
        btn,
        key,
        duration: null,
        placement: 'bottomRight'
      });
    }
  }, [loading]);

  const getCountry = () => {
    api.masterData.getCountry()
      .then(response => {
        if (!getLocalData('accessToken')) {
          setLoading(false);
        }
        // initPixel(response.response);

        let lkUp = response.response;
        if (!lkUp) {
          return;
        }
        lkUp = lookup.byIso(response.response)
        if (lkUp && lkUp.continent) {
          dispatch(setContinent(lkUp.continent));
          dispatch(setCountry(lkUp.country));
          dispatch(setDialCode(COUNTRIESDIALCODE[lkUp.country] || null));
        }
      })
      .catch(error => {
        if (!getLocalData('accessToken')) {
          setLoading(false);
        }
        console.error(error);
      });
  }

  useEffect(() => {

    let tagsObj = getUrlparamsAsObject(),
      tags = [];

    // if (tag) {
    //   tag = tag.constructor === Array ? tag : [tag];
    //   tags = tags.concat(tag);
    // }
    if (tagsObj && Object.keys(tagsObj).length) {
      Object.keys(tagsObj).map(key => {
        tags.push({ [key]: tagsObj[key] });
      })
    }
    window.console.log('tags', tags);
    getCountry();
    if (getLocalData('accessToken')) {
      getStudents();
    }
  }, []);

  const getStudents = () => {
    let currentUser = getLocalData('currentUser');
    api.profile.studentsProfile()
      .then(response => {
        if (currentUser && response.result.map(s => currentUser.id === s.id).length) {
          dispatch(userData(currentUser));
        } else if (response.result.length) {
          dispatch(userData(response.result[0]));
        }
        dispatch(usersData(response.result));

        window.console.log(response);

        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }



  const redirectLink = (e) => {
  
  }


  const renderRoutes = () => {
    return (
      <Switch>
        {/* <PublicRoute exact path='/' component={Homepage} /> */}
        <Route exact path='/' component={LoadablePage('Homepage')} />
        <PublicRoute exact path='/home' component={LoadablePage('Homepage')} />
        <PublicRoute exact path='/course/:id' component={LoadablePage('Course')} />
        <Route exact path='/about-us' component={LoadablePage('AboutUs')} />
        <Route exact path='/contact-us' component={LoadablePage('ContactUs')} />
        <Route exact path='/join-us' component={LoadablePage('JoinUs')} />
        <Route exact path='/codeq' component={LoadablePage('CodeQNew')} />
        <Route exact path='/lingoq' component={LoadablePage('LingoQNew')} />
       
        <Route exact path='/study-in-uk' component={LoadablePage('StudyInUK')} />
        <Route exact path='/study-in-canada' component={LoadablePage('StudyInCanada')} />
        <Route exact path='/study-in-ireland' component={LoadablePage('StudyInIreland')} />
        <Route exact path='/study-in-europe' component={LoadablePage('StudyInEurope')} />
        <Route exact path='/study-in-usa' component={LoadablePage('StudyInUsa')} />
        <Route exact path='/study-in-australia' component={LoadablePage('StudyInAustralia')} />






        <Route exact path='/code-q' component={LoadablePage('CodeQNew')} />
        <Route exact path='/lingo-q' component={LoadablePage('LingoQNew')} />
        <Route exact path='/lingoq-new' component={LoadablePage('LingoQNewPage')} />
        <Route exact path='/privacy-policies' component={LoadablePage('PrivacyPolicy')} />
        <Route exact path='/book-demo' component={LoadablePage('BookDemo')} />
        <Route exact path='/terms-of-use' component={LoadablePage('TermsConditions')} />
        <Route exact path='/group-classes' component={LoadablePage('GroupClasses')} />
        <Route exact path='/master-classes' component={LoadablePage('MasterClasses')} />
        <Route exact path='/master-classes-hindi' component={LoadablePage('MasterClasses')} />
        <Route exact path='/master-classes-adult' component={LoadablePage('MasterClassesAdult')} />
        <Route exact path='/test-prep' component={LoadablePage('Gallery')} />
        {/* <Route exact path='/survey' component={LoadablePage('Survey')} /> */}
        <Route exact path='/advertisement' component={LoadablePage('Advertisement')} />
        <Route exact path='/test-b' component={LoadablePage('Advertisement')} />
        <Route exact path='/me-checkout' component={LoadablePage('MeeshoAd')} />
        <Route exact path='/qzard' component={LoadablePage('Qzard')} />
        <Route exact path='/demo-booking' component={LoadablePage('CodeqForm')} />
        <Route exact path='/demo-booking-lingoq' component={LoadablePage('LingoqForm')} />
        <Route exact path='/qidzania' component={LoadablePage('Qidzania')} />
        <Route exact path='/lead-page' component={LoadablePage('LeadPage')} />
        <Route exact path='/booking-success' component={LoadablePage('BookingSuccess')} />
        <Route exact path="/login" render={(e) => redirectLink(e)} />
        <Route exact path='/survey' render={(e) => redirectLink(e)} />
        <Route exact path='/calendar' render={(e) => redirectLink(e)} />
        <Route exact path='/waiting-room' render={(e) => redirectLink(e)} />
        <Route exact path='/dl-joinClass' render={(e) => redirectLink(e)} />
        <Route exact path='/deprecated-classLink' render={(e) => redirectLink(e)} />
        <Redirect from='*' to='/' />
      </Switch>
    );
  }

  if (loading) {
    return <div className="component-loading">Loading...</div>
  }
  return renderRoutes()
}

export default Router;

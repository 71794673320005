import { QuestionOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from "react-router-dom";
import validator from 'validator';
import { leads } from '../actions/auth';
import { hideModal, showModal } from '../actions/modal';
import api from '../commonjs/api';
import { THANK_YOU_MODAL } from '../constants/types';
import banner from '../images/static/book-demo.svg';
import cross from '../images/static/cross.svg';
import { getParameterByName, pixelEvent, setLocalData, validatePhoneNumber, taboolaPixel } from '../utils/helper';
import { COUNTRIES } from '../commonjs/commonData';

const phoneHelperText = <span>Please add country code <br /> eg: 919013595445 <br /> Number should be mininmum 8 characters and maximum 12 characters</span>,
  COURSES_FILTERS = ['LingoQ Senior'];

function BookDemoModal(props) {
  const dispatch = useDispatch(),
    continent = useSelector(state => state.continent),
    dialCode = useSelector(state => state.dialcode),
    country = useSelector(state => state.country),
    [data, setData] = useState({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      message: '',
      has_tnc_agreed: continent === 'Europe' ? false : true,
      news_letter: continent === 'Europe' ? false : true,
      course: props.course ? props.course.name : ''
    }),
    [errors, setErrors] = useState({}),
    [courses, setCourses] = useState([]),
    [loading, setLoading] = useState(false),
    history = useHistory();

  window.console.log(props);
  console.log("country", country)


  useEffect(() => {
    getCourses();
    pixelEvent('ViewContent');
    taboolaPixel('page_view');
    if (props.data && (props.data.email || props.data.phone)) {
      setData({ ...data, email: props.data.email || '', phone: props.data.phone || '' });
    }
  }, []);

  function getCourses() {
    console.log("props", props)
    let params = {
      is_online: true
    };
    if (props.program && typeof props.program === 'string') {
      params.program__name = props.program;
    }
    if (props.course_type) {
      params.course_type = props.course_type;
    }
    api.masterData.courses(params)
      .then(response => {
        response = response.filter(data => data.description !== '' && !COURSES_FILTERS.includes(data.name));
        setCourses(response);
      })
      .catch(error => {
        console.error(error);
      });
  }

  function close() {
    dispatch(hideModal(null, {
      ...props
    }));
  }

  function thankyouModal(message, type = undefined, e, messageAlreadyRegister) {
    setLoading(false);
    if (e && e.response && e.response.status === 400) {
      message = messageAlreadyRegister;
      dispatch(showModal(THANK_YOU_MODAL, { message, type }));
    } else {
      dispatch(showModal(THANK_YOU_MODAL, { message, type }));
    }

  }

  function handleChange(e) {
    let tempData = { ...data };
    tempData[e.target.name] = e.target.value;
    setData(tempData);
  }

  function onCheckboxChange(e) {
    let tempData = { ...data };
    tempData[e.target.name] = e.target.checked;
    setData(tempData);
  }

  function validate(params) {
    let errors = {};
    if (!params.first_name) {
      errors.first_name = true;
    }
    if (!params.phone) {
      errors.phone = true;
    }
    if (!params.has_tnc_agreed) {
      errors.has_tnc_agreed = true;
    }
    if (params.phone && !validatePhoneNumber(params.phone)) {
      errors.phone = true;
    }
    if (!params.email) {
      errors.email = true;
    }
    if (!params.course) {
      errors.course = true;
    }
    if (!country || country === '') {
      if (!params.country) {
        errors.country = true;
      }
    }
    if (!params.news_letter) {
      errors.news_letter = true;
    }
    if (params.email && !validator.isEmail(params.email)) {
      errors.email = true;
    }
    return errors;
  }

  function submit() {
    let tempErrors = validate(data);
    setErrors(tempErrors);
    if (Object.keys(tempErrors).length) {
      return;
    }
    let params = {};
    Object.keys(data).forEach(key => {
      if (data[key]) {
        params[key] = data[key];
      }
    });

    window.console.log(params);
    setLocalData('studentDetails', { ...params, program: props.program });
    setLoading(true);
    if (getParameterByName('type') === 'milkbasket') {
      if (props.tags) {
        params.tags = props.tags;
      }
      if (props.tags) {
        params.message = props.message;
      }
    }

    if (!params.country && country) {
      params.country = country;
    }

    const message = 'Hi, Thank you for your interest. One of our executives will contact you shortly to book your trial session.';
    const messageAlreadyRegister = 'Hi, Looks like you\'ve already registered with us.We will connect with you shortly for your child\'s Free Demo. Thanks';
    dispatch(leads(params, (e) => thankyouModal(message, 'error', e, messageAlreadyRegister)));
  }

  return (
    <div className="modal-window">
      <div className="book-demo modal-content">
        <img src={cross} alt="" className="modal-close" onClick={close} />
        <div className="content-wrapper">
          <div>
            <p className="title-text">{props.title || 'Booklllooo Free Demo'}</p>
            <div className="col">
              <input type="text" placeholder="First Name *" name="first_name" value={data.first_name} className={errors.first_name ? 'error' : ''} onChange={handleChange} />
            </div>
            <div className="col">
              {
                dialCode ? <label className="dial-code">
                  {
                    dialCode
                  }
                </label> : null
              }
              <input type="number" placeholder="Phone No. *" name="phone" value={data.phone} className={errors.phone ? 'error ' : '' + (props.data && props.data.phone ? 'disabled' : '') + (dialCode ? ' with-dial-code' : '')} onChange={handleChange} />
              <Tooltip className="ant-tooltip" placement="rightBottom" title={phoneHelperText}>
                <QuestionOutlined />
              </Tooltip>
            </div>
            <div className="col">
              <input type="text" placeholder="Email *" name="email" value={data.email} className={errors.email ? 'error' : '' + (props.data && props.data.email ? 'disabled' : '')} onChange={handleChange} />
            </div>
            <div className="col">
              {
                props.course && props.course.course_type === 'masterclass' ?
                  <p className="course"><span>Course </span>{props.course.name}</p> : <div className={'select-dropdown ' + (errors.course ? 'error' : '')}>
                    <select onChange={handleChange} name="course" className={data.course === '' ? 'light' : ''} value={data.course}>
                      <option value="">Select Course</option>
                      {
                        courses.map(data => {
                          return (
                            <option key={data.id} value={data.name}>{data.name} | Age ({data.min_age} - {data.max_age})</option>
                          )
                        })
                      }
                    </select>
                  </div>
              }
            </div>
            {!country || country === '' ? <div className="col">
              {
                <div className={'select-dropdown ' + (errors.country ? 'error' : '')}>
                  <select onChange={handleChange} name="country" className={data.country === '' ? 'light' : ''} value={data.country}>
                    <option value="">Select Country</option>
                    {
                      COUNTRIES.map((country, index) => {
                        return (
                          <option key={index} value={country}>{country}</option>
                        )
                      })
                    }
                  </select>
                </div>
              }
            </div> : <></>}
            <div className="col tnc">
              <Checkbox name="has_tnc_agreed" onChange={onCheckboxChange} checked={data.has_tnc_agreed}>By checking this box you confirm that you have read and agree to our  <NavLink exact to="/terms-of-use" target="_blank">
                Terms & Conditions
              </NavLink></Checkbox>
              {
                errors.has_tnc_agreed ? <p className="error-message">Please tick the above field.</p> : null
              }
            </div>
            <div className="col tnc">
              <Checkbox onChange={onCheckboxChange} name="news_letter" checked={data.news_letter}>By checking this box you would like to receive more information about our products/services, events, news </Checkbox>
              {
                errors.news_letter ? <p className="error-message">Please tick the above field.</p> : null
              }
            </div>
            <div className="col">
              <button className={"button " + (loading ? 'disabled' : '')} onClick={submit}>SUBMIT</button>
            </div>
          </div>
          <div>
            <img src={banner} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookDemoModal;
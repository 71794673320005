import React from 'react';
import cross from '../../images/static/cross.svg';
import { hideModal } from "../../actions/modal";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function Modal(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    function close() {
      dispatch(
        hideModal(null, {
          ...props,
        })
      );
      history.push("/dashboard");
    }
    return (
        <div className="modal-window">
            <div className="confirmation-modal modal-content">
                <img src={cross} alt="" className="modal-close" onClick={() => close()} />
                <div>
                    <div className="content">
                        {
                            props.action.props.message ? <p className="sub-text">{props.action.props.message}</p> : null
                        }
                        <div className="buttons-wrap">
                            <button className="q-button blue-bordered" onClick={() => close()}>Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;

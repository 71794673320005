import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal } from '../actions/modal';
import cross from '../images/static/cross.svg';
import tick from '../images/check.svg';
import { useHistory } from "react-router-dom";


function ThankYouModal(props) {
  const dispatch = useDispatch(),
  history = useHistory();

  // useEffect(() => {
  //   // if(!props.type) {
  //     props.history.push('/book-demo');
  //     close();
  //   // }
  // }, []);

  function close() {
    // if(props.goHome) {
    //   props.history.push('/');
    //   localStorage.clear();
    // }
    history.push('/');
    dispatch(hideModal(null, {
      ...props
    }));
  }

  return (
    <div className="modal-window">
      <div className="thankyou-modal modal-content">
        <img src={cross} alt="" className="modal-close" onClick={close} />
        <div>
          <img src={tick} alt=""/>
          {/* <p className="title-text">Thank you</p> */}
          <p className="sub-text">{props.message || 'Thank you for your interest, We’ll verify your details and get in touch shortly'}</p>
          <button className="button blue" onClick={close}>Ok, got it</button>
        </div>
      </div>
    </div>
  )

  // if (props.type === 'error' || props.type === 'info') {
  //   return (
  //     <div className="modal-window">
  //       <div className="thankyou-modal modal-content">
  //         <img src={cross} alt="" className="modal-close" onClick={close} />
  //         <div>
  //           <p className="title-text">Thank you</p>
  //           <p className="sub-text">{props.message || 'We will get in touch shortly'}</p>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  // return null;
  
}

export default withRouter(ThankYouModal);
import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { hideModal, showModal } from '../../actions/modal';
import api from '../../commonjs/api';
import MessageModal from '../regular-view/MessageModal'
import cross from '../../images/static/cross.svg';
import { errorHandler, getParameterByName, notify, toUtcAmPm } from '../../utils/helper';
import { CONFIRM_MODAL, MESSAGE_MODAL } from "../../constants/types";



function RescheduleModal(props) {
  console.log("datadata", props)
  const dispatch = useDispatch();
  const { title, message, action } = props;
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSlot] = useState({});
  const [requestDone, setRequestDone] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const user = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [minimumDate, setMinimumDate] = useState(moment(props.data.start));
  const [getBookingDate, setGetBookingDate] = useState([]);
  const [errorData, setErrorData] = useState(null);
  let rescheduleMaxHours = 3;

  window.console.log(props);

  useEffect(() => {
    if (props.data.event_type === 'DEMO') {
      rescheduleMaxHours = 6;
    }
    // setMinimumDate(moment(props.data.start))
    getRescheduleDatesData();

  }, []);

  useEffect(() => {
    let params = {
      event_id: props.data.id
    };
    if (selectedDate) {
      params.start_date = toUtcAmPm(selectedDate.format('YYYY-MM-DD'));
      params.end_date = selectedDate.clone().add(1, 'days').format('YYYY-MM-DD') + 'T12:00am';
      demoSlotsPerDay(user.id, params);
    }
    // params.id = props.data.id;
    setSlot({});
  }, [selectedDate]);


  function close() {
    dispatch(hideModal(null, {
      ...props
    }));
  }

  function selectSlot(params) {
    setSlot(params);
  }

  const getRescheduleDatesData = () => {
    let params = {
      event_id: props.data.id
    };
    api.platform.getRescheduleDates(user.id, params)
      .then((response) => {
        let getDate = response.map(
          (data) => moment(data)
        );
        setSelectedDate(getDate[0]);
        setGetBookingDate(getDate)
      })

      .catch((error) => {
        window.console.log(error.response);
        openPopupMessage(error.response.data.response);
        // errorHandler(error.response, true);

      });
  };
  function demoSlotsPerDay(id, params = {}) {
    api.platform.getRescheduleClass(id, params)
      .then(response => {
        setRequestDone(true);
        setSlots(response.events);
      })
      .catch(error => {
        openPopupMessage(error.response.data.response);
        // errorHandler(error.response, true);
      });
  }

  // function onChange(date, dateString) {
  //   let params = {
  //     start_date: moment(date).format('YYYY-MM-DD') + 'T' + (moment(date).format('hh:mma').replace(' ', '')),
  //     end_date: moment(date).add(1, 'days').format('YYYY-MM-DD') + 'T12:00am',
  //     t: token
  //   };
  //   setSelectedDate(date);
  //   demoSlotsPerDay(props.data.id, params);
  // }

  function onChange(d, dateString) {
    if (!d) {
      setSlots([]);
      setSlot({});
      setSelectedDate(null);
      return;
    }
    let date = d.clone();

    // if (date.isSame(moment(), 'day')) {
    //   date = date.add(3, 'hours');
    // }
    let params = {
      event_id: props.data.id
    };
    if (date.isSame(moment(), 'day')) {
      params.start_date = toUtcAmPm(date.add(rescheduleMaxHours, 'hours'));
      params.end_date = toUtcAmPm(moment().endOf('day'));
    } else {
      params.start_date = toUtcAmPm(date.format('YYYY-MM-DD'));
      params.end_date = date.clone().add(1, 'days').format('YYYY-MM-DD') + 'T12:00am';
    }
    // params.id = props.data.id;
    setSelectedDate(date);
    setSlot({});
    demoSlotsPerDay(user.id, params);
  }

  function confirm() {
    let params = {
      from_event: parseInt(props.data.id),
      to_event: parseInt(selectedSlot.id),
    };
    setLoading(true);
    api.platform.rescheduleClass(user.id, params)
      .then(response => {
        props.action();
        setLoading(false);
        notify('Class has been rescheduled', 'success');
        close();
      })
      .catch(error => {
        console.error(error);
        errorHandler(error.response, true);
        setLoading(false);
      });
  }

  function renderInfo(params) {
    if (Object.keys(selectedSlot).length) {
      return <div className="info-wrap">
        <p className="text">Your class date and time is : <span>{moment(selectedSlot.start).format('LL')} &nbsp; &nbsp;   {moment(selectedSlot.start).format('hh:mm a')} - {moment(selectedSlot.end).format('hh:mm a')}</span></p>
      </div>
    }
    if (requestDone && !slots.length) {
      return <div className="info-wrap">
        <p className="text">No classes on : <span>{moment(selectedDate).format('LL')}</span></p>
      </div>
    }
  }

  function selectDate(date) {
    console.log("datedate", date)
    let params = {
      event_id: props.data.id
    };
    params.start_date = toUtcAmPm(date.format('YYYY-MM-DD'));
    params.end_date = date.clone().add(1, 'days').format('YYYY-MM-DD') + 'T12:00am';
    // params.id = props.data.id;
    setSelectedDate(date);
    setSlot({});
    // demoSlotsPerDay(user.id, params);
    // setSelectedDate(date);
    // setDateTimeRange({ start_time: "16:00:00", end_time: "23:00:00" });
  }


  function openPopupMessage(message) {
    close();
    dispatch(
      showModal(MESSAGE_MODAL, {
        action: (
          <MessageModal
            message={message}
          />
        ),
      })
    );
  }



  return (
    <div className="modal-window">
      <div className="reschedule-modal modal-content">
        <img src={cross} alt="" className="modal-close" onClick={close} />
        <div>
          <div className="content">
            <p className="sub-text">Kindly select the date and time slot to reschedule your class</p>
            {/* <DatePicker onChange={onChange}
              disabledDate={(current) => {
                // Can not select days after today and before start Date
                const start = moment(props.data.start, 'YYYY-MM-DD');
                return current < start || current > moment(props.data.start).add(5, 'day');
              }}
              allowClear={false} /> */}
            <div className="datepick">

              {getBookingDate.map((date, index) => {
                return (
                  <div>
                    <div style={{ width: '80px !important', marginRight: 10 }}
                      onClick={selectDate.bind(null, date)}
                      className={
                        "time-slot " +
                        (date.format("YYYY-MM-DD") ===
                          selectedDate.format("YYYY-MM-DD")
                          ? "selected"
                          : "")
                      }
                      key={index}
                    >
                      <p>{date.format("Do")}</p>
                      <p className="date">{date.format("ddd")}</p>
                      <p>{date.format("MMM")}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            {
              slots.length ? <div className="">
                <p className="label">Available time slots</p>
                <div className="available-slots-wrapper">
                  {
                    slots.map((slot, index) => {
                      return <div onClick={selectSlot.bind(null, slot)} className={"time-slot " + (slot === selectedSlot ? 'selected' : '')} key={index}>{moment(slot.start).local().format('hh:mm a')} - {moment(slot.end).local().format('hh:mm a')}</div>
                    })
                  }
                </div>
              </div> : null
            }
            {
              renderInfo()
            }
            <div className="buttons-wrap">
              <button className={"q-button blue " + (!Object.keys(selectedSlot).length || loading ? 'disabled' : '')} onClick={confirm}>Confirm</button>
            </div>
            <p className="info-text">Once you’ve confirmed your slot, you’ll receive a confirmation email</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(RescheduleModal);